<template>
  <div>
      <div class="hot-spinner" v-if="!isTargetingDataFetched">
        <i class="fa fa-lg fa-spinner fa-spin" />
      </div>
    <div class="elements" v-else>
      {{ allVerified }}
      <div v-if="budgetTypePivot.campaigns > 0" class="budget-type-pivot">
        <div class="">
          <label class="element-label">Campaign Setup</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
            <label class="checkbox-status-label">
              {{ budgetTypeVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="budgetTypeVerified" />
            </label>
          </span>
        </div>
        <div class="budget-type-table">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  v-for="item in dbmEnums.budgetTypeColumns"
                  :key="item"
                  class="table-header"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(record, i) in budgetTypePivot.records" :key="i">
              <tr>
                <td class="data-center">{{ record.account }}</td>
                <td class="data-center">{{ record.name }}</td>
                <td class="data-center">{{ record.type }}</td>
                <td class="data-center">{{ record.bidType + ", " + record.bpCurrency +" "+ parseFloat(record.bid).toFixed(2)}}</td>
                <td class="data-center">{{ record.bpCurrency +" "+ record.budget}}</td>
                <td class="data-center">{{ record.frequency }}</td>
                <td class="data-center">{{ record.status }}</td>
                <td class="data-center">{{ record.exchanges.length }} Exchange(s) and {{record.subExchanges.length}} SubExchange(s)</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="lineItemPivot.length > 0" class="budget-type-pivot">
        <div class="">
          <label class="element-label">Line Items</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
            <label class="checkbox-status-label">
              {{ lineItemVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="lineItemVerified" />
            </label>
          </span>
        </div>
        <div class="budget-type-table">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  v-for="item in dbmEnums.lineItemColumns"
                  :key="item"
                  class="table-header"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(record, i) in lineItemPivot" :key="i">
              <tr>
                <td class="data-center">{{ record.name }}</td>
                <td class="data-center">-</td>
                <td class="data-center">{{ record.type }}</td>
                <td class="data-center">{{ record.objectiveType ? record.objectiveType : "-" }}</td>
                <td class="data-center">{{ record.targetName}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

    <div v-if="locationsPivot.length > 0" class="budget-type-pivot">
      <div class="">
        <label class="element-label">Locations</label>
        <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
          <label class="checkbox-status-label">
            {{ locationsVerified ? "Verified" : "Check to verify" }}
            <input type="checkbox" v-model="locationsVerified" />
          </label>
        </span>
      </div>
      <div class="budget-type-table">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th
                v-for="item in dbmEnums.locationsColumns"
                :key="item"
                class="table-header"
              >
                {{ item }}
              </th>
            </tr>
          </thead>
          <template v-if="locationsPivot.length > 0">
            <tbody v-for="(record, i) in locationsPivot" :key="i">
              <tr>
                <td class="data-center">{{ record.name }}</td>
                <td class="data-center">{{ record.liCount }} ({{record.adCount}} {{record.adCount == 1 ? 'ad' : 'ads'}}) </td>
              </tr>
            </tbody>
          </template>
          <template v-else>
            <tbody>
              <tr>
                <td class="data-center" colspan="2">No locations selected</td>
              </tr>
            </tbody>
          </template>
        </table>
      </div>
    </div>

      <div v-if="adgroupPivot.length > 0" class="budget-type-pivot">
        <div class="">
          <label class="element-label">AdGroup</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
            <label class="checkbox-status-label">
              {{ youtubeAdgroupsVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="youtubeAdgroupsVerified" />
            </label>
          </span>
        </div>
        <div class="budget-type-table">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  v-for="item in dbmEnums.youtubeAdgroupColumns"
                  :key="item"
                  class="table-header"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(record, i) in adgroupPivot" :key="i">
              <tr>
                <td class="data-center">
                  <span>{{ record.name }}</span>
                  <!-- <Row v-if="record.id > 0" style="margin-top: 4px">
                    <Row
                    v-for="(summaryObj, targetName, i) in getTargetAdGroupSummary(getAdGroupData(record.id))"
                    :key="i"
                    >
                      <Row
                        v-for="(value, header, i) in summaryObj"
                        :key="i"
                        style=""
                      >
                        <Row-Col span="24" class="text-left"><span style="font-weight: bold;">{{ header }}</span>&nbsp;<span style="color:#566464">{{ value }}</span></Row-Col
                        >
                      </Row>
                    </Row>
                  </Row> -->
                </td>
                <td class="">
                  <Row v-if="record.internalId > 0" style="margin-top: 4px">
                    <Row
                    v-for="(summaryObj, targetName, i) in getTargetAdGroupSummary(getAdGroupData(record.internalId))"
                    :key="i"
                    >
                      <Row
                        v-for="(value, header, i) in summaryObj"
                        :key="i"
                        style=""
                      >
                        <Row-Col span="24" class="text-left" v-if="value"><span style="font-weight: bold;">{{ header }}</span>&nbsp;<span style="color:#566464">{{ value }}</span></Row-Col
                        >
                      </Row>
                    </Row>
                  </Row>
                  <span v-else>-</span>
                </td>
                <td class="data-center">{{ record.objectiveType }}</td>
                <td class="data-center">{{ record.adFormat }}</td>
                <td class="data-center">{{ record.bidding }}</td>
                <td class="data-center">{{ record.ads }} {{ record.ads == 1 ? 'ad' : 'ads' }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="creativesPivot.length > 0" class="budget-type-pivot">
        <div class="">
          <label class="element-label">Creatives</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled">
            <label class="checkbox-status-label">
              {{ creativesVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="creativesVerified" />
            </label>
          </span>
        </div>
        <div class="budget-type-table">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  v-for="item in dbmEnums.creativeColumns"
                  :key="item"
                  class="table-header"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(record, i) in creativesPivot" :key="i">
              <tr>
                <td class="data-center">{{ record.id }}</td>
                <td class="data-center">
                  <img v-if="record.imgUrl != null" v-lazy="record.imgUrl" height="70">
                  <tbody v-if="record.creativeType == 'Youtube'">
                    <tr>
                      <td class="creative-info-key">
                        Youtube URL &nbsp;
                      </td>
                      <td class="creative-info-value">
                        {{ record.creative  }}
                      </td>
                    </tr>
                    <tr>
                      <td class="creative-info-key" v-if="record.displayUrl">
                        Display URL &nbsp;
                      </td>
                      <td class="creative-info-value">
                        {{ record.displayUrl  }}
                      </td>
                    </tr>
                    <tr>
                      <td class="creative-info-key" v-if="record.callToAction">
                        Call To Action &nbsp;
                      </td>
                      <td class="creative-info-value">
                        {{ record.callToAction }}
                      </td>
                    </tr>
                      <tr>
                      <td class="creative-info-key" v-if="record.headline">
                        Headline &nbsp;
                      </td>
                      <td class="creative-info-value">
                        {{ record.headline }}
                      </td>
                    </tr>
                      <tr>
                      <td class="creative-info-key" v-if="record.longHeadline">
                        Long Headline &nbsp;
                      </td>
                      <td class="creative-info-value">
                        {{ record.longHeadline }}
                      </td>
                    </tr>
                    <tr>
                      <td class="creative-info-key" v-if="record.description1">
                        Description 1 &nbsp;
                      </td>
                      <td class="creative-info-value">
                        {{ record.description1 }}
                      </td>
                    </tr>
                    <tr>
                      <td class="creative-info-key" v-if="record.description2">
                        Description 2 &nbsp;
                      </td>
                      <td class="creative-info-value">
                        {{ record.description2 }}
                      </td>
                    </tr>
                  </tbody>
                </td>
                <td class="data-center">{{ record.landingPage ? record.landingPage : "-" }}</td>
                <td class="data-center">{{ record.targetName }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="targetingPivot.length > 0" class="budget-type-pivot">
        <div class="">
          <label class="element-label">Targeting</label>
          <span class="verified-action" v-if="isCampaignLauncherVerificationEnabled && isTargetingDataFetched">
            <label class="checkbox-status-label">
              {{ targetingVerified ? "Verified" : "Check to verify" }}
              <input type="checkbox" v-model="targetingVerified" />
            </label>
          </span>
        </div>
        <!-- <div class="hot-spinner" v-if="!isTargetingDataFetched">
          <i class="fa fa-lg fa-spinner fa-spin" />
        </div> -->
        <div class="budget-type-table">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th
                  v-for="item in dbmEnums.targetingColumns"
                  :key="item"
                  class="table-header"
                >
                  {{ item }}
                </th>
              </tr>
            </thead>
            <tbody v-for="(record, i) in targetingPivot" :key="i">
              <tr>
                <td class="">
                  <span style="font-weight: bold;">
                    {{ record.name }} (DXT-{{ record.targetId }}) &nbsp;<span style="color: #bbbbbb"> {{ record.entityType }}</span>
                  </span>
                  <Row
                    v-for="(summaryObj, targetName, i) in getFullSummary(record.targetString)"
                    :key="i"
                  >
                    <!-- <Row-Col span="4" style="font-weight: bold;">{{ targetName }}:</Row-Col> -->
                    <Row
                      v-for="(value, header, i) in summaryObj"
                      :key="i"
                      style=""
                    >
                      <Row-Col span="24" class="text-left"><span style="font-weight: bold;">{{ header }}</span>&nbsp;<span style="color:#566464">{{ value }}</span></Row-Col
                      >
                      <!-- <Row-Col span="14">{{ value }}</Row-Col> -->
                    </Row>
                  </Row>
                  <!-- <Row v-for="(adGroup) in targetAdGroupPivot(record.targetId)" :key="adGroup.id" class="target-adgroup">
                    <span style="font-weight: bold; text-decoration: underline">{{adGroup.name}}</span>
                    <Row
                    v-for="(summaryObj, targetName, i) in getTargetAdGroupSummary(adGroup)"
                    :key="i"
                    >
                      <Row
                        v-for="(value, header, i) in summaryObj"
                        :key="i"
                        style=""
                      >
                        <Row-Col span="24" class="text-left"><span style="font-weight: bold;">{{ header }}</span>&nbsp;<span style="color:#566464">{{ value }}</span></Row-Col
                        >
                      </Row>
                  </Row>
                  </Row> -->
                </td>
                <td class="data-center">{{ record.liCount }} ({{record.adCount}} {{record.adCount == 1 ? 'ad' : 'ads'}}) </td>
                <td class="data-center">{{ record.adGroupsCount }} </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { locale, Row, Col } from "iview";
import lang from "iview/dist/locale/en-US";
import { mapState } from "vuex";
import { dbmEnums } from "../../../Constants/DbmEnums.js";
import { options } from "../../../Constants/DbmTargetOptions.js";
import { APIService } from "../../../ApiService.js";

locale(lang); // iview is default chinese

export default {
  components: {
    Row,
    "Row-Col": Col
  },
  data: function () {
    return {
      budgetTypeVerified: false,
      targetingVerified: false,
      locationsVerified: false,
      creativesVerified: false,
      youtubeAdgroupsVerified: false,
      dbmEnums,
      isTargetingDataFetched: false,
      options,
      target: null,
      lineItemVerified: false,
      targetAdGroups: []
    };
  },
  created: function () {
    if (this.isCampaignLauncherVerificationEnabled == false) {
      this.budgetTypeVerified = true
      this.locationsVerified = true
      this.creativesVerified = true
      this.youtubeAdgroupsVerified = true
      this.lineItemVerified = true
    }
  },
  computed: {
    ...mapState([
      "dv360EntitiesResponse",
      "selectedPublisher",
      "savedTargets",
      "publisher",
      "selectedCampaignLauncherConfig",
      "isCampaignLauncherVerificationEnabled",
      "dv360Targets",
      "dbmAccounts"
    ]),
    budgetTypePivot: function () {
      const dbmEntities = this.dv360EntitiesResponse.data.childEntities;
      var dbmAccount = this.dbmAccounts.find(x => x.id == this.selectedCampaignLauncherConfig.bpseId);
      if (this.isCampaignLauncherVerificationEnabled) {
        this.budgetTypeVerified = false;
      }
      if (!dbmEntities.length) {
        this.budgetTypeVerified = true;
        return { records: [], campaigns: 0 };
      }
      let records = dbmEntities.flatMap(ioDetails => {
        return ioDetails.childEntities.map(entity => {
          return {
            account: dbmAccount.accountId + ' - ' + dbmAccount.accountNickName,
            name: ioDetails.childEntities[0].name,
            type: this.dbmEnums.insertionOrderType[ioDetails.childEntities[0].additionalData.insertionOrder.type],
            bidType: this.dbmEnums.ioKpiType[ioDetails.childEntities[0].additionalData.insertionOrder.bid.kpi],
            bid: ioDetails.childEntities[0].additionalData.insertionOrder.bid.amount,
            budget: parseFloat(ioDetails.childEntities[0].additionalData.budget).toFixed(2).toLocaleString(),
            status: this.dbmEnums.campaignStatus[ioDetails.childEntities[0].status],
            bpCurrency: ioDetails.childEntities[0].additionalData.bpCurrency,
            frequency: this.getFrequencyString(ioDetails.childEntities[0].additionalData.insertionOrder.frequency),
            exchanges: ioDetails.additionalData.exchanges || [],
            subExchanges: ioDetails.additionalData.subExchanges || []
          }
        })
      });
      let totalCampaigns = records.length;
      return { records: records, campaigns: totalCampaigns };
    },
    targetingPivot: function () {
      var self = this;
      const campaigns = this.dv360EntitiesResponse.data.childEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.targetingVerified = false;
      }
      let records = {};
      campaigns.forEach(campaign => {
        campaign.childEntities.forEach(io => {
          const lineItems = io.childEntities;
          lineItems.forEach(li => {
            let isYTCampaign = false;
            const targetId = li.additionalData.lineItem.deltaXTargetId;
            if (records[targetId]) {
              records[targetId].adCount += self.getAdCount(li);
              records[targetId].liCount++;
            } else {
              records[targetId] = {
                adCount: self.getAdCount(li),
                liCount: 1,
                name: self.dv360Targets.find(x => x.id === targetId).name,
                targetString: self.$store.state.dv360Targets.find(x => x.id === targetId).targetString,
                targetId
                // adGroupsCount: self.getAdGroupCountFromTargetString(self.$store.state.dv360Targets.find(x => x.id === targetId).targetString)
              }
            }
            li.childEntities && li.childEntities.forEach(adGroup => {
              if (adGroup.entityType == dbmEnums.entityType["Ad Group"]) {
                isYTCampaign = true;
              }
            })
            records[targetId].adGroupsCount = isYTCampaign ? self.getAdGroupCountFromTargetString(records[targetId].targetString) : "NA";
            records[targetId].entityType = isYTCampaign ? "YouTube" : "Display";
          })
        })
      })

      let result = Object.values(records);
      if (!result.length) {
        this.targetingVerified = true;
        return [];
      }
      return result;
    },
    locationsPivot: function () {
      const campaigns = this.dv360EntitiesResponse.data.childEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.locationsVerified = false;
      }
      let records = {};
      campaigns.forEach(campaign => {
        campaign.childEntities.forEach(io => {
          const lineItems = io.childEntities;
          lineItems.forEach(li => {
            let locations = li.additionalData.lineItem.locations;
            if (!locations || !Array.isArray(locations.includedLocations)) {
              return;
            }

            locations.includedLocations.forEach(location => {
              if (records[location.id]) {
                records[location.id].adCount += this.getAdCount(li);
                records[location.id].liCount++;
              } else {
                records[location.id] = {
                  adCount: this.getAdCount(li),
                  liCount: 1,
                  name: location.name,
                  locationId: location.id
                }
              }
            })
          })
        })
      })

      let result = Object.values(records);
      if (!result.length) {
        this.locationsVerified = true;
        return [];
      }
      return result;
    },
    adgroupPivot: function () {
      const campaigns = this.dv360EntitiesResponse.data.childEntities;
      let records = [];
      if (this.isCampaignLauncherVerificationEnabled) {
        this.youtubeAdgroupsVerified = false;
      }
      campaigns.forEach(campaign => {
        campaign.childEntities.forEach(io => {
          io.childEntities.forEach(li => {
            li.childEntities.forEach(childEntity => {
              // let targetId = li.additionalData.lineItem.deltaXTargetId;
              if (childEntity.entityType == 5) {
                let adgroup = childEntity.additionalData.adgroup;
                let bpCurrency = childEntity.additionalData.bpCurrency;
                records.push({
                  id: childEntity.id,
                  name: childEntity.name,
                  objectiveType: this.getEnumName(dbmEnums.lineItemType, adgroup.type),
                  adFormat: this.getEnumName(dbmEnums.liAdformat, adgroup.adFormat),
                  bidding: `Max CPV: ${bpCurrency} ${adgroup.maxCPV}`,
                  ads: childEntity.childEntities.length,
                  internalId: childEntity.internalId
                })
              }
            })
          })
        })
      })
      if (!records.length) {
        this.youtubeAdgroupsVerified = true;
        return [];
      }
      return records;
    },
    creativesPivot: function () {
      const campaigns = this.dv360EntitiesResponse.data.childEntities;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.creativesVerified = false;
      }
      let records = [];
      campaigns.forEach(campaign => {
        campaign.childEntities.forEach(io => {
          const lineItems = io.childEntities;
          lineItems.forEach(li => {
            li.childEntities.forEach(childEntity => {
              let targetId = li.additionalData.lineItem.deltaXTargetId;
              if (childEntity.entityType == 4) {
                let creativeData = childEntity.additionalData.creative.creative;
                records.push({
                  id: childEntity.additionalData.creative.hotRowId,
                  landingPage: creativeData.landingPageUrl,
                  targetName: this.savedTargets.find(x => x.id == targetId).name,
                  imgUrl: creativeData.previewImageUrl,
                  creativeType: "Display"
                })
              } else if (childEntity.entityType == 5) {
                childEntity.childEntities.forEach(ad => {
                  let creativeData = ad.additionalData.creative.youtubeCreative;
                  let videoId = creativeData.videoId;
                  records.push({
                    id: ad.additionalData.creative.hotRowId,
                    landingPage: creativeData.landingPageUrl ? creativeData.landingPageUrl : "",
                    targetName: this.savedTargets.find(x => x.id == targetId).name,
                    imgUrl: null,
                    creative: `https://www.youtube.com/watch?v=${videoId}`,
                    displayUrl: creativeData.displayUrl ? creativeData.displayUrl : "",
                    callToAction: creativeData.callToAction ? creativeData.callToAction : "",
                    headline: creativeData.headline ? creativeData.headline : "",
                    longHeadline: creativeData.longHeadline ? creativeData.longHeadline : "",
                    description1: creativeData.description ? creativeData.description : "",
                    description2: creativeData.description2 ? creativeData.description2 : "",
                    creativeType: "Youtube"
                  })
                })
              }
            })
          })
        })
      })
      if (!records.length) {
        this.creativesVerified = true;
        return [];
      }
      return records;
    },
    allVerified: function () {
      this.$emit("elementsVerified", [
        { value: this.budgetTypeVerified, label: "Campaign Setup" },
        { value: this.targetingVerified, label: "Targeting" },
        { value: this.locationsVerified, label: "Locations" },
        { value: this.creativesVerified, label: "Creatives" },
        { value: this.youtubeAdgroupsVerified, label: "AdGroup" },
        { value: this.lineItemVerified, label: "LineItem" }
      ]);
      return "";
    },
    lineItemPivot: function () {
      let self = this;
      if (this.isCampaignLauncherVerificationEnabled) {
        this.lineItemVerified = false;
      }
      let records = [];

      const campaigns = this.dv360EntitiesResponse.data.childEntities;
      campaigns.forEach(campaign => {
        campaign.childEntities.forEach(io => {
          const lineItems = io.childEntities;
          lineItems.forEach(li => {
            let lineItemInfo = li.additionalData.lineItem;
            let adGroup = li.additionalData.adgroup;
            let liObj = {
              name: lineItemInfo.name,
              type: self.getEnumName(dbmEnums.creativeType, lineItemInfo.Type),
              budget: lineItemInfo.Budget,
              targetName: self.dv360Targets.find(x => x.id == lineItemInfo.deltaXTargetId).name
            };
            if (lineItemInfo.Type == 4) {
              if (adGroup && adGroup.type) {
                liObj.objectiveType = self.getEnumName(dbmEnums.lineItemType, adGroup.type);
              }
            }
            records.push(liObj);
          })
        })
      });
      if (!records.length) {
        this.lineItemVerified = true;
      }
      return records;
    }
  },
  methods: {
    getFrequencyString (frequency) {
      let breakdown = frequency.exposures.breakdown
      let value = frequency.exposures.value
      let limit = frequency.limit
      if (breakdown == 1) {
        return limit + " exposures in " + this.dbmEnums.exposureBreakdowns[breakdown]
      }
      return limit + " exposures per " + value + " " + this.dbmEnums.exposureBreakdowns[breakdown]
    },
    getEnumName (enumObj, enumId) {
      for (let [key, val] of Object.entries(enumObj)) {
        if (val == enumId) {
          return key;
        }
      }
    },
    getAdCount (li) {
      let count = 0;
      if (li.additionalData.lineItem.Type == 4) {
        count = li.childEntities.length > 0 ? li.childEntities[0].childEntities.length : 0;
      } else {
        count = li.childEntities.length;
      }

      return count;
    },
    getTargetEnumName (optionString, enumId) {
      let res = "";
      let option = this.options;
      optionString.split('.').forEach(key => {
        option = option[key]
      });
      // if enum id is an object
      if (typeof enumId === 'object' && enumId !== null) {
        enumId = enumId.id;
      }
      if (option.length) {
        let optionName = option.find(x => x.id == enumId);
        if (optionName) {
          res = optionName.name;
        }
      } else {
        res = option[enumId] ? option[enumId] : "";
      }
      return res;
    },
    demographicSummary (index, target) {
      let info = target.demographics[index];
      return {
        ...((info.gender || []).length > 0 && {
          Gender: info.gender.map(x => this.getTargetEnumName("gender", x)).join(", ")
        }),
        ...((info.age || []).length > 0 && {
          Age: info.age.map(x => this.getTargetEnumName("age", x)).join(", ")
        }),
        ...((info.parentalStatus || []).length > 0 && {
          "Parental status": info.parentalStatus
            .map(x => this.getTargetEnumName("parent", x))
            .join(", ")
        }),
        ...((info.income || []).length > 0 && {
          Income: info.income.map(x => this.getTargetEnumName("income", x)).join(", ")
        })
      };
    },
    technologySummary (index, target) {
      let info = target.technology[index];
      let summary = {};
      if (info.devices.length > 0) {
        summary["Devices"] = info.devices.map(x => this.getTargetEnumName("devices", x)).join(", ");
      }
      if (info.browser.values.length > 0) {
        summary["Browsers"] = info.browser.values
          .map(x => this.getTargetEnumName("browsers", x))
          .join(", ")
      }
      if (info.carrierAndISP.values.length > 0) {
        summary[`${info.carrierAndISP.type == 1 ? 'Included' : 'Excluded'} Carrier & Isp`] = info.carrierAndISP.values
          .map(x => this.getTargetEnumName("carrierAndISP", x)).join(", ");
      }
      return summary;
    },
    audienceSummary (index, target) {
      let info = target.audience[index];
      let summary = {};
      if (info.include.type && info.include.list.length > 0) {
        summary["Included Audiences"] = this.getAudienceNames(info.include.type, info.include.list)
      }
      if (info.exclude.type && info.exclude.list.length > 0) {
        summary["Excluded Audiences"] = this.getAudienceNames(info.exclude.type, info.exclude.list)
      }
      return summary;
    },
    brandSafetySummary (index, target) {
      let info = target.brandSafety[index];
      let summary = {};
      if (info.digitalContentLabelExclusions.length > 0) {
        summary["Digital Content Label exclusions"] = info.digitalContentLabelExclusions
          .map(x => this.getTargetEnumName("digitalContentLabelExclusions", x))
          .join(", ")
      }
      if (info.sensitiveCategoryExclusions.length > 0) {
        summary["Sensitive Category exclusions"] = info.sensitiveCategoryExclusions
          .map(x => this.getTargetEnumName("sensitiveCategoryExclusions", x))
          .join(", ")
      }
      if (info.otherVerificationServices.length > 0) {
        summary["Other Verification Services"] = info.otherVerificationServices
          .map(x => this.getTargetEnumName("otherVerificationServices", x))
          .join(", ")
      }
      return summary;
    },
    appsAndUrlsSummary (index, target) {
      let info = target.appsAndUrls[index];
      let summary = {};
      if (info.includedChannels.length > 0) {
        summary["Included channels"] = info.includedChannels.join(", ");
      }
      if (info.excludedChannels.length > 0) {
        summary["Excluded channels"] = info.excludedChannels.join(", ");
      }
      if (info.includedCollections.length > 0) {
        summary["Included collections"] = info.includedCollections.join(", ");
      }
      if (info.excludedCollections.length > 0) {
        summary["Excluded collections"] = info.excludedCollections.join(", ");
      }
      if (info.includedUrls) {
        summary["Excluded Urls"] = info.includedUrls;
      }
      if (info.excludedUrls) {
        summary["Excluded Urls"] = info.excludedUrls;
      }
      if (info.includedApps) {
        summary["Included Apps"] = info.includedApps;
      }
      if (info.excludedApps) {
        summary["Excluded Apps"] = info.excludedApps;
      }
      return summary;
    },
    placementSummary (index, target) {
      let info = target.placements[index];
      let summary = {};

      if (info.includedYtChannels) {
        summary["Included youtube channels"] = info.includedYtChannels.join(", ");
      }
      if (info.excludedYtChannels) {
        summary["Excluded youtube channels"] = info.excludedYtChannels.join(", ");
      }
      if (info.includedYtVideos) {
        summary["Included youtube videos"] = info.includedYtVideos.join(", ");
      }
      if (info.excludedYtVideos) {
        summary["Excluded youtube videos"] = info.excludedYtVideos.join(", ");
      }
      if (info.includedApps) {
        summary["Included Apps"] = info.includedApps;
      }
      if (info.excludedApps) {
        summary["Excluded Apps"] = info.excludedApps;
      }
      return summary;
    },
    viewabilitySummary (index, target) {
      let info = target.viewability[index];
      if (info.activeView) {
        return {
          "Active View": this.getTargetEnumName("viewability", info.activeView)
        }
      }
      return {};
    },
    environmentSummary (index, target) {
      let info = target.environment[index].filter(Boolean);
      let summary = {};
      if (info.length > 0) {
        summary["Environment"] = info
          .map(x => this.getTargetEnumName("environment", x))
          .join(", ");
      }
      return summary;
    },
    keywordsSummary (index, target) {
      let info = target.keywords[index];
      let summary = {};
      if (info.includedKeywords) {
        summary["Included keywords"] = info.includedKeywords;
      }
      if (info.excludedKeywords) {
        summary["Excluded keywords"] = info.excludedKeywords;
      }
      return summary;
    },
    positionsSummary (index, target) {
      let info = target.positions[index];
      let summary = {};
      if (info.onScreen.length > 0) {
        summary["Position on screen"] = info.onScreen.map(x =>
          this.C("positionOnScreen", x)
        ).join(", ");
      }
      if (info.contextDisplay.length > 0) {
        summary["In Context Display"] = info.contextDisplay.map(x =>
          this.getTargetEnumName("inContextDisplay", x)
        ).join(", ");
      }
      if (info.contextNative.length > 0) {
        summary["In Context Native"] = info.contextDisplay.map(x =>
          this.getTargetEnumName("inContextNative", x)
        ).join(", ");
      }
      return summary;
    },
    locAndLangSummary (index, target) {
      let info = target.locAndLang[index];
      let summary = {};
      if (info.includedLocations.length > 0) {
        summary["Included Locations"] = info.includedLocations.map(x =>
          this.getTargetEnumName("locations", x)
        ).join(", ");
      }
      if (info.excludedLocations.length > 0) {
        summary["Excluded Locations"] = info.excludedLocations.map(x =>
          this.getTargetEnumName("locations", x)
        ).join(", ");
      }
      if (info.languages.length > 0) {
        summary["Languages"] = info.languages.map(x =>
          this.getTargetEnumName("languages", x)
        ).join(", ");
      }
      return summary;
    },
    ytDeviceModelSummary (index, target) {
      let info = target.ytDevices[index];
      let summary = {};
      if (info.models.length > 0) {
        summary["YT Device Models"] = info.models.map(x =>
          this.getTargetEnumName("deviceModels", x)
        ).join(", ");
      }
      return summary;
    },
    getFullSummary (targetString) {
      let target = JSON.parse(targetString);
      let summary = {
        Demographics: this.demographicSummary(0, target),
        "Location and Language": this.locAndLangSummary(0, target),
        Technology: this.technologySummary(0, target),
        Audience: this.audienceSummary(0, target),
        "Brand Safety": this.brandSafetySummary(0, target),
        "Apps & URLs": this.appsAndUrlsSummary(0, target),
        Viewability: this.viewabilitySummary(0, target),
        Environment: this.environmentSummary(0, target),
        Keywords: this.keywordsSummary(0, target),
        Position: this.positionsSummary(0, target),
        DeviceModels: this.ytDeviceModelSummary(0, target)
      };

      return Object.fromEntries(
        Object.entries(summary).filter(
          ([_, val]) => Object.keys(val).length > 0
        )
      );
    },
    filterTargetStringToFetch () {
      let self = this;
      let targetDataToFetch = [];

      this.dv360Targets.forEach(item => {
        if (item.targetString) {
          let targetObj = JSON.parse(item.targetString);
          let targetInfo = { id: item.id };
          if (targetObj.locAndLang.length) {
            let includedLocations = [];
            let excludedLocations = [];
            if (targetObj.locAndLang[0].includedLocations && targetObj.locAndLang[0].includedLocations.length) {
              includedLocations = targetObj.locAndLang[0].includedLocations;
            }
            if (targetObj.locAndLang[0].excludedLocations && targetObj.locAndLang[0].excludedLocations.length) {
              excludedLocations = targetObj.locAndLang[0].excludedLocations;
            }
            targetInfo.locations = [...new Set([...includedLocations, ...excludedLocations])];
          }
          // if (targetObj.audience.length) {
          //   if (targetObj.audience[0].include && targetObj.audience[0].include.list.length) {
          //     targetInfo.includedAudiences = { type: targetObj.audience[0].include.type, values: targetObj.audience[0].include.list }
          //   }
          //   if (targetObj.audience[0].exclude && targetObj.audience[0].exclude.list.length) {
          //     targetInfo.excludedAudiences = { type: targetObj.audience[0].exclude.type, values: targetObj.audience[0].exclude.list }
          //   }
          // }
          if (targetObj.ytDevices.length) {
            if (targetObj.ytDevices[0].models && targetObj.ytDevices[0].models.length) {
              targetInfo.deviceModels = targetObj.ytDevices[0].models;
            }
          }
          if (targetObj.technology.length) {
            if (targetObj.technology[0].carrierAndISP && targetObj.technology[0].carrierAndISP.values.length) {
              targetInfo.carrierAndISPs = targetObj.technology[0].carrierAndISP.values;
            }
          }

          // set audience targeting options from dynamic target string and target string
          let dynamicTargetStringObj = JSON.parse(item.dynamicTargetString);
          dynamicTargetStringObj.adGroups && dynamicTargetStringObj.adGroups.forEach(adGroup => {
            let includedAudiences = adGroup.audience[0].include.list;
            let excludedAudiences = adGroup.audience[0].exclude.list;

            self.options.audience[adGroup.audience[0].include.type] = [...self.options.audience[adGroup.audience[0].include.type], ...includedAudiences]
            self.options.audience[adGroup.audience[0].exclude.type] = [...self.options.audience[adGroup.audience[0].exclude.type], ...excludedAudiences]
          });
          if (dynamicTargetStringObj.audience && dynamicTargetStringObj.audience.length) {
            let includedAudiences = dynamicTargetStringObj.audience[0].include.list;
            let excludedAudiences = dynamicTargetStringObj.audience[0].exclude.list;

            self.options.audience[dynamicTargetStringObj.audience[0].include.type] = [...self.options.audience[dynamicTargetStringObj.audience[0].include.type], ...includedAudiences]
            self.options.audience[dynamicTargetStringObj.audience[0].exclude.type] = [...self.options.audience[dynamicTargetStringObj.audience[0].exclude.type], ...excludedAudiences]
          }
          targetDataToFetch.push(targetInfo);
        }
      });
      this.fetchDV360TargetingOptions(targetDataToFetch);
    },
    fetchDV360TargetingOptions (targetDataToFetch) {
      let self = this;
      APIService.PostDV360TargetStringData(this.selectedCampaignLauncherConfig.bpseId, targetDataToFetch).then(response => {
        let targetingInfo = response.data;
        // set device models names
        if (targetingInfo.deviceModels && targetingInfo.deviceModels.length) {
          let deviceModelsNames = [];
          targetingInfo.deviceModels.forEach(model => {
            deviceModelsNames.push({ id: model.targetingOptionId, name: model.deviceMakeModelDetails.displayName });
          });
          self.$set(self.options, "deviceModels", deviceModelsNames);
        }
        // set carrier isp names
        if (targetingInfo.carrierAndISPs && targetingInfo.carrierAndISPs.length) {
          let carrierAndIspNames = [];
          targetingInfo.carrierAndISPs.forEach(model => {
            carrierAndIspNames.push({ id: model.targetingOptionId, name: model.carrierAndIspDetails.displayName });
          });
          self.$set(self.options, "carrierAndISP", carrierAndIspNames);
        }
        // set location names
        if (targetingInfo.locations && targetingInfo.locations.length) {
          let locationNames = [];
          targetingInfo.locations.forEach(model => {
            locationNames.push({ id: model.targetingOptionId, name: model.geoRegionDetails.displayName });
          });
          self.$set(self.options, "locations", locationNames);
        }
        this.isTargetingDataFetched = true;
        if (!this.isCampaignLauncherVerificationEnabled) {
          this.targetingVerified = true;
        }
      }).catch(error => {
        self.$Message.error("Unable to fetch targeting options");
        console.log(error);
      });
    },
    setTargetAdGroups (targetId, adGroups) {
      adGroups.forEach(adGroup => {
        adGroup.targetId = targetId;
        this.targetAdGroups.push(adGroup);
      });
    },
    targetAdGroupPivot (targetId) {
      return this.targetAdGroups.filter(adGroup => adGroup.targetId == targetId);
    },
    getTargetAdGroupSummary (adGroup) {
      let summary = {
        Demographics: this.demographicSummary(0, adGroup),
        Audience: this.audienceSummary(0, adGroup),
        Placements: this.placementSummary(0, adGroup),
        Keywords: this.keywordsSummary(0, adGroup)
      };

      return Object.fromEntries(
        Object.entries(summary).filter(
          ([_, val]) => Object.keys(val).length > 0
        )
      );
    },
    getAdGroupData (adGroupId) {
      return this.targetAdGroups.find(adGroup => adGroup.id == adGroupId);
    },
    getAdGroupCountFromTargetString (targetString) {
      let targetStringObj = JSON.parse(targetString);
      if (targetStringObj.adGroups && targetStringObj.adGroups.length) {
        return targetStringObj.adGroups.length;
      } else {
        return 1;
      }
    },
    getAudienceNames (audienceType, audienceIds) {
      let self = this;
      let audienceNames = [];
      audienceIds.forEach(audienceId => {
        let audience = self.options.audience[audienceType].find(x => x.id == audienceId);
        if (audience) {
          audienceNames.push(audience.name);
        } else {
          // show existing ids for old targets
          audienceNames.push(audienceId);
        }
      });
      return audienceNames.join(", ");
    }
  },
  // async mounted () {
  //   this.filterTargetStringToFetch();
  // },
  watch: {
    dv360EntitiesResponse: {
      deep: true,
      immediate: true,
      handler: function (val) {
        this.isTargetingDataFetched = false;
        let campaigns = val.data.childEntities;
        let targetsToFetch = []
        campaigns.forEach(campaign => {
          campaign.childEntities.forEach(io => {
            let lineItems = io.childEntities;
            lineItems.forEach(li => {
              targetsToFetch.push(li.additionalData.lineItem.deltaXTargetId);
            })
          })
        });
        let self = this;
        self.$store.state.dv360Targets = [];
        APIService.getTargets(targetsToFetch.join(",")).then(res => {
          self.$store.state.dv360Targets = res;
        }).catch(e => {
          self.$Message.error("Unable to fetch targets");
        })
      }
    },
    dv360Targets: {
      deep: true,
      immediate: true,
      handler: function (val) {
        let self = this;
        if (val && val.length) {
          // if adgroups is present then set the adGroups
          val.forEach((target) => {
            let targetStringObj = JSON.parse(target.targetString);
            if (targetStringObj.adGroups && targetStringObj.adGroups.length) {
              self.setTargetAdGroups(target.id, targetStringObj.adGroups);
            }
          })
          this.filterTargetStringToFetch();
        }
      }
    }
  }
};
</script>

<style scoped>
img[lazy=loading] {
  min-height: 2rem;
  min-width: 2rem;
  animation: placeholder-animate 1.5s ease-in-out infinite;
  background:rgba(0, 0, 0, 0.08);
}
@keyframes placeholder-animate {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.creative-info-key {
  text-align: right;
  vertical-align: middle;
  font-weight: bold;
}
.creative-info-value {
  text-align: left;
  vertical-align: middle;
}.target-adgroup {
  margin-top: 8px;
}
.title-sticky {
  position: sticky;
  padding: 0px 5px;
  position: sticky;
  top: 100px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.element-label {
  font-size: large;
  padding: 10px 10px 10px 0;
}
.verified-action {
  float: right;
  padding: 10px;
}
.checkbox-status-label {
  padding-right: 5px;
  color: #999;
  cursor: pointer;
}
input[type="checkbox"] {
  vertical-align: text-bottom;
}
.total-footer {
  font-weight: 600;
  background-color: #eee;
}
.table-footer-label {
  border: 1px solid #ddd;
  padding: 0px 5px;
}
.target-label {
  padding-top: 10px;
}
.target-sub-label {
  padding-top: 10px;
  font-size: 0.9em;
  color: #979898;
  max-width: 420px;
}
.page-label {
  padding: 10px 5px;
  position: sticky;
  top: 140px;
  background: white;
  z-index: 10;
  border: 1px solid #eee;
}
.creatives-li {
  padding-bottom: 5px;
  list-style: none;
}
.more-previews {
  font-size: 1.3em;
}
.data-right {
  text-align: right;
}
.data-center {
  text-align: center;
  vertical-align: middle;
}
.upper-case {
  text-transform: uppercase;
}
::v-deep .ivu-modal-footer {
  display: none;
}
.table-header{
  border:1px solid #ddd !important;
  padding:10px !important;
}
</style>
