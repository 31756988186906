import axios from 'axios'

const axiosInstance = axios.create()
axiosInstance.interceptors.request.use(function (config) {
  let accessTokenCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('AccessToken'))
  let accessTokenCookieKeyValue = accessTokenCookie ? accessTokenCookie.split('=') : [];
  let accessToken = accessTokenCookieKeyValue.length > 0 ? accessTokenCookieKeyValue[1] : '';
  if (accessToken) {
    config.headers.Authorization = `Bearer ${accessToken}`
  }
  config.headers['Content-Type'] = 'application/json'
  return config
})

axiosInstance.interceptors.response.use(
  response => response,
  async error => {
    switch (error.response.status) {
      case 401:
        await axios.get(`${window.location.protocol}//${window.location.host}/App/Account/Logout`);
        window.location.href = `/App/Login`;
        break;
    }
    return Promise.reject(error);
  }
);

export default axiosInstance
