import { Objective } from "./Objective.js"
import { platforms, positions } from "../../Constants/FacebookEnums.js"

let ColumnConfig = function () {
  this.single = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Link", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Call To Action", "HOT_Ad Title", "HOT_Display Link", "HOT_Text", "HOT_Description", "HOT_Url Tags", "HOT_ViewTags", "HOT_Phone Number", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Image/Video"];
    this.card = []
    this.requiredCard = []
    this.callToAction = ["No Button", "Apply Now", "Download", "Learn More", "Shop Now", "Sign Up", "Watch More", "Book Travel", "Call Now", "Subscribe"];
    this.placementCustomizationHeaders = ["HOT_Placements", "HOT_Image/Video", "HOT_Text", "HOT_Ad Title", "HOT_Link"];
    this.DynamicCreativeHeaders = ["HOT_Placements", "HOT_Image/Video", "HOT_Primary Text", "HOT_Description", "HOT_Headline"];
    this.promoModeAutoFill = {
      "HOT_Phone Number": {
        macro: "{{store.phonenumber}}"
      }
    }
  }();
  this.carousel = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_See More Url", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Ad Title", "HOT_Text", "HOT_Description", "HOT_See More Display Url", "HOT_Url Tags", "HOT_ViewTags", "HOT_Phone Number", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
    this.requiredBase = ["HOT_Ad Name", "HOT_See More Url"]
    this.card = ["HOT_Link", "HOT_Headline", "HOT_Image/Video", "HOT_Description"];
    this.requiredCard = ["HOT_Link", "HOT_Image/Video"]
    this.callToAction = ["No Button", "Apply Now", "Download", "Learn More", "Shop Now", "Sign Up", "Watch More", "Book Travel", "Call Now", "Subscribe"];
    this.promoModeAutoFill = {
      "HOT_Phone Number": {
        macro: "{{store.phonenumber}}"
      }
    }
  }();
  this.collection = new function () {
    this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Instant Experience", "HOT_Message", "HOT_Call To Action", "HOT_Headline", "HOT_ViewTags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations"];
    this.requiredBase = ["HOT_Ad Name", "HOT_Message", "HOT_Instant Experience"];
    this.card = []
    this.requiredCard = []
    this.callToAction = ["No Button", "Apply Now", "Download", "Learn More", "Shop Now", "Sign Up", "Watch More", "Book Travel", "Subscribe"];
  }();
}
let CreativeSpec = function () {
  this.single = new function () {
    this.image = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "link_data": {
            "picture": "HOT_Image/Video",
            "link": "HOT_Link",
            "name": "HOT_Ad Title",
            "description": "HOT_Description",
            "message": "HOT_Text",
            "caption": "HOT_Display Link",
            "call_to_action": {
              "type": "HOT_Call To Action",
              "value": {
                "link": "HOT_Link"
              }
            }
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    };
    this.video = {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "video_data": {
            "video_id": "HOT_SearchEngineVideoId",
            "image_url": "HOT_Image/Video",
            "title": "HOT_Ad Title",
            "message": "HOT_Text",
            "call_to_action": {
              "type": "HOT_Call To Action",
              "value": {
                "link": "HOT_Link"
              }
            }
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    };
    this.placementCustomization = {}
  }()
  this.carousel = {
    "creative": {
      "object_story_spec": {
        "link_data": {
          "call_to_action": {
            "type": "HOT_Call To Action",
            "value": {
              "link": "HOT_See More Url"
            }
          },
          "description": "HOT_Description",
          "link": "HOT_See More Url",
          "message": "HOT_Text",
          "name": "HOT_Ad Name",
          "caption": "www.deltax.com",
          "child_attachments": [
            {
              "link": "HOT_Link",
              "picture": "HOT_Image/Video",
              "name": "HOT_Headline",
              "description": "HOT_Description"
            }
          ],
          "multi_share_optimized": "HOT_Optimize Card Order"
        },
        "page_id": "HOT_Page",
        "instagram_actor_id": "HOT_InstagramId"
      },
      "url_tags": "HOT_Url Tags"
    }
  };
  this.collection = new function () {
    this.image = {
      "creative": {
        "object_story_spec": {
          "link_data": {
            "link": "HOT_Link",
            "message": "HOT_Message",
            "name": "HOT_Headline",
            "picture": "HOT_Image/Video",
            "retailer_item_ids": [
              "0",
              "0",
              "0",
              "0"
            ]
          },
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId"
        }
      }
    };
    this.video = {
      "creative": {
        "object_story_spec": {
          "video_data": {
            "call_to_action": {
              "value": {
                "link": "HOT_Link"
              },
              "type": "LEARN_MORE"
            },
            "retailer_item_ids": [
              "0",
              "0",
              "0",
              "0"
            ],
            "video_id": "HOT_SearchEngineVideoId",
            "message": "HOT_Message",
            "title": "HOT_Headline"
          },
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId"
        }
      }
    }
  }();
}
let AdFormats = {
  "Image or Carousel": {
    "required": true,
    "adSheets": ["single", "carousel"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.MarketPlace,
        positions.Facebook.VideoFeeds,
        positions.Facebook.Story,
        positions.Facebook.InstreamVideo,
        positions.Facebook.InstantArticle
      ],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Story,
        positions.Instagram.Explore],
      [platforms.AudienceNetwork]: [
        positions.AudienceNetwork.InstreamVideo
      ]
    }
  },
  "Video": {
    "adSheets": ["single"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.MarketPlace,
        positions.Facebook.VideoFeeds,
        positions.Facebook.Story,
        positions.Facebook.InstreamVideo,
        positions.Facebook.InstantArticle
      ],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Story,
        positions.Instagram.Explore],
      [platforms.AudienceNetwork]: [
        positions.AudienceNetwork.InstreamVideo
      ]
    }
  }
  // "Instant Experience": {
  //   "adSheets": ["collection"]
  // }
}
let reach = new Objective(new ColumnConfig(), new CreativeSpec(), AdFormats)
export { reach }
