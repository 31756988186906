const dbmEnums = {
  campaignGoals: {
    1: "Raise awareness of my brand or product",
    2: "Drive online action or visits",
    3: "Drive offline or in-store sales",
    4: "Drive app installs or engagements"
  },
  creativeType: {
    "Display": 1,
    // "Video": 2
    // "Audio": 3,
    "Youtube": 4
  },
  insertionOrderType: {
    1: "Real Time Bidding"
  },
  optimizationType: {
    1: "Auto bid & budget at IO level",
    2: "Manual bid & budget at LI level"
  },
  optimizationPerformanceOptions: {
    1: "Maximize conversions",
    2: "Maximize clicks"
  },
  optimizationBrandOptions: {
    3: "Maximize viewable impressions"
    // 4: "Maximize completed in-view and audible",
    // 5: "Maximize viewable for at least 10 seconds"
  },
  ioNameMacros: [
    "MP_name",
    "LI_name",
    "Creative_type",
    "Goal",
    "LI_Id"
  ],
  ioKpiType: {
    1: "CPM",
    2: "CPC",
    3: "CPA",
    4: "Viewability %",
    5: "CPIAVC",
    7: "CTR",
    8: "CPV"
  },
  exposureBreakdowns: {
    1: "Lifetime",
    2: "Months",
    3: "Week",
    4: "Days",
    5: "Hours",
    6: "Minutes",
    7: "No Limit"
  },
  ioStatus: {
    1: "Active",
    3: "Paused"
  },
  budgetTypeColumns: [
    "AccountId",
    "Insertion Order",
    "IO Type",
    "Bidding",
    "Budget",
    "Frequency",
    "Status",
    "Public Inventory"
  ],
  targetingColumns: [
    "Targets",
    "No. of Line Items",
    "No. of Ad Groups"
  ],
  locationsColumns: [
    "Location",
    "No. of Line Items"
  ],
  creativeColumns: [
    "Ad Copy Id",
    "Creative",
    "Landing Page",
    "Targets"
  ],
  youtubeAdgroupColumns: [
    "Adgroup Name",
    "Targeting",
    "Objective Type",
    "Ad Format",
    "Bidding",
    "No. of Ads"
  ],
  lineItemColumns: [
    "Name",
    "Budget",
    "Type",
    "Objective Type",
    "Target Name"
  ],
  campaignStatus: {
    1: "New",
    3: "Paused",
    8: "Success",
    10: "Failed"
  },
  maxExposureValue: [1, 2, 4, 6, 23, 59],
  pacingPeriod: {
    1: "Flight",
    2: "Daily"
  },
  pacingType: {
    1: "Ahead",
    2: "ASAP",
    3: "Even"
  },
  lineItemType: {
    "Brand Awareness and Reach": 1,
    // "Shopping": 2,
    "Drive Conversions": 3,
    "Product and Brand Consideration": 4
  },
  liAdformat: {
    "In-stream": 1,
    // "Video Discovery": 2,
    "Bumper": 3,
    "Non-skippable": 4,
    "In-feed video": 5,
    "Responsive": 6,
    "Efficient reach": 7
  },
  lineItemSubType: {
    "Reach": 1,
    "Simple": 2,
    "Non Skippable": 3,
    "Action": 4,
    "View": 5,
    "Target Frequency": 6
  },
  // the field id indicate the lineItemType and the value's id indicate the lineItemSubType
  objectiveBasedSubTypes: {
    1: {
      "Efficient Reach": 1,
      "Non Skippable ads": 3,
      "Target Frequency": 6
    },
    3: {
      "Video Action": 4
    },
    4: {
      "Video Views": 5
      // "Simple": 2 // simple is handeled using "includeProductShortAds" data property check in Campaign Config
    }
  },
  // the field id indicate the lineItemSubType and the value's id indicate the liAdformat
  subTypeBasedAdFormats: {
    1: {
      "In-stream": 1,
      "Bumper": 3,
      "Non-skippable": 4
    },
    2: {
      "Skippable ads": 1,
      "In-feed video ads": 5
    },
    3: {
      "Non-skippable": 4
    },
    4: {
      "Responsive": 6
    },
    5: {
      "Responsive": 6
    },
    6: {
      "Non-skippable": 4,
      "Efficient reach": 7
    }

  },
  entityType: {
    "Campaign": 1,
    "Insertion Order": 2,
    "Line Item": 3,
    "Creative": 4,
    "Ad Group": 5
  },
  lineItemExposureBreakdowns: {
    1: "No Limit",
    2: "Months",
    3: "Week",
    4: "Days"
  }
};

export { dbmEnums };
