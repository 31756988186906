<template>
  <Form
    label-position="right"
    :label-width="200"
    :model="config"
    :rules="validationRules"
    ref="formAmsSP"
  >
    <div v-show="showConfigForm">
      <Divider orientation="left">Accounts and Assets</Divider>

      <Form-Item label="Type">
        <Row>
          <Row-Col span="18">
            Sponsored Products
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Amazon Account" prop="bpseId">
        <Row>
          <Row-Col span="18">
            <Select
              v-model="config.bpseId"
              placeholder="Select an account"
              :transfer="true"
              filterable
            >
              <Option
                v-for="(item, index) in amsAccounts"
                :key="index"
                :value="item.id"
                :disabled="item.IsOauthExpired"
                >{{ item.accountId + " - " + item.accountNickName }}</Option
              >
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Portfolio" prop="portfolioId">
        <Row>
          <Row-Col span="18">
            <Select
              ref="filter"
              v-model="config.portfolio"
              placeholder="Select a portfolio"
              filterable
            >
              <OptionGroup label="Active">
                  <Option
                    v-for="(item, index) in activePortfolios"
                    :key="index"
                    :value="item.id"
                    >{{ item.portfolioId + " - " + item.name }}
                  </Option>
              </OptionGroup>
              <OptionGroup label="Paused">
                  <Option
                    v-for="(item, index) in pausedPortfolios"
                    :key="index"
                    :value="item.id"
                    >{{ item.portfolioId + " - " + item.name }}
                  </Option>
              </OptionGroup>
              <OptionGroup label="Pending">
                  <Option
                    v-for="(item, index) in pendingPortfolios"
                    :key="index"
                    :value="item.id"
                    >{{ item.portfolioId + " - " + item.name }}
                  </Option>
              </OptionGroup>
              <OptionGroup label="Others">
                  <Option
                    v-for="(item, index) in otherPortfolios"
                    :key="index"
                    :value="item.id"
                    >{{ item.portfolioId + " - " + item.name }}
                  </Option>
              </OptionGroup>
            </Select>
          </Row-Col>
        </Row>
      </Form-Item>

      <Divider orientation="left">Campaign Settings</Divider>

      <Form-Item label="Campaign Mode" prop="campaign.status">
        <Row>
          <Row-Col>
            <RadioGroup v-model="config.campaign.status">
              <Radio :label="name" v-for="(name, id) in amsEnums.status" :key="id">
                <span> {{ id }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>

      <Form-Item label="Campaign Name" prop="campaign.name">
        <Row>
          <Row-Col span="18">
            <i-input :disabled="isNomenclatureEnabled" v-model="config.campaign.name" clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
      <Form-Item label="Scheduling" prop="campaign.scheduling">
        <RadioGroup v-model="config.campaign.scheduling">
          <Radio :label="name" v-for="(name, id) in amsEnums.scheduling" :key="id">
                <span> {{ id }} </span>
          </Radio>
        </RadioGroup>
      </Form-Item>

      <Form-Item label="Campaign Grouping" prop="campaign.grouping">
        <RadioGroup v-model="config.campaign.grouping">
          <Radio :label="name" v-for="(name, id) in amsEnums.campaignGrouping" :key="id">
                <span> {{ id }} </span>
          </Radio>
        </RadioGroup>
      </Form-Item>

      <Divider orientation="left">Bidding and Targeting</Divider>
      <Form-Item label="Targeting Type" prop="campaign.targetingTypeForProducts">
        <Row>
          <Row-Col span="18">
            <RadioGroup v-model="config.campaign.targetingType" type="button" button-style="solid">
              <Radio :label="name" v-for="(name, id) in amsEnums.targetingTypeForProducts" :key="id">
                <span> {{ id }} </span>
              </Radio>
            </RadioGroup>
          </Row-Col>
        </Row>
      </Form-Item>
      <template v-if="config.campaign.targetingType == '1'">
        <Form-Item label="Campaign Bidding Strategy" prop="campaign.biddingStrategy">
          <Row>
            <Row-Col span="18">
              <RadioGroup v-model="config.campaign.biddingStrategy" vertical>
                <Radio  :label="name" v-for="(name, id) in amsEnums.biddingStrategy" :key="id">
                  <span> {{ id }} </span>
                </Radio >
              </RadioGroup>
            </Row-Col>
          </Row>
        </Form-Item>
        <Form-Item label="Bid Adjustment" prop="campaign.bidAdjustent">
          <Row>
            <Row-Col span="3">
              <label>First Page</label>
            </Row-Col>
            <Row-Col span="5" offset="1">
                <InputNumber
                :min="0"
                :max="100"
                placeholder="Adjustment %"
                v-model="config.campaign.bidAdjustment.firstPage"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')">
                style="width: 500px"
              </InputNumber>
            </Row-Col>
          </Row>
          <Row>
            <Row-Col span="3">
              <label>Product Page</label>
            </Row-Col>
            <Row-Col span="5" offset="1">
              <InputNumber
                :min="0"
                :max="100"
                placeholder="Adjustment %"
                v-model="config.campaign.bidAdjustment.productPage"
                :formatter="value => `${value}%`"
                :parser="value => value.replace('%', '')">
              </InputNumber>
            </Row-Col>
          </Row>
        </Form-Item>
      </template>
      <Form-Item label="Config Name" prop="name">
        <Row>
          <Row-Col span="18">
            <i-input v-model="config.name" clearable/>
          </Row-Col>
        </Row>
      </Form-Item>
    </div>
  </Form>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  RadioGroup,
  Radio,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  InputNumber,
  OptionGroup
} from "iview";
import { mapState, mapMutations } from "vuex";
import { APIService } from "./../../ApiService.js";
import CLHelperMethodsMixin from "../../Mixins/CLHelperMethods.js";

export default {
  props: {
    loadExistingConfigId: { default: "" },
    showConfigForm: { default: false },
    openConfigurationModal: { default: false },
    isExistingConfig: { default: false },
    nomenclatureMacro: { default: null }
  },
  components: {
    Divider,
    Row,
    "Row-Col": Col,
    Radio,
    RadioGroup,
    Form,
    FormItem,
    Select,
    Option,
    OptionGroup,
    "i-input": Input,
    InputNumber
  },
  data () {
    return {
      portfolioStatus: {
        Active: 1,
        Paused: 3,
        Pending: 4,
        Others: 99
      },
      config: {
        searchEngineTypeId: 11,
        name: "",
        bpseId: 0,
        portfolio: 0,
        campaign: {
          name: "",
          scheduling: 1,
          grouping: 1,
          targetingType: 1,
          defaultBid: null,
          biddingStrategy: 1,
          bidAdjustment: {
            firstPage: null,
            productPage: null
          },
          status: 1,
          checkIfValid: false
        }
      },
      validationRules: {
        bpseId: [
          {
            validator: (rule, value, callback) => {
              if (this.checkIfValid && (isNaN(value) || value <= 0)) {
                callback(new Error("Please select an account"));
              }
              callback();
            }
          }
        ],
        "campaign.status": [
          { required: true, message: 'Please select a campaign status' }
        ],
        "campaign.name": [
          { required: true, message: 'Please enter a campaign name' }
        ],
        "campaign.scheduling": [
          { required: true, message: 'Please select a campaign schedule type' }
        ],
        "campaign.grouping": [
          { required: true, message: 'Please select a campaign grouping type' }
        ],
        "campaign.bidLimit": [
          { required: true, type: 'number', message: 'Please enter a valid bid limit' }
        ],
        "campaign.defaultBid": [
          {
            type: "number",
            min: 0.1,
            required: true,
            message: "Please enter a valid bid",
            trigger: "change"
          }
        ],
        "name": [
          { required: true, message: 'Please enter a configuration name' }
        ]
      },
      isNomenclatureEnabled: false,
      pendingPortfolios: [],
      activePortfolios: [],
      pausedPortfolios: [],
      otherPortfolios: []
    };
  },
  created () {
    this.getAmsPortfolios();
  },
  mixins: [CLHelperMethodsMixin],
  watch: {
    loadExistingConfigId (id) {
      if (id) {
        this.config = JSON.parse(this.campaignLauncherConfigs[id].config);
        if (!this.isExistingConfig) {
          this.config.name = this.appendCopyToConfigName(this.config.name);
        }
      }
    },
    "config.campaign.targetingType": function (type) {
      if (type == 2) {
        this.config.campaign.defaultBid = null
        this.config.campaign.biddingStrategy = 1
        this.config.campaign.bidAdjustment.firstPage = null
        this.config.campaign.bidAdjustment.productPage = null
      }
    },
    "config.bpseId": function (newValue, oldValue) {
      if (oldValue != newValue && newValue != undefined && newValue != 0) {
        this.config.portfolio = 0;
        this.getAmsPortfolios();
      }
    },
    "openConfigurationModal": function () {
      if (this.openConfigurationModal) {
        if (this.nomenclatureMacro && this.nomenclatureMacro.campaign && this.nomenclatureMacro.campaign != '') {
          this.isNomenclatureEnabled = true;
          this.config.campaign.name = this.nomenclatureMacro.campaign;
        } else {
          this.isNomenclatureEnabled = false;
          this.config.campaign.name = this.getCampaignName();
        }
      }
    }
  },
  computed: {
    ...mapState([
      "amsEnums",
      "amsAccounts",
      "businessProfileId",
      "campaignLauncherConfigs",
      "publisher",
      "mediaPlanId",
      "selectedMediaPlan",
      "bpCurrencySymbol"
    ])
  },
  methods: {
    ...mapMutations([]),
    getAmsPortfolios () {
      APIService.GetAmsPortfolios(this.config.bpseId)
        .then(response => {
          this.pendingPortfolios = [];
          this.activePortfolios = [];
          this.pausedPortfolios = [];
          this.otherPortfolios = [];
          response.data.data.forEach(element => {
            if (element.status == this.portfolioStatus.Active) {
              this.activePortfolios.push(element);
            } else if (element.status == this.portfolioStatus.Paused) {
              this.pausedPortfolios.push(element);
            } else if (element.status == this.portfolioStatus.Pending) {
              this.pendingPortfolios.push(element);
            } else if (element.status == this.portfolioStatus.Others) {
              this.otherPortfolios.push(element);
            }
          });
        })
    },
    getConfig () {
      return this.config;
    },
    resetFields () {
      this.config.campaign.bidAdjustment.firstPage = null
      this.config.campaign.bidAdjustment.productPage = null
      this.config.portfolio = 0
      this.$refs["formAmsSP"].resetFields();
      this.$refs['filter'].reset();
    },
    isAMSFormValid (saveCallback) {
      this.checkIfValid = true;
      this.$refs["formAmsSP"].validate((valid) => {
        if (valid) {
          saveCallback();
        }
        this.checkIfValid = false;
      })
    },
    getCampaignName: function () {
      var suggestedSubmissionName = this.selectedMediaPlan.name + " - " + new Date().toLocaleString() + " - AMS Sponsored Products"
      return suggestedSubmissionName
    }
  }
};
</script>
<style scoped>
.ivu-input-number{
      width: 100px;
}
.ivu-radio-group-button .ivu-radio-wrapper>span {
    margin-left: 0;
    color:inherit !important;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked:first-child {
    border-color: #51b848 !important;
    background-color: #51b848;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white !important;
}
.ivu-radio-group-button .ivu-radio-wrapper:first-child {
    border-radius: 4px 0 0 4px;
    border-left: 1px solid #dcdee2;
    color: #515a6e;
}
.ivu-radio-group-button .ivu-radio-wrapper:last-child {
    border-radius: 0 4px 4px 0;
    color: #515a6e;
}
.ivu-radio-group-button .ivu-radio-wrapper-checked:last-child {
    border-color: #51b848 !important;
    background-color: #51b848;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: white;
}
</style>
