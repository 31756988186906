import { Objective } from "./Objective.js"

let ColumnConfig = {
  single: {
    base: ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Website Url", "HOT_Call To Action", "HOT_Primary Text", "HOT_Deep Link Destination", "HOT_News Feed Link Description", "HOT_Android Url", "HOT_iOS Url", "HOT_Windows Phone Url", "HOT_Ad Title", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"],
    requiredBase: ["HOT_Ad Name", "HOT_Website Url", "HOT_Call To Action", "HOT_Primary Text", "HOT_Deep Link Destination", "HOT_News Feed Link Description"],
    card: [],
    requiredCard: [],
    callToAction: ["Download", "Learn More", "Sign Up", "Subscribe", "Book Now", "Get Showtimes", "Listen Now", "Open Link", "Shop Now"]
  },
  carousel: {
    base: ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_See More Url", "HOT_Deep Link", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Catalog Options", "HOT_Deep Link Destination", "HOT_Catalog Card: Creative", "HOT_Catalog Card: Headline", "HOT_Catalog Card: News Feed Link Description", "HOT_Android Url", "HOT_iOS Url", "HOT_Windows Phone Url", "HOT_Ad Title", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"],
    // "HOT_Intro Card: Image", "HOT_Intro Card: Headline", "HOT_Intro Card: News Feed Link Description", "HOT_Intro Card: Website Url",
    requiredBase: ["HOT_Ad Name", "HOT_Primary Text", "HOT_Deep Link", "HOT_Call To Action", "HOT_Catalog Options", "HOT_See More Url", "HOT_Deep Link Destination", "HOT_Catalog Card: Creative", "HOT_Catalog Card: Headline", "HOT_Catalog Card: News Feed Link Description"],
    card: [],
    requiredCard: [],
    callToAction: ["Download", "Learn More", "Sign Up", "Subscribe", "Book Now", "Get Showtimes", "Listen Now", "Open Link", "Shop Now"]
  },
  collection: {
    base: [
      "HOT_Preview Ad",
      "HOT_Ad Name",
      // "HOT_Instant Experience",
      // "HOT_Product Set",
      "HOT_Image/Video",
      "HOT_Video_Thumbnail",
      "HOT_Button Label",
      "HOT_Button Destination",
      // "HOT_Products Featured",
      "HOT_Primary Text",
      "HOT_Deep Link Destination",
      "HOT_Headline",
      "HOT_Call To Action",
      "HOT_Deep Link",
      "HOT_Instant Experience Name",
      "HOT_Android Url",
      "HOT_iOS Url",
      "HOT_Windows Phone Url",
      "HOT_Url Tags",
      "HOT_StartDate",
      "HOT_EndDate",
      "HOT_StartTime",
      "HOT_EndTime",
      "HOT_Targets",
      "HOT_Locations",
      "HOT_LineItemFractions"
    ],
    requiredBase: [
      // "HOT_Instant Experience",
      // "HOT_Product Set",
      "HOT_Ad Name",
      "HOT_Image/Video",
      "HOT_Button Label",
      "HOT_Button Destination",
      // "HOT_Products Featured",
      "HOT_Primary Text",
      "HOT_Headline",
      "HOT_Deep Link Destination"
    ],
    card: [],
    requiredCard: [],
    callToAction: ["No Button"]
  }
}

let CreativeSpec = {
  single: {
    catalogueInformation: {
      overlay_shape: "HOT_Catalogue Information Shape",
      text_font: "HOT_Catalogue Information Font",
      shape_color: "HOT_Catalogue Information Shape Colour",
      text_color: "HOT_Catalogue Information Text Colour",
      opacity: "HOT_Catalogue Information Opacity",
      overlay_position: "HOT_Catalogue Information Position",
      layer_type: "text_overlay",
      content: {
        type: "HOT_Catalogue Information Type"
      }
    },
    frame: {
      layer_type: "frame_overlay",
      blending_mode: "HOT_Frame Blending",
      frame_image_hash: "HOT_Frame Image",
      frame_source: "custom",
      opacity: "HOT_Frame Opacity",
      overlay_position: "HOT_Frame Position",
      scale: "HOT_Frame Size"
    },
    "creative": {
      "object_story_spec": {
        "page_id": "HOT_Page",
        "instagram_actor_id": "HOT_InstagramId",
        "template_data": {
          "call_to_action": {
            "type": "HOT_Call To Action"
          },
          "description": "HOT_News Feed Link Description",
          "force_single_link": true,
          "link": "HOT_Website Url",
          "message": "HOT_Primary Text",
          "name": "HOT_Ad Title"
        }
      },
      "applink_treatment": "HOT_Deep Link Destination",
      "template_url_spec": {
        "android": {
          "url": "HOT_Android Url"
        },
        "ios": {
          "url": "HOT_iOS Url"
        },
        "web": {
          "url": "HOT_Website Url"
        },
        "windows_phone": {
          "url": "HOT_Windows Phone Url"
        }
      },
      "url_tags": "HOT_Url Tags"
    }
  },
  carousel: {
    catalogueInformation: {
      overlay_shape: "HOT_Catalogue Information Shape",
      text_font: "HOT_Catalogue Information Font",
      shape_color: "HOT_Catalogue Information Shape Colour",
      text_color: "HOT_Catalogue Information Text Colour",
      opacity: "HOT_Catalogue Information Opacity",
      overlay_position: "HOT_Catalogue Information Position",
      layer_type: "text_overlay",
      content: {
        type: "HOT_Catalogue Information Type"
      }
    },
    frame: {
      layer_type: "frame_overlay",
      blending_mode: "HOT_Frame Blending",
      frame_image_hash: "HOT_Frame Image",
      frame_source: "custom",
      opacity: "HOT_Frame Opacity",
      overlay_position: "HOT_Frame Position",
      scale: "HOT_Frame Size"
    },
    "creative": {
      "object_story_spec": {
        "page_id": "HOT_Page",
        "instagram_actor_id": "HOT_InstagramId",
        "template_data": {
          "message": "HOT_Primary Text",
          "link": "HOT_See More Url",
          "name": "HOT_Catalog Card: Headline",
          "call_to_action": {
            "type": "HOT_Call To Action"
          },
          "description": "HOT_Catalog Card: News Feed Link Description",
          "format_option": "HOT_Catalog Card: Creative",
          "multi_share_end_card": false,
          "multi_share_optimized": "HOT_Optimize Card Order"
        }
      },
      "applink_treatment": "HOT_Deep Link Destination",
      "template_url_spec": {
        "android": {
          "url": "HOT_Android Url"
        },
        "ios": {
          "url": "HOT_iOS Url"
        },
        "web": {
          "url": "HOT_Deep Link"
        },
        "windows_phone": {
          "url": "HOT_Windows Phone Url"
        }
      },
      "url_tags": "HOT_Url Tags"
    }
  },
  collection: {
    image: {
      "creative": {
        "object_story_spec": {
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId",
          "link_data": {
            "link": "$$Canvas_Link$$",
            "message": "HOT_Primary Text",
            "name": "HOT_Headline",
            "retailer_item_ids": [
              "0",
              "0",
              "0",
              "0"
            ]
          }
        },
        "applink_treatment": "HOT_Deep Link Destination",
        "template_url_spec": {
          "android": {
            "url": "HOT_Android Url"
          },
          "ios": {
            "url": "HOT_iOS Url"
          },
          "web": {
            "url": "HOT_Deep Link"
          },
          "windows_phone": {
            "url": "HOT_Windows Phone Url"
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    },
    video: {
      "creative": {
        "object_story_spec": {
          "object_type": "VIDEO",
          "instagram_actor_id": "HOT_InstagramId",
          "page_id": "HOT_Page",
          "video_data": {
            "call_to_action": {
              "type": "NO_BUTTON",
              "value": {
                "link": "$$Canvas_Link$$"
              }
            },
            "retailer_item_ids": [
              "0",
              "0",
              "0",
              "0"
            ],
            "message": "HOT_Primary Text",
            "title": "HOT_Headline",
            "video_id": "HOT_SearchEngineVideoId",
            "image_url": "HOT_Image/Video"
          }
        },
        "applink_treatment": "HOT_Deep Link Destination",
        "template_url_spec": {
          "android": {
            "url": "HOT_Android Url"
          },
          "ios": {
            "url": "HOT_iOS Url"
          },
          "web": {
            "url": "HOT_Deep Link"
          },
          "windows_phone": {
            "url": "HOT_Windows Phone Url"
          }
        },
        "url_tags": "HOT_Url Tags"
      }
    },
    "instantExperience": {
      "isVideo": false,
      "pageId": "HOT_Page",
      "photo": {
        "url": "HOT_Image/Video",
        "published": false
      },
      "canvasPhoto": {
        "canvas_photo": {
          "photo_id": ""
        }
      },
      "canvasVideo": {
        "canvas_video": {
          "video_id": "HOT_SearchEngineVideoId"
        }
      },
      "canvasProductSet": {
        "canvas_product_set": {
          "product_set_id": "HOT_Product Set"
        }
      },
      "canvasButton": {
        "canvas_button": {
          "rich_text": {
            "plain_text": "HOT_Button Label"
          },
          "open_url_action": {
            "url": "HOT_Button Destination"
          }
        }
      },
      "canvasFooter": {
        "canvas_footer": {
          "child_elements": []
        }
      },
      "canvas": {
        "name": "DX-CL-Sell Products",
        "body_element_ids": [],
        'is_published': true
      }
    }
  }
}
let OptimizationGoal = [7, 8, 10, 13, 14]

let productCatalogSales = new Objective(ColumnConfig, CreativeSpec, null, OptimizationGoal)
export { productCatalogSales }
