<template>
<Card dis-hover class="container-card">
   <Row>
      <Row-Col :span="selectColSize">
      <Card
       dis-hover
       class="list-container"
       :class="{
        '': !isCampaignLauncher,
        'list-container-height-auto': isCampaignLauncher
       }">
         <Row slot="title" class="search-block">
         <Row-Col span = 10>
            <i-input placeholder="Search" clearable v-model="searchQuery">
            <span slot="append">
               <Dropdown trigger="click" placement="bottom-start">
                 <Badge :count="selectedFilterCount">
                    <Button class="btn btn-white" :disabled="isLoading"><i class="fa-filter"></i></Button>
                 </Badge>
                  <DropdownMenu slot="list">
                      <slot name="filter-block"></slot>
                  </DropdownMenu>
               </Dropdown>
            </span>
            </i-input>
         </Row-Col>
         <Row-Col v-if="!isCampaignLauncher" span = 5 class="check-all-box">
            <Checkbox v-model="isAllSelected" :disabled="isReadOnly || isSelectAllDisabled" @on-change="selectAll">Select All</Checkbox>
         </Row-Col>
         <Row-Col v-else span = 14 class="check-all-box">
            <Checkbox v-model="isAllSelected" :disabled="isReadOnly || isSelectAllDisabled" @on-change="selectAll">Select All</Checkbox>
         </Row-Col>
         <Row-Col v-if="!isCampaignLauncher" span = 9>
            <p v-if="isSelectOnly" class="selected-stores-heading selected-headline list-number-container">{{entitiesSelected.length}} of {{displayedEntites}} Selected</p>
            <p v-else class="selected-stores-heading selected-headline list-number-container">Showing {{displayedEntites}} of {{totalEntityList}} stores</p>
         </Row-Col>
         </Row>
         <Row>
            <VuePerfectScrollbar
              class="perfect-scrollbar-style"
              :class="{
                '': !isCampaignLauncher,
                'perfect-scrollbar-style-cl': isCampaignLauncher
              }"
            >
            <div v-if="isLoading" class="spinner">
              <i class="fa fa-lg fa-spinner fa-spin loader"/>
            </div>
            <ul
              :class="{
                'overflow-x-hidden': !isCampaignLauncher,
                '': isCampaignLauncher
              }"
              class="parent-container entity-select-list"
              v-else
            >
              <div class="alert alert-info info-result" role="alert" v-if="noResults">
                                <span class="fa-info-circle modal-alert"></span>
                                No stores found
              </div>
            <li class="list-item-group" v-else v-for="entity in filterParent(filteredEntities)" :key="entity.id">
               <Checkbox class="full-width hover-class" :disabled="isReadOnly || isParentDisabled(filterChild(entity.children, entity))" @on-change="parentCheckboxChange(entity)" v-model="entity.isSelected"><slot name="select-list-group" :entity="entity"></slot></Checkbox>
               <ul class="parent-container">
                 <li class="list-group-item-child" v-for="child in filterChild(entity.children, entity)" :key="child.id">
                    <Checkbox class="full-width" :disabled="child.isDisabled || child.isUnDeselectable || isReadOnly" v-model="child.isSelected" @on-change="childCheckboxChange(child)"><slot name="select-list-group-child" :childEntity="child" :parentEntity="entity"></slot></Checkbox>
                 </li>
               </ul>
            </li>
            </ul>
            </VuePerfectScrollbar>
            <Row v-if="isCampaignLauncher" class="footer-campaign-launcher">
              <Row-Col span=20>
                <p class="selected-stores-heading-cl">Showing {{displayedEntites}} of {{totalEntityList}} stores</p>
                <p class="selected-stores-cl">Selected Stores: {{ selectedEntityList.length }}</p>
              </Row-Col>
              <Row-Col span=4>
                <button
                  class="btn btn-success btn-sm mb-10"
                  @click="$emit('dx-mulitiselect-select-clicked')"
                >Select</button>
              </Row-Col>
            </Row>
         </Row>
      </Card>
      </Row-Col>
      <Row-Col span = 10 v-if="!isSelectOnly && !isCampaignLauncher">
      <Card dis-hover class="list-container selected-entites-container">
      <div slot="title" class="selected-header">
        <p class="selected-stores-heading selected-headline">Selected Stores: {{ entitiesSelected.length }}</p>
        <a :class="entitiesSelected.length > 0 ? `remove-all-style selected-enabled` : `remove-all-style selected-disabled`" @click="deselectAll" v-show="!isReadOnly">Remove All</a>
        </div>
      <VuePerfectScrollbar class="perfect-scrollbar-style">
      <ul class="list-group overflow-x-hidden parent-container selected-list-container">
        <li class="list-group-item selected-list-group-item" v-for="entity in entitiesSelected"  :key=entity.id>
          <Row type="flex" justify="center" align="middle">
            <Row-Col span="22">
              <slot name="selected-list-group" :entity="entity"></slot>
            </Row-Col>
            <Row-Col span="2">
              <span class="pull-right close-button">
                <i class="fa fa-times" v-if="!entity.isUnDeselectable" v-on:click="removeEntity(entity)"></i>
              </span>
            </Row-Col>
          </Row>
        </li>
      </ul>
      </VuePerfectScrollbar>
         </Card>
      </Row-Col>
   </Row>
</Card>
</template>
<script>
import {
  Divider,
  Row,
  Col,
  Checkbox,
  Input,
  Card,
  Dropdown,
  DropdownMenu,
  Button,
  Badge
} from "iview";
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
export default {
  components: {
    Row,
    "Row-Col": Col,
    Checkbox,
    'i-input': Input,
    Card,
    VuePerfectScrollbar,
    Dropdown,
    DropdownMenu,
    Button,
    Badge
  },
  props: [
    "entityList",
    "filteredEntityIds",
    "selectedEntityList",
    "selectedFilterCount",
    "isLoading",
    "isReadOnly",
    "isSelectOnly",
    "isCampaignLauncher"
  ],
  created () {
    this.setFilteredEntities();
    this.setLiveAndPreselectedEntities();
  },
  data: function () {
    return {
      searchQuery: '',
      entitiesSelected: [],
      visible: false,
      entityListProp: this.entityList,
      isAllSelected: false,
      isSearchEnabled: false,
      filterModal: false,
      filteredEntities: [],
      isSelectAllDisabled: false,
      noResults: false
    };
  },
  methods: {
    isParentDisabled (children) {
      let isAnyChildEnabled = children.find(x => !x.isDisabled && !x.isUnDeselectable);
      return !isAnyChildEnabled;
    },
    setFilteredEntities () {
      let isAllEntitiesSelected = true;
      let hasDisabledEntitiesOnly = true;
      let entityIds = [];
      this.entityList.forEach((entity) => {
        entityIds.push(...entity.children.map(e => e.id));
      });
      this.entityList.forEach((entity) => {
        let isAllSelected = true;
        let hasFilteredDisabledchild = false;
        let disabledChildIds = entity.children.filter(e => e.isDisabled).map(e => e.id);
        if (this.filteredEntityIds.some(r => disabledChildIds.includes(r))) {
          hasFilteredDisabledchild = true;
        }
        let enabledChilds = entity.children.filter(e => !e.isDisabled).map(e => e.id);
        if (this.selectedFilterCount > 0) {
          if (this.noResults || entity.children.find(child => !child.isSelected && (this.filteredEntityIds.includes(child.id) && !child.isDisabled) && (!this.isSearchEnabled || child.isSearched))) {
            isAllSelected = false;
          }
          if (this.filteredEntityIds.some(r => enabledChilds.includes(r))) {
            hasDisabledEntitiesOnly = false;
          }
        } else {
          if (this.noResults || entity.children.find(child => !child.isSelected && (this.filteredEntityIds.includes(child.id) || this.filteredEntityIds.length == 0) && (!this.isSearchEnabled || child.isSearched))) {
            isAllSelected = false;
          }
          if (entityIds.some(r => enabledChilds.includes(r))) {
            hasDisabledEntitiesOnly = false;
          }
        }
        if (isAllSelected) {
          hasFilteredDisabledchild ? entity.isSelected = false : entity.isSelected = true;
        } else {
          entity.isSelected = false;
          isAllEntitiesSelected = false;
        }
      });
      if (hasDisabledEntitiesOnly) {
        this.isSelectAllDisabled = true;
      } else {
        this.isSelectAllDisabled = false;
        this.isAllSelected = isAllEntitiesSelected;
      }
      this.filteredEntities = this.entityList;
    },
    deselectAll () {
      this.filteredEntities.forEach((entity) => {
        entity.isSelected = false;
        entity.children.forEach((child) => {
          if (!child.isUnDeselectable) {
            child.isSelected = false;
            this.entitiesSelected = this.entitiesSelected.filter(e => e != child);
          }
        })
      });
      this.entitiesSelected = [...new Set(this.entitiesSelected)];
      this.setFilteredEntities();
    },
    filterParent (filteredEntities) {
      return filteredEntities.filter((entity) => {
        let isChildFiltered = false;
        entity.children.forEach((child) => {
          // if no filters selected show all stores
          if (this.selectedFilterCount > 0) {
            if (this.filteredEntityIds.includes(child.id)) {
              isChildFiltered = true;
            }
          } else {
            if (this.filteredEntityIds.includes(child.id) || this.filteredEntityIds.length == 0) {
              isChildFiltered = true;
            }
          }
        })
        return (!this.isSearchEnabled || entity.isSearched || entity.isChildSearched) && isChildFiltered;
      });
    },
    filterChild (filteredEntities, entity) {
      return filteredEntities.filter(child => (this.filteredEntityIds.length == 0 || this.filteredEntityIds.includes(child.id)) && (!this.isSearchEnabled || (entity.isSearched || child.isSearched)));
    },
    selectAll () {
      this.filteredEntities.forEach((entity) => {
        if (this.isAllSelected) {
          if (!this.isSearchEnabled || entity.isSearched) {
            entity.isSelected = true;
          }
        } else {
          entity.isSelected = false;
        }
        entity.children.forEach((child) => {
          // if no filter selected select all available stores
          if (this.selectedFilterCount > 0) {
            if (this.filteredEntityIds.includes(child.id)) {
              if ((!child.isDisabled && !child.isUnDeselectable) && (!this.isSearchEnabled || entity.isSearched || child.isSearched)) {
                if (this.isAllSelected) {
                  child.isSelected = true;
                  this.entitiesSelected.push(child);
                } else {
                  child.isSelected = false;
                  this.entitiesSelected = this.entitiesSelected.filter(e => e != child);
                }
              }
            }
          } else {
            if ((this.filteredEntityIds.includes(child.id) || this.filteredEntityIds.length == 0) && (!child.isDisabled && !child.isUnDeselectable) && (!this.isSearchEnabled || entity.isSearched || child.isSearched)) {
              if (this.isAllSelected) {
                child.isSelected = true;
                this.entitiesSelected.push(child);
              } else {
                child.isSelected = false;
                this.entitiesSelected = this.entitiesSelected.filter(e => e != child);
              }
            }
          }
        })
      });
      this.entitiesSelected = [...new Set(this.entitiesSelected)];
      this.setFilteredEntities();
      this.$emit('update:selectedEntityList', this.entitiesSelected);
    },
    parentCheckboxChange (entity) {
      if (entity.isSelected) {
        entity.children.forEach((child) => {
          if ((!child.isSelected) && (this.filteredEntityIds.includes(child.id) || this.filteredEntityIds.length == 0) && (!child.isDisabled && !child.isUnDeselectable) && (!this.isSearchEnabled || child.isSearched)) {
            child.isSelected = true;
            this.entitiesSelected.push(child);
          }
        });
      } else {
        entity.children.forEach((child) => {
          if (!child.isDisabled && !child.isUnDeselectable) {
            child.isSelected = false;
            this.entitiesSelected = this.entitiesSelected.filter(e => e != child);
          }
        });
      }
      this.setFilteredEntities();
      this.$emit('update:selectedEntityList', this.entitiesSelected);
    },
    childCheckboxChange (entity) {
      if (entity.isSelected) {
        this.entitiesSelected.push(entity);
      } else {
        entity.isSelected = false;
        this.entitiesSelected = this.entitiesSelected.filter(e => e != entity);
      }
      this.setFilteredEntities();
      this.$emit('update:selectedEntityList', this.entitiesSelected);
    },
    removeEntity (entity) {
      this.filteredEntities.forEach((ent) => {
        ent.children.forEach((child) => {
          if (child.id == entity.id) {
            child.isSelected = false;
          }
        });
      });
      this.entitiesSelected = this.entitiesSelected.filter(e => e != entity);
      this.setFilteredEntities();
    },
    setLiveAndPreselectedEntities () {
      this.deselectAll();
      let entitiesSelected = [];
      var unDeselectableEntityIds = this.selectedEntityList.filter(entity => {
        return entity.isDeselectDisabled;
      }).map(s => s.id);
      var preSelectedEntityIds = this.selectedEntityList.map(s => s.id);
      if ((unDeselectableEntityIds.length && unDeselectableEntityIds.length > 0) || (preSelectedEntityIds && preSelectedEntityIds.length > 0)) {
        this.filteredEntities.forEach((entity) => {
          entity.children.forEach((child) => {
            if (unDeselectableEntityIds.length > 0 && unDeselectableEntityIds.includes(child.id)) {
              child.isSelected = true;
              child.isUnDeselectable = true;
              entitiesSelected.push(child);
            } else if (preSelectedEntityIds.length > 0 && preSelectedEntityIds.includes(child.id)) {
              child.isSelected = true;
              entitiesSelected.push(child);
            }
          })
        })
      }
      this.entitiesSelected = entitiesSelected;
      this.setFilteredEntities();
    }
  },
  computed: {
    displayedEntites () {
      let count = 0;
      this.entityList.forEach(entity => {
        entity.children.forEach(child => {
          if (this.selectedFilterCount > 0) {
            if (this.filteredEntityIds.includes(child.id) && (!this.searchQuery || (entity.isSearched || child.isSearched))) {
              count++;
            }
          } else {
            if ((this.filteredEntityIds.length == 0 || this.filteredEntityIds.includes(child.id)) && (!this.searchQuery || (entity.isSearched || child.isSearched))) {
              count++;
            }
          }
        });
      });
      return count;
    },
    totalEntityList () {
      let count = 0;
      this.entityList.forEach(entity => {
        entity.children.forEach(child => {
          count++;
        });
      });
      return count;
    },
    selectColSize () {
      return this.isSelectOnly || this.isCampaignLauncher ? 24 : 14;
    }
  },
  watch: {
    selectedEntityList () {
      this.setLiveAndPreselectedEntities();
    },
    entityList () {
      if (this.entityList.length == 0) {
        this.searchQuery = '';
        this.filteredEntities.forEach((entity) => {
          entity.isSelected = false;
          entity.children.forEach((child) => {
            child.isSelected = false;
            child.isUnDeselectable = false;
          })
        })
        this.entitiesSelected = [];
        this.$emit('update:selectedEntityList', this.entitiesSelected);
      } else {
        this.setFilteredEntities();
        this.setLiveAndPreselectedEntities();
      }
    },
    entitiesSelected: {
      handler (newVal, oldVal) {
        let isSame = false;
        // comparing old and new vlaues to avoid infinite loops due to 2-way sync of selected entities
        if (newVal.length == oldVal.length) {
          let newIdList = newVal.map(x => x.id);
          let oldIdList = oldVal.map(x => x.id);
          isSame = newIdList.every((x) => {
            if (!oldIdList.includes(x)) {
              return false;
            }
            return true;
          })
        }
        if (!isSame) {
          this.$emit('update:selectedEntityList', newVal);
        }
      },
      deep: true
    },
    searchQuery: {
      handler (val) {
        if (val) {
          this.isSearchEnabled = true;
          let isEmpty = true;
          this.filteredEntities.forEach((entity) => {
            if (entity.name.toLowerCase().includes(val.toLowerCase())) {
              entity.isSearched = true;
              isEmpty = false;
              entity.isChildSearched = true;
              entity.children.forEach(child => {
                child.isSearched = true;
              });
            } else {
              entity.isSearched = false;
              entity.isChildSearched = false;
              entity.children.forEach(child => {
                if (child.effectiveName.toLowerCase().includes(val.toLowerCase())) {
                  child.isSearched = true;
                  entity.isChildSearched = true;
                  isEmpty = false;
                } else {
                  child.isSearched = false;
                }
              });
            }
          })
          this.noResults = isEmpty;
        } else {
          this.isSearchEnabled = false;
          this.noResults = false;
        }
        this.setFilteredEntities();
      }
    },
    filteredEntityIds: function () {
      this.setFilteredEntities();
    }
  }
}
</script>
<style scoped>
.list-group-item-child{
   padding: 0px 0px 0px 20px !important;
   border:none;
}
.list-group-item-child:hover {
  background-color:#F4F4F4 !important;
}
::v-deep .ivu-select-dropdown{
  /* width: 175px !important; */
  margin-left: -7px;
  border-radius: 0px !important;
}
.selected-list-group-item{
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 2%;
  padding-bottom: 2%;
}
.list-group{
   margin: 0 !important;
}
::v-deep .ivu-input-icon {
  right: 19% !important;
  cursor: pointer !important;
  top: 7% !important;
}
.hover-class:hover {
  background-color:#F4F4F4 !important;
}
.search-block{
  height: 3rem !important;
}
.selected-header{
  position: relative !important;
  top: 17% !important;
}
.list-item-group {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
}
.selected-stores-heading {
  color: #979898 !important;
  font-size: 13px !important;
  margin-bottom: 5px !important;
}
.selected-stores-heading-cl {
  color: #979898 !important;
  font-size: 13px !important;
  margin: 0 !important;
}
.selected-stores-cl {
  color: #979898 !important;
  font-size: 13px !important;
  margin: 5px 0 0 0 !important;
}
.mb-10 {
  margin: 10px 0 !important;
}
.no-search-results {
  color: #979898 !important;
}
.info-result{
  margin: 8px !important;
}
.no-search-results {
  color: #979898 !important;
}
.check-all-box{
  padding-left: 2%;
  top: 28% !important;
}
.perfect-scrollbar-style{
  position: relative !important;
  height: 31.5rem !important;
  margin: auto !important;
}
.perfect-scrollbar-style-cl{
  height: 25rem !important;
}
.full-width{
  width: 100% !important;
}
.selected-headline{
  text-align: center;
}
.entity-select-list {
  margin-left: 2%;
  margin-right: 2%;
}
.border-underline{
  border-bottom: 1px solid;
  border-color: #CCCCCC;
}
.list-item-group:nth-child(even) {
  background-color: #E5E5E5;
}
.loader {
  margin: 0;
  position: absolute;
  top: 50%;
}
.spinner {
  text-align: center;
}
.parent-container {
  list-style-type: none !important;
}
.selected-list-container {
  margin-left: 15px !important;
  margin-right: 15px !important;
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}
</style>
<style>
.container-card > .ivu-card-body {
   padding: 0;
}
.container-card{
  border: 0px !important;
  border-radius: 0px !important;
}
.selected-enabled{
  cursor: pointer;
}
.selected-disabled{
  cursor: not-allowed;
}
.list-container > .ivu-card-body {
   padding: 1px !important;
   height: 32rem;
}
.list-container-height-auto > .ivu-card-body {
   height: auto;
}
.list-container > .ivu-card-head {
   height: 62px !important;
}
.list-container {
  border-radius: 0px !important;
}
.list-number-container {
  top: 6px !important;
  position: relative !important;
}
.remove-all-style {
  font-size: 11px;
  color: lightgrey;
  float: right !important;
}
.list-container > .ivu-card-head {
   height: 58.9773px !important;
}
.overflow-x-hidden {
  height: 28rem !important;
}
.modal-alert {
  color: #40bbea !important;
}
.footer-campaign-launcher {
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 14px;
  padding-bottom: 14px;
  border-top: 1px solid;
  border-color: #CCCCCC;
}
</style>
