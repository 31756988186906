<template>
  <div class="hot-div-publish" style="width: 100%; height: 530px; overflow: hidden;" :key="rerenderKey">
    <div v-if="htData.length < 1 && isPublishedDataFetchComplete" class="alert alert-info">
      No published ads were found.
    </div>
		<hot-table class="ad-sheet-hot" ref="hotPublish" :settings="hotSettings"></hot-table>
    <creative-setup
      ref="creativeSetup"
      :creativeSetupData="creativeSetupData"
      :mainTableData="mainTableData"
      :associatedMainTableRowNum="creativeSetupRowNum"
      :isPublished="creativeSetupRowNum < publishedCreativesSize || isLivePromotion"
      @saveSetupData="saveSetupData"
      :placementOptions="placementOptions"
      :placementCustomizationHeaders="placementCustomizationHeaders"
    >
    </creative-setup>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex"
import { HotTable } from "@handsontable/vue"
import { columnWidths } from "../../columnConfig.js"
import CreativeSetup from "../CreativeSetup.vue"
import Handsontable from 'handsontable'
import { APIService } from "../../ApiService.js"
import * as fbEnums from "../../Constants/FacebookEnums.js"
import FacebookCampaignLauncherConfig from "../../Mixins/FacebookCampaignLauncherConfig.js"

export default {
  components: {
    "hot-table": HotTable,
    "creative-setup": CreativeSetup
  },
  props: {
    tab: { default: '' },
    creativeSpec: { default: function () { return {} } },
    colHeaders: { default: function () { return [] } }, // ["HOT_Ad Name", "HOT_Call To Action"]
    displayColHeaders: { default: function () { return [] } }, // ["Ad Name", "Call To Action"]
    requiredCols: { default: function () { return {} } },
    callToAction: { default: function () { return [] } },
    placementOptions: { default: function () { return []; } },
    placementCustomizationHeaders: { default: function () { return []; } },
    promoModeAutoFill: { default: function () { return {} } },
    showCreativeSetup: { default: false },
    bpTimeZone: { default: '' },
    isPublishedDataFetchComplete: { default: false }
  },
  mixins: [FacebookCampaignLauncherConfig],
  data: function () {
    return {
      htData: [],
      hotSettings: {},
      startRows: 2,
      uniqueId: null,
      publishedRows: [],
      publishedCreativesSize: 0,
      previewable: {},
      hardcodedCardsCount: 10,
      rerenderKey: 0,
      availableWidth: 0,
      alreadyProcessed: {},
      creativeSetupData: {},
      creativeSetupRowNum: 0,
      existingPostAdsCount: 0,
      mainTableData: {},
      adsheetLvlAlert: {
        "HOT_Headline": null,
        "HOT_Primary Text": null,
        "HOT_Description": null,
        "HOT_Image/Video": null,
        "HOT_Call To Action": null
      },
      promoModeAutoFillCount: Object.keys(this.promoModeAutoFill).length,
      colNameIndexMap: this.colHeaders.reduce(
        (obj, colName, colIndex) => {
          obj[colName] = colIndex
          return obj
        },
        {}
      ),
      bulkUpdateQueries: {},
      channelMacros: {},
      macroRenderedCells: {},
      ldRenderedCells: {},
      dropdownColumns: new Set(),
      ColumnValidationInfo: {
        "HOT_Frame Opacity": "Opacity must range from 50 to 100",
        "HOT_Catalogue Information Shape Colour": "Please enter a valid 6 digit HEX colour value",
        "HOT_Catalogue Information Text Colour": "Please enter a valid 6 digit HEX colour value"
      },
      dpaCreativeEditRequiredCols: [],
      labelLDMap: {},
      previewableAdsCount: 0,
      previewableRow: {}
    }
  },
  computed: {
    ...mapState([
      "builtImageIdUrlMap",
      "campaignLauncherConfigs",
      "existingPostAds",
      "imageIdUrlMap",
      "mappedLineItems",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "selectedFbObjective",
      "isPromotionMode",
      "promotionId",
      "mediaPlanId",
      "mediaPlans",
      "promotionObject",
      "publisher",
      "accountMacros",
      "connectedInstagramAccountId",
      "tableData",
      "placementCustomizationSettings",
      "nomenclatureSettings",
      "lineItemLabels",
      "labelDimensions",
      "macroValueMapper",
      "publishedSheetCreatives",
      "allCreativesInfo",
      "adCreativeMapping",
      "instagramPosts"
    ]),
    ...mapGetters(["getInstantExperienceById", "getMediaPlanById", "isLivePromotion", "getFacebookNomenclature"])
  },
  created () {
    this.selectedMediaPlanDetails = this.getMediaPlanById(this.mediaPlanId)
    this.hotSettings = this.buildHotSettings()
  },
  mounted () {
    this.availableWidth = document.querySelector('.hot-div').offsetWidth
    if ((this.publishedSheetCreatives.adSheetSettings || {})["Frame"]) {
      this.dpaCreativeEditRequiredCols.push(fbEnums.dpaCreativeEditRequiredCols["Frame"])
    }
    if ((this.publishedSheetCreatives.adSheetSettings || {})["Catalogue Information"]) {
      this.dpaCreativeEditRequiredCols.push(fbEnums.dpaCreativeEditRequiredCols["Catalogue Information"])
    }
  },
  watch: {
    publishedSheetCreatives: {
      immediate: true,
      handler: function () {
        this.startRows = this.selectedCampaignLauncherConfig.adset.isDynamicCreative ? 1 : 2
        if (this.isPromotionMode) {
          this.publishedCreativesSize = 0;
        } else {
          this.publishedCreativesSize = 0;
          if (this.publishedSheetCreatives[this.tab] && this.publishedSheetCreatives[this.tab].length) {
            this.publishedCreativesSize = this.publishedSheetCreatives[this.tab].length
          }
        }
        this.previewable = {}
        this.publishedRows = []
        let newHtData = [];
        this.previewableAdsCount = 0;
        this.previewableRow = {};
        if (this.publishedSheetCreatives[this.tab] && this.publishedSheetCreatives[this.tab].length) {
          let publishedRowObjects = this.publishedSheetCreatives[this.tab]
          publishedRowObjects.forEach((rowObject, rowIndex) => {
            if (!rowObject.HOT_IsNotPublished) {
              let htRow = []
              this.setStatusAndError(rowObject, `DXC-${this.tab}-${rowIndex + 1}`)
              this.colHeaders.forEach(header => {
                if (header in rowObject) {
                  htRow.push(rowObject[header])
                } else {
                  htRow.push(null)
                }
              })
              newHtData.push(htRow)
              if ("creativeSetupData" in rowObject) {
                const csData = rowObject["creativeSetupData"]
                this.creativeSetupData[this.publishedCreativesSize - rowIndex - 1] = {
                  type: csData.type,
                  enabled: true,
                  hotTableData: csData.hotTableData
                }
              }
              this.previewableRow[rowIndex] = false
              htRow.forEach((cellData, colIndex) => {
                if (!this.isPromotionMode && cellData && this.colHeaders[colIndex].startsWith("HOT_Preview") && cellData === 'true') {
                  this.previewableRow[rowIndex] = true
                  this.previewableAdsCount += 1
                }
                this.updatePreviewColumn(rowIndex, colIndex, null, cellData, null);
              });
            }
          })
          this.publishedRows = JSON.parse(JSON.stringify(newHtData))
        }
        this.$store.state.adsCountPublishTab[this.tab] = this.previewableAdsCount;
        this.htData = newHtData.reverse()
        this.$nextTick(() => {
          let updatedSettings = {
            data: this.htData
          }

          // If handsontable width is less than available width, stretch all cols equally except 1st col(preview ad)
          let totalColWidth = [...Array(this.colHeaders.length).keys()]
            .map(index => this.getColWidthByIndex(index))
            .reduce((accu, curVal) => accu + Number(curVal), 0)
          if (totalColWidth < this.availableWidth) {
            let equalSize = (this.availableWidth - 100) / (this.colHeaders.length - 1)
            updatedSettings['colWidths'] = [100, ...Array(this.colHeaders.length - 1).fill(equalSize)]
          }
          if (this.$refs['hotPublish']) {
            this.$refs['hotPublish'].hotInstance.updateSettings(updatedSettings)
          }
        })
      }
    },
    builtImageIdUrlMap () {
      if (this.builtImageIdUrlMap) {
        this.rerender()
      }
    }
  },
  methods: {
    ...mapMutations(["set_tableData"]),
    statusRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (!(td.firstElementChild)) {
        td.innerHTML = "";
        let previewTdWrapper = document.createElement('div')
        previewTdWrapper.classList.add('preview-td-wrapper')

        let previewIcon = document.createElement('span')
        previewIcon.classList.add('status-span')
        if (value == 'Error') {
          previewIcon.classList.add('error')
          previewIcon.innerHTML = `<i class="ivu-icon ivu-icon-ios-alert-outline"/>`
        } else if (value == 'Partial') {
          previewIcon.classList.add('partial')
          previewIcon.innerHTML = `<i class="ivu-icon ivu-icon-ios-checkmark-circle-outline"/>`
        } else if (value == 'New') {
          previewIcon.classList.add('new')
          previewIcon.innerHTML = `<i class="ivu-icon ivu-icon-ios-time-outline"/>`
        } else {
          previewIcon.classList.add('published')
          previewIcon.innerHTML = `<i class="ivu-icon ivu-icon-ios-checkmark-circle-outline"/>`
        }
        previewTdWrapper.appendChild(previewIcon)
        td.appendChild(previewTdWrapper)
      }
      return td
    },
    rerender () {
      this.$nextTick(() => {
        this.$refs['hotPublish'].hotInstance.render()
      })
    },
    imageColRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (row < this.publishedCreativesSize) {
        td.classList.add('disabled-cell')
      }
      if (!td.querySelector('img')) {
        value = Handsontable.helper.stringify(value)

        if (value in this.imageIdUrlMap) {
          let img = td.querySelector("img")
          if (img && img.src == this.imageIdUrlMap[value]) {
            return td
          }
          img = document.createElement('IMG')
          if (value.split('-')[0] === 'DXC') {
            img.src = this.imageIdUrlMap[value]['src']
          } else {
            img.src = this.imageIdUrlMap[value]['src']
          }
          img.classList.add('hot-img')
          Handsontable.dom.addEvent(img, 'mousedown', function (event) {
            event.preventDefault()
          })

          Handsontable.dom.empty(td)
          td.appendChild(img)
        } else if (value.includes("http") || value.includes("www")) {
          let img = td.querySelector("img")
          if (img && img.src == value) {
            return td
          }
          img = document.createElement('IMG')
          img.src = value;
          img.classList.add('hot-img')
          Handsontable.dom.addEvent(img, 'mousedown', function (event) {
            event.preventDefault()
          })
          Handsontable.dom.empty(td)
          td.appendChild(img)
        }
        return td
      }
    },
    launchCreativeSetup (rowNum) {
      this.creativeSetupRowNum = rowNum;
      const type = this.selectedCampaignLauncherConfig.adset.isDynamicCreative
        ? "Dynamic Creative"
        : "Placement Customization";
      if (!(rowNum in this.creativeSetupData)) {
        this.creativeSetupData[rowNum] = {
          type,
          enabled: false,
          hotTableData: null
        };
      }
      this.mainTableData = this.getMainTableData(rowNum);
      this.$refs.creativeSetup.openModal();
    },
    addCreativeSetupIcon (previewTdWrapper, rowNum) {
      const beaker = document.createElement('span');
      beaker.className = "beaker-span";
      beaker.innerHTML = '<i class="fas fa-flask preview-icon"></i>'
      beaker.onclick = () => { this.launchCreativeSetup(rowNum) };
      previewTdWrapper.appendChild(beaker);
    },
    previewBtnRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (!(td.firstElementChild)) {
        td.innerHTML = "";
        let previewTdWrapper = document.createElement('div')
        previewTdWrapper.classList.add('preview-td-wrapper')

        let previewIcon = document.createElement('span')
        previewIcon.classList.add('eye', 'preivew-icon-span')
        previewIcon.innerHTML = '<i class="fas fa-eye preview-icon"></i>'
        previewTdWrapper.appendChild(previewIcon)
        td.appendChild(previewTdWrapper)
      }
      if (!((this.isDPAObjective(this.selectedCampaignLauncherConfig) && this.tab == "collection") || this.isSalesCollectionCatalogAd())) {
        let previewIcon = td.firstElementChild.querySelector(".preivew-icon-span");
        previewIcon.onclick = async () => {
          let rowInfo = instance.getDataAtRow(row)
          const accountId = this.selectedCampaignLauncherConfig.facebookAccountId;
          let creativeSpec = this.buildCreativeSpec(rowInfo, row)
          if (this.isPromotionMode) {
            try {
              let replaceMacrosResponse = await APIService.replacePromotionMacros(
                this.promotionId,
                null,
                JSON.stringify(creativeSpec)
              )
              creativeSpec = JSON.parse(replaceMacrosResponse.data.data.creativeJson)
              this.$emit("onPreview", {
                newSpec: creativeSpec,
                creativeIndex: 0,
                accountId,
                allPreviewableRowsSpec: []
              });
            } catch (err) {
              console.error(err)
            }
          } else {
            let specForAllPreviewableRows = this.getPreviewableRowsForAdPreview();
            this.$emit("onPreview", {
              newSpec: creativeSpec,
              creativeIndex: row + 1,
              accountId,
              allPreviewableRowsSpec: specForAllPreviewableRows
            });
          }
        }
      }
      if (this.showCreativeSetup) {
        let beakerElm = td.firstElementChild.querySelector(".beaker-span");
        if (beakerElm != null) {
          beakerElm.onclick = () => { this.launchCreativeSetup(row) };
        } else if (!this.isPromotionMode || deltax.isHubPlacementCustomizationEnabled) {
          this.addCreativeSetupIcon(td.firstElementChild, row)
        }
        if (
          row in this.creativeSetupData &&
          this.creativeSetupData[row].enabled
        ) {
          td.firstElementChild.classList.add("green-beaker");
        } else {
          td.firstElementChild.classList.remove("green-beaker");
        }
      }
      td.firstElementChild.classList.remove('disabled-cell')
      if (value !== "true") {
        td.style.cursor = 'not-allowed';
        td.firstElementChild.classList.add('preview-icon-disabled')
      } else {
        td.style.cursor = 'default';
        if (row < this.publishedCreativesSize) {
          td.firstElementChild.classList.add('disabled-cell')
        }
        td.firstElementChild.classList.remove('preview-icon-disabled')
      }
      td.classList.add('trim')
      return td
    },
    checkIfReq (col) {
      if (this.colHeaders[col] === undefined) { return [false, -1] }
      let colName = this.colHeaders[col]
      if (col < this.requiredCols['baseLength']) {
        return (this.requiredCols['base'].has(colName)) ? [true, -1] : [false, -1]
      }
      let space = colName.lastIndexOf(' ')
      if (this.requiredCols['card'].has(colName.substring(0, space))) {
        let cardIndex = Math.floor((col - this.requiredCols['baseLength']) / this.requiredCols['cardLength'])
        return [true, cardIndex]
      }
      return [false, -1]
    },
    checkIfPreviewable (previewRow) {
      let validCard =
        previewRow["card"].filter((x) => x >= this.requiredCols["card"].size)
          .length > 1;
      let validBase = previewRow["base"] >= this.requiredCols["base"].size;
      let validDC = previewRow["invalidDynamicCreativeCols"].size == 0
      return (validCard && validBase && validDC);
    },
    createNewPreviewableObj () {
      return {
        'validCard': false,
        'validBase': false,
        'card': new Array(this.hardcodedCardsCount).fill(0),
        'base': 0,
        'invalidDynamicCreativeCols': new Set()
      }
    },
    getColWidthByIndex (index) {
      var colWidthByPublisher = columnWidths(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config);
      for (let [width, headerNames] of Object.entries(colWidthByPublisher)) {
        if (width === "default") { continue }
        for (let headerName of headerNames) {
          if (this.colHeaders[index].indexOf(headerName) != -1) {
            return width
          }
        }
      }
      return colWidthByPublisher["default"]
    },
    processImageUrl (row, col, oldVal, newVal, instance) {
      let img = new Image();
      img.onload = () => {
        this.alreadyProcessed[`${row}-${col}`] = true;
        instance.setDataAtCell(row, col, newVal);
      };
      img.onerror = () => {
        this.$Message.error({
          background: true,
          content: 'Please enter a valid image URL'
        })
      };
      img.src = newVal;
    },
    getMainTableData (rowNum) {
      const instance = this.$refs['hotPublish'].hotInstance;
      const rowData = instance.getDataAtRow(rowNum);
      let dataObj = {}
      for (let i = 0; i < rowData.length; i++) {
        dataObj[this.colHeaders[i]] = rowData[i]
      }
      return dataObj;
    },
    validateWithCreativeSetup (rowNum, colNum, cellData, instance) {
      setTimeout(() => {
        if (
          colNum == 0 ||
          !(rowNum in this.creativeSetupData) ||
          !this.creativeSetupData[rowNum].enabled ||
          this.creativeSetupData[rowNum].type !== "Dynamic Creative"
        ) { return; }

        const updatePreviewableObj = (assetKey, assetName) => {
          const arr = dcData[assetKey].flat()
          if (arr.includes(cellData)) {
            this.adsheetLvlAlert[assetKey] = `'${cellData}' has already been used as a dynamic input. Please enter a unique ${assetName}`
            this.previewable[rowNum].invalidDynamicCreativeCols.add(colNum)
          } else {
            this.adsheetLvlAlert[assetKey] = null
            this.previewable[rowNum].invalidDynamicCreativeCols.delete(colNum)
          }
        }

        let alertMessages = []
        const dcData = this.creativeSetupData[rowNum].hotTableData;
        const colHeader = this.colHeaders[colNum];

        switch (colHeader) {
          case "HOT_Ad Title":
          case "HOT_Headline":
            updatePreviewableObj("HOT_Headline", "Headline");
            break;
          case "HOT_Text":
          case "HOT_Primary Text":
            updatePreviewableObj("HOT_Primary Text", "Primary Text");
            break;
          case "HOT_Description":
            updatePreviewableObj("HOT_Description", "Description");
            break;
          case "HOT_Image/Video":
            updatePreviewableObj("HOT_Image/Video", "Image/Video");

            const mediaData = [...dcData["HOT_Image/Video"].flat(), cellData]
            const imagesNum = mediaData.filter(dxId => dxId.startsWith("DXC")).length;

            if (imagesNum > 0 && imagesNum < mediaData.length) {
              alertMessages.push("Please use either images or videos only");
            }
            break;
          case "HOT_Call To Action":
            updatePreviewableObj("HOT_Call To Action", "Call To Action");
            break;
        }

        alertMessages.push(
          ...Object.values(this.adsheetLvlAlert).filter(str => str !== null)
        );
        this.$emit("onSetAdSheetAlert", {
          show: alertMessages.length > 0,
          tab: this.tab,
          message: alertMessages.join('<br>')
        });
        if (instance) {
          const previewEnabled = instance.getDataAtCell(rowNum, 0);
          const isPreviewable = this.checkIfPreviewable(this.previewable[rowNum]);
          if (isPreviewable && !previewEnabled) {
            instance.setDataAtCell(rowNum, 0, 'true')
          } else if (!isPreviewable && previewEnabled) {
            instance.setDataAtCell(rowNum, 0, 'false')
          }
        }
      }, 0)
    },
    validateWithPlacementCustomization (rowData) {
      /*

        uniqueHeader = [12,13,19]  // index for pc columns
        and if rowdata[uniqueHeaders] has any value within it return true as it should be considered
        for placement customization.

      */
      var uniqueColHeader = this.getUniqueColHeadersForPC()
      for (let header of uniqueColHeader) {
        if (rowData[header]) {
          return true
        }
      }
      return false
    },
    getUniqueColHeadersForPC () {
      /*
        Play with the code here:
        https://repl.it/@shivamkmr/LogicForValidatingPlacementCustomization#index.js
      */
      var placementCategories = ["HOT_Facebook", "HOT_Instagram", "HOT_Messenger", "HOT_Audience network"]
      var colHeaderPositionForPlacementCustomization = []
      placementCategories.forEach(placementPosition => {
        var position = this.colHeaders.map((header, iterator) => header.includes(placementPosition) ? iterator : '').filter(String)
        colHeaderPositionForPlacementCustomization.push(position)
      })
      var uniqueColHeader = [...new Set(colHeaderPositionForPlacementCustomization.flat(1))]
      return uniqueColHeader
    },

    setHotCellData (row, col, value, instance, lazyUpdate) {
      if (lazyUpdate) {
        this.bulkUpdateQueries[[row, col]] = [row, col, value]
      } else {
        setTimeout(() => { instance.setDataAtCell(row, col, value) })
      }
    },
    updatePreviewColumn (row, col, oldVal, newVal, instance, lazyUpdate = false) {
      if (!(row in this.previewable)) {
        this.previewable[row] = this.createNewPreviewableObj()
      }
      this.validateWithCreativeSetup(row, col, newVal, instance);
      let [required, cardIndex] = this.checkIfReq(col)
      if (required) {
        if (!oldVal && newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] += 1
          } else {
            this.previewable[row]['base'] += 1
          }
        } else if (oldVal && !newVal) {
          if (cardIndex != -1) {
            this.previewable[row]['card'][cardIndex] -= 1
          } else {
            this.previewable[row]['base'] -= 1
          }
        }
        if (instance) {
          if (!this.isPromotionMode && !this.previewableRow[row]) {
            this.previewableRow[row] = false
          }

          const isPreviewable = this.checkIfPreviewable(this.previewable[row]);
          if (isPreviewable && !(this.htData[row] && this.htData[row][0] === 'true')) {
            this.setHotCellData(row, 0, 'true', instance, lazyUpdate)
          } else if (!isPreviewable && !(this.htData[row] && this.htData[row][0] === 'false')) {
            this.setHotCellData(row, 0, 'false', instance, lazyUpdate)
          }

          if (!this.isPromotionMode && isPreviewable && this.previewableRow[row] !== isPreviewable) {
            this.previewableRow[row] = isPreviewable
            this.previewableAdsCount += 1
          } else if (!this.isPromotionMode && !isPreviewable && this.previewableRow[row] !== isPreviewable) {
            this.previewableRow[row] = isPreviewable
            this.previewableAdsCount -= 1
          }
          this.$store.state.adsCountPublishTab[this.tab] = this.previewableAdsCount;
        }
      }
    },
    getGroupedValues (colHeaders, rowData) {
      var columnIds = this.getUniqueColHeadersForPC();
      var titleDict = {
        "Text": "HOT_Text",
        "Image/Video": "HOT_Image/Video",
        "Video": "HOT_Video",
        "Headline": "HOT_Ad Title",
        "Link": "HOT_Link",
        "Primary Text": "HOT_Primary Text"
      }
      if (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig)) {
        titleDict.Headline = "HOT_Headline"
      }
      var mapCommonFields = {}
      columnIds.forEach(id => {
        var title = colHeaders[id].split(" - ")
        var optionalName = ""
        if (title[2]) {
          optionalName = " - " + title[2]
        }
        if (mapCommonFields[title[0]]) {
          mapCommonFields[title[0]].push({
            type: title[1],
            header: titleDict[title[1]] + optionalName,
            value: rowData[id]
          })
        } else {
          mapCommonFields[title[0]] = [
            {
              type: title[1],
              header: titleDict[title[1]] + optionalName,
              value: rowData[id]
            }
          ]
        }
      })
      return mapCommonFields
    },
    createStructureRequiredForPC (rowData, structure) {
      var groupedValues = this.getGroupedValues(this.colHeaders, rowData)
      for (var key in groupedValues) {
        var headerData = this.getPublisherPlacementObjectFromColumnHeader(key)
        var header = [headerData.publisher + " - " + headerData.placement]
        var fieldsData = []
        var sequence = []
        if (this.placementCustomizationHeaders) {
          sequence = JSON.parse(JSON.stringify(this.placementCustomizationHeaders))

          // remove the first header mainly HOT_Placements
          sequence.shift()
        }
        /*
        text is going under image, please make this by sequence.
        */
        sequence.forEach(el => {
          var fieldsObject = groupedValues[key].find(x => x.header == el)
          if (fieldsObject) {
            fieldsData.push(fieldsObject.value)
          } else {
            fieldsData.push(null)
          }
        })

        var noElementsPresent = fieldsData.every(e => e === undefined || e === null)
        if (!noElementsPresent) {
          var values = [...header, ...fieldsData]
          structure.hotTableData.push(values)
        }
      }
      structure.enabled = true
      return structure
    },
    getPublisherPlacementObjectFromColumnHeader (placement) {
      var placementCategories = ["HOT_Facebook", "HOT_Instagram", "HOT_Messenger", "HOT_Audience network"]
      for (var value of placementCategories) {
        if (placement.indexOf(value) != -1) {
          var placementValue = placement.replace(value, '').split(" - ")[0]
          return {
            publisher: value.split("_")[1],
            placement: placementValue.trim()
          }
        }
      }
    },
    labelDimensionRenderer (instance, td, row, col, prop, value, cellProperties) {
      let ldName = this.displayColHeaders[col];
      let defaultLabel = (this.lineItemLabels[ldName] || {}).name;
      if ([row, col] in this.ldRenderedCells) {
        this.htData[row][col] = value;
      } else if (defaultLabel) {
        arguments[5] = defaultLabel;
        this.htData[row][col] = defaultLabel;
        this.ldRenderedCells[[row, col]] = 0
        this.addToLabelLDMap(defaultLabel, ldName);
      }
      Handsontable.renderers.AutocompleteRenderer.apply(this, arguments);
    },
    channelMacroRenderer (instance, td, row, col, prop, value, cellProperties) {
      if ([row, col] in this.macroRenderedCells) {
        this.htData[row][col] = value;
        Handsontable.renderers.TextRenderer.apply(this, arguments)
      } else {
        let macroValue = this.colHeaders[col] == "HOT_Ad Name" ? this.channelMacros.Ad : this.channelMacros.UrlTags
        arguments[5] = macroValue
        this.htData[row][col] = macroValue;
        this.updatePreviewColumn(row, col, null, macroValue, instance);
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        this.macroRenderedCells[[row, col]] = 0
      }
    },
    promoMacroRenderer (instance, td, row, col, prop, value, cellProperties) {
      if (this.htData[row][this.colNameIndexMap["HOT_Call To Action"]] == "Call Now") {
        this.htData[row][col] = value;
        Handsontable.renderers.TextRenderer.apply(this, arguments)
        return
      }
      const newVal = this.promoModeAutoFill[this.colHeaders[col]].macro;
      if (value != newVal) {
        arguments[5] = newVal
        this.htData[row][col] = newVal;
        this.updatePreviewColumn(row, col, null, newVal, instance);
      }
      Handsontable.renderers.TextRenderer.apply(this, arguments);
      if (this.promoModeAutoFill[this.colHeaders[col]].disableColumn) {
        td.classList.add('disabled-cell-text')
      }
    },
    buildHotSettings () {
      var self = this;
      this.channelMacros = this.getFacebookNomenclature || {};
      let ldNameIdMap = Object.values(this.labelDimensions).reduce((obj, ld) => {
        obj[ld.name] = ld.id;
        return obj;
      }, {});

      return {
        data: this.htData,
        startCols: this.colHeaders.length,
        startRows: this.startRows,
        minSpareRows: 0,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        copyPaste: {
          rowsLimit: 5000
        },
        viewportColumnRenderingOffset: this.colHeaders.length,
        comments: true,
        colHeaders: function (index) {
          var header = self.colHeaders[index];
          var displayName = self.displayColHeaders[index];
          var displayText = displayName;
          if (self.requiredCols.base.has(header) || self.isDpaCreativeEditCol(header)) {
            displayText += "<span style='color:#b94a48'>*</span>";
          }
          var dateColumns = ["HOT_StartTime", "HOT_EndTime"];
          var startEndDateColumns = ["HOT_StartDate", "HOT_EndDate"];
          if (dateColumns.includes(header)) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='Format: HH:MM &#010;TimeZone: " + self.bpTimeZone + "'></i>"
          }
          if (startEndDateColumns.includes(header)) {
            displayText += "&nbsp<i class='fa fa-info-circle' title='TimeZone: " + self.bpTimeZone + "'></i>"
          }
          if (header == "HOT_Catalog Card: Creative" && self.dpaCreativeEditRequiredCols.length > 0) {
            displayText += `&nbsp<i class="fa fa-info-circle" title="DPA creative edit is not supported for 'carousel slideshows'"></i>`
          }
          return displayText;
        },
        rowHeights: 74,
        // stretchH: 'all',
        colWidths: this.getColWidthByIndex,
        // comments: true,
        afterRenderer: (td, row, col, prop, value, cellProperties) => {
          let instance = this.$refs['hotPublish'].hotInstance
          if (col == 0) {
            this.previewBtnRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.colHeaders[col] === "HOT_Image/Video" || this.colHeaders[col] === "HOT_Video" || this.colHeaders[col] === "HOT_Intro Card: Image" ||
            this.colHeaders[col] === "Image/Video" || this.colHeaders[col] === "- Video" || this.colHeaders[col].includes("Image/Video") || this.colHeaders[col] === "HOT_Video_Thumbnail" || this.colHeaders[col].includes("Video Thumbnail")) {
            this.imageColRenderer(instance, td, row, col, prop, value, cellProperties);
          }
          if (this.colHeaders[col] === "Status") {
            this.statusRenderer(instance, td, row, col, prop, value, cellProperties);
          }
        },
        beforeRenderer: (td, row, col, prop, value, cellProperties) => {},
        afterUpdateSettings: () => {
          let instance = this.$refs['hotPublish'].hotInstance;
          if (!this.isReachObjective(this.selectedCampaignLauncherConfig)) {
            return;
          }
          let requireRerender = false;
          for (let i = 0; i < this.htData.length; i++) {
            if (this.htData[i][this.colNameIndexMap["HOT_Call To Action"]] == "Call Now") {
              requireRerender = true;
              let meta = instance.getCellMeta(i, this.colNameIndexMap["HOT_Phone Number"]);
              meta.readOnly = false;
              meta.className = "";
            }
          }
          if (requireRerender) {
            this.rerender();
          }
        },
        afterChange: (changes, source) => {
          if (!changes) {
            return null;
          }
          let instance = this.$refs['hotPublish'].hotInstance
          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [row] = change;
            if (this.showCreativeSetup) {
              let rowData = instance.getDataAtRow(row)
              var structure = {
                "type": "Placement Customization",
                "enabled": false,
                "hotTableData": [
                  // [
                  //   "HOT_Placements",
                  //   "HOT_Image/Video",
                  //   "HOT_Text",
                  //   "HOT_Ad Title",
                  //   "HOT_Link"
                  // ]
                  // [
                  //   "Facebook - Feed",
                  //   "DXC-1-84",
                  //   "shivam",
                  //   null,
                  //   "www.deltax.com"
                  // ]
                ]
              }
              structure.hotTableData.push(self.placementCustomizationHeaders)
              if (this.validateWithPlacementCustomization(rowData)) {
                structure = this.createStructureRequiredForPC(rowData, structure);
                this.creativeSetupRowNum = row
                this.saveSetupData(structure)
              } else if (this.getUniqueColHeadersForPC().length) {
                this.creativeSetupRowNum = row
                this.saveSetupData(structure)
              }
            }
          }
        },
        columns: (index) => {
          if (index >= this.colHeaders.length) { return }
          let settings = {}
          if (
            this.colHeaders[index].indexOf("HOT_Image/Video") != -1 ||
            this.colHeaders[index].indexOf("HOT_Video") != -1 ||
            this.colHeaders[index].indexOf("HOT_Intro Card: Image") != -1 ||
            this.colHeaders[index].indexOf("Image/Video") != -1 ||
            this.colHeaders[index].indexOf("- Video") != -1 ||
            this.colHeaders[index].indexOf("HOT_Video_Thumbnail") != -1 ||
            this.colHeaders[index].includes("Video Thumbnail")
          ) {
            settings.renderer = this.imageColRenderer;
          } else if (index == 0 && this.colHeaders[index] == "HOT_Preview Ad") {
            settings.readOnly = true
            settings.copyable = false
            settings.editor = false
            settings.renderer = this.previewBtnRenderer
          } else if (this.colHeaders[index] == "HOT_Call To Action") {
            settings.type = 'dropdown'
            settings.source = this.callToAction
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Deep Link Destination") {
            settings.type = 'dropdown'
            settings.source = ["Deeplink with web fallback", "Deeplink with appstore fallback", "Web only"]
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Targets") {
            settings.validator = this.validateTargets
            settings.allowInvalid = true
          } else if (this.colHeaders[index] == "HOT_Locations") {
            settings.validator = this.validateLocations
            settings.allowInvalid = true
          } else if (this.colHeaders[index] == "HOT_Catalog Card: Creative") {
            settings.type = 'dropdown'
            settings.source = ["carousel images multi items", "carousel slideshows"]
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Catalog Options") {
            settings.type = 'dropdown'
            settings.source = ["Multiple Products"]
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_StartDate" || this.colHeaders[index] == "HOT_EndDate") {
            var self = this
            settings.type = 'date'
            settings.dateFormat = 'MM/DD/YYYY'
            settings.correctFormat = true
            settings.allowEmpty = true
            if (self.isPromotionMode) {
              settings.readonly = true;
            }
            settings.datePickerConfig = {
              position: 'absolute',
              disableDayFn: function (date) {
                if (self.isPromotionMode) {
                  var promoStartDate = new Date(self.promotionObject.settings.schedule.startDate) <= new Date(new Date().toDateString()) ? new Date(new Date().toDateString()) : new Date(self.promotionObject.settings.schedule.startDate)
                  return (date < promoStartDate || date > new Date(new Date(self.promotionObject.settings.schedule.endDate).toDateString()))
                }
                var presentStartDate = new Date(self.selectedMediaPlanDetails.StartDate) <= new Date(new Date().toDateString()) ? new Date(new Date().toDateString()) : new Date(self.selectedMediaPlanDetails.StartDate)
                return (date < presentStartDate || date > new Date(new Date(self.selectedMediaPlanDetails.EndDate).toDateString()))
              }
            }
            settings.validator = function (value, callBack) {
              if (value != "" && value != null && !isNaN(new Date(value).getTime())) {
                var startDate = new Date(this.instance.getDataAtCell(this.row, self.colHeaders.indexOf("Start Date"))).getTime()
                var endDate = new Date(this.instance.getDataAtCell(this.row, self.colHeaders.indexOf("End Date"))).getTime()
                if (self.colHeaders[index] == "Start Date" && endDate && new Date(value).getTime() > new Date(endDate).getTime()) {
                  callBack(false)
                } else if (self.colHeaders[index] == "End Date" && startDate && new Date(value).getTime() < new Date(startDate).getTime()) {
                  callBack(false)
                } else {
                  callBack(true)
                }
              } else if (value != "" && value != null && isNaN(new Date(value).getTime())) {
                callBack(false)
              } else {
                callBack(true)
              }
            }
          } else if (this.colHeaders[index] == "HOT_Phone Number") {
            settings.allowInvalid = true
            settings.readOnly = !this.isLeadGenerationObjective(this.selectedCampaignLauncherConfig)
            settings.className = !this.isLeadGenerationObjective(this.selectedCampaignLauncherConfig) ? 'disabled-cell-text' : ''
          } else if (
            ((this.tab == "collection" &&
            this.isDPAObjective(this.selectedCampaignLauncherConfig)) || this.isSalesCollectionCatalogAd()) &&
            (this.colHeaders[index] == "HOT_Instant Experience" || this.colHeaders[index] == "HOT_Product Set")
          ) {
            settings.readOnly = true;
            settings.className = "disabled-cell-text";
          } else if (this.colHeaders[index] == "HOT_StartTime" || this.colHeaders[index] == "HOT_EndTime") {
            if (this.isPromotionMode) {
              settings.readonly = true;
            }
            settings.type = 'time';
            settings.allowInvalid = false;
            settings.timeFormat = 'h:mm:ss a';
            settings.correctFormat = true;
            // settings.validator = function (value, callBack) {
            //   console.log("in time validator");
            //   callBack(true);
            // }
          } else if (this.colHeaders[index] == "HOT_AddMap") {
            settings.type = 'checkbox';
          } else if (this.colHeaders[index] == "HOT_Frame Blending") {
            settings.type = 'dropdown'
            settings.source = fbEnums.dpaCreativeSettings[this.colHeaders[index]]
            settings.allowInvalid = false
            this.dropdownColumns.add(this.colHeaders[index]);
          } else if (this.colHeaders[index] == "HOT_Frame Opacity") {
            settings.allowInvalid = false
            settings.validator = (value, callBack) => {
              if (value == "" || value == null) {
                callBack(true);
                return;
              }
              const numVal = Number(value);
              if (numVal == parseInt(value) && numVal >= 50 && numVal <= 100) {
                callBack(true);
              } else {
                callBack(false);
              }
            }
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Frame Image") {
            settings.renderer = this.imageColRenderer;
          } else if (this.colHeaders[index] == "HOT_Frame Size") {
            settings.type = 'dropdown'
            settings.source = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"]
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Frame Position") {
            settings.type = 'dropdown'
            settings.source = fbEnums.dpaCreativeSettings[this.colHeaders[index]]
            settings.allowInvalid = false
            this.dropdownColumns.add(this.colHeaders[index]);
          } else if (this.colHeaders[index] == "HOT_Catalogue Information Type") {
            settings.type = 'dropdown'
            settings.source = fbEnums.dpaCreativeSettings[this.colHeaders[index]]
            settings.allowInvalid = false
            this.dropdownColumns.add(this.colHeaders[index]);
          } else if (this.colHeaders[index] == "HOT_Catalogue Information Shape") {
            settings.type = 'dropdown'
            settings.source = fbEnums.dpaCreativeSettings[this.colHeaders[index]]
            settings.allowInvalid = false
            this.dropdownColumns.add(this.colHeaders[index]);
          } else if (this.colHeaders[index] == "HOT_Catalogue Information Font") {
            settings.type = 'dropdown'
            settings.source = fbEnums.dpaCreativeSettings[this.colHeaders[index]]
            settings.allowInvalid = false
            this.dropdownColumns.add(this.colHeaders[index]);
          } else if (this.colHeaders[index] == "HOT_Catalogue Information Shape Colour") {
            settings.validator = this.validateHexColor;
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Catalogue Information Text Colour") {
            settings.validator = this.validateHexColor;
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Catalogue Information Opacity") {
            settings.type = 'dropdown'
            settings.source = fbEnums.dpaCreativeSettings[this.colHeaders[index]]
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Catalogue Information Position") {
            settings.type = 'dropdown'
            settings.source = fbEnums.dpaCreativeSettings[this.colHeaders[index]]
            settings.allowInvalid = false
            this.dropdownColumns.add(this.colHeaders[index]);
          } else if (this.colHeaders[index] == "Status") {
            settings.readOnly = true
            settings.copyable = true
            settings.editor = false
            settings.renderer = this.statusRenderer
          } else if (this.colHeaders[index] == "HOT_Standard_Enhancements" || this.colHeaders[index] == "HOT_Image_Enhancements" || this.colHeaders[index] == "HOT_3d_Animation" || this.colHeaders[index] == "HOT_Music") {
            settings.type = 'dropdown'
            settings.source = ['Enable', 'Disable']
            settings.allowInvalid = false
          } else if (this.colHeaders[index] == "HOT_Optimize Card Order") {
            settings.type = 'dropdown'
            settings.source = ['Yes', 'No']
            settings.allowInvalid = false
          }
          if (this.isLivePromotion) {
            settings.readOnly = true;
            settings.className = 'post-cell';
          }
          return settings
        },
        cells: (row, col, prop) => {
          const showNomenclatureMacros = this.isNomenclatureEnabled() && !this.isPromotionMode
          const savedRowsCount = (this.publishedSheetCreatives[this.tab]) ? this.publishedSheetCreatives[this.tab].length : 0;
          let cellProperties = {}
          if (this.isLivePromotion && self.htData[row].some((ele) => ele != null)) {
            cellProperties.className = 'disabled-cell-text'
          }
          if (showNomenclatureMacros && this.colHeaders[col] == "HOT_Ad Name" && this.channelMacros.Ad) {
            cellProperties.renderer = this.macroColRenderer;
          } else if (showNomenclatureMacros && this.colHeaders[col] == "HOT_Url Tags" && this.channelMacros.UrlTags) {
            cellProperties.renderer = this.macroColRenderer
          }
          if (this.publishedSheetCreatives[this.tab] && row < this.publishedCreativesSize) {
            cellProperties.readOnly = 'true'
            if (col) {
              cellProperties.className = 'disabled-cell-text'
            }
          } else if (this.tab === 'existing posts' && this.colHeaders[col] == "HOT_Post ID") {
            cellProperties.readOnly = 'true'
            cellProperties.className = 'post-cell'
          } else if (this.isPromotionMode) {
            if (this.colHeaders[col] in this.promoModeAutoFill) {
              cellProperties.renderer = this.promoMacroRenderer;
              if (this.promoModeAutoFill[this.colHeaders[col]].disableColumn) {
                cellProperties.readOnly = 'true';
              }
            }
            if (this.colHeaders[col] == "HOT_Targets" || this.colHeaders[col] == "HOT_Locations") {
              cellProperties.readOnly = 'true';
              cellProperties.className = 'disabled-cell-text';
            }
          } else if (this.colHeaders[col].startsWith("HOT_LD_")) {
            if (row < savedRowsCount) {
              this.ldRenderedCells[[row, col]] = 0
            }
            const ldId = ldNameIdMap[this.displayColHeaders[col]];
            const labels = (this.labelDimensions[ldId] || {}).labels || [];
            cellProperties.type = 'autocomplete'
            cellProperties.filter = false
            cellProperties.strict = false
            cellProperties.source = labels
            cellProperties.renderer = this.labelDimensionRenderer;
          }
          return cellProperties
        },
        beforeCreateRow: (index, amount, source) => {
          if (source != "auto" || index < 100) { return true }
          if (this.isPromotionMode) {
            return (
              this.htData[index - 1].slice(1).filter(Boolean).length >
              this.promoModeAutoFillCount
            );
          } else {
            let macroPrefilledColCount =
              (this.channelMacros.Ad && "HOT_Ad Name" in this.colNameIndexMap ? 1 : 0) +
              (this.channelMacros.UrlTags && "HOT_Url Tags" in this.colNameIndexMap ? 1 : 0);
            return (
              this.htData[index - 1].slice(1).filter(Boolean).length >
              macroPrefilledColCount
            )
          }
        },
        beforePaste: (data, coords) => {
        },
        beforeColumnResize: (newSize, column, isDoubleClick) => {
          return isDoubleClick && newSize > window.innerWidth ? window.innerWidth : newSize
        },
        beforeAutofill: (st, ed, data) => {
        },
        beforeChange: (changes, source) => {
          let instance = this.$refs['hotPublish'].hotInstance
          let requireRerender = false;
          this.bulkUpdateQueries = {};
          for (let index = 0; index < changes.length; index++) {
            let change = changes[index];
            let [row, col, oldVal, newVal] = change;
            if (row < this.publishedCreativesSize || col >= this.colHeaders.length) {
              return;
            }
            if (
              this.isReachObjective(this.selectedCampaignLauncherConfig) &&
              this.colHeaders[col] == "HOT_Call To Action" && (newVal == "Call Now" ||
              oldVal == "Call Now")
            ) {
              const isCallNow = newVal == "Call Now"
              requireRerender = true;
              let meta = instance.getCellMeta(row, this.colNameIndexMap["HOT_Phone Number"]);
              meta.readOnly = !isCallNow;
              meta.className = !isCallNow ? "disabled-cell-text" : "";
            }
            if (newVal && this.colHeaders[col] == "HOT_Frame Image") {
              if (!newVal.startsWith("DXC") || !(newVal in this.imageIdUrlMap)) {
                this.$Message.error({ background: true, content: "Image not found." });
                changes[index] = null
                continue;
              }
            }
            if (
              newVal &&
              (this.colHeaders[col].indexOf("HOT_Image/Video") != -1 ||
              this.colHeaders[col].indexOf("HOT_Video") != -1 ||
              this.colHeaders[col].indexOf("Image/Video") != -1 ||
              this.colHeaders[col].indexOf("- Video") != -1
              )
            ) {
              if (newVal.includes("http") || newVal.includes("www")) {
                if (this.alreadyProcessed[`${row}-${col}`]) {
                  this.alreadyProcessed[`${row}-${col}`] = false;
                } else {
                  changes[index] = null;
                  this.processImageUrl(row, col, oldVal, newVal, instance);
                  continue
                }
              } else {
                let errorMessage = null
                if (!(newVal in this.imageIdUrlMap)) {
                  errorMessage = "Image/Video not found."
                } else if ((this.colHeaders[col] === "HOT_Video" || this.colHeaders[col].indexOf("- Video") != -1) && newVal.split('-')[0] === 'DXC') {
                  errorMessage = "Please select a Video."
                }
                if (errorMessage !== null) {
                  this.$Message.error({
                    background: true,
                    content: errorMessage
                  })
                  changes[index] = null
                  continue;
                }
              }
            }
            if (this.colHeaders[col].startsWith("HOT_LD_")) {
              if (this.labelLDMap[oldVal]) {
                this.labelLDMap[oldVal].count -= 1;
                if (this.labelLDMap[oldVal].count == 0) {
                  delete this.labelLDMap[oldVal];
                }
              }
              if (newVal) {
                this.addToLabelLDMap(newVal, this.displayColHeaders[col], false);
                if (this.labelLDMap[newVal].labelDimension != this.displayColHeaders[col]) {
                  this.$Message.error({
                    background: true,
                    content: `This label is already mapped to label dimension '${this.labelLDMap[newVal].labelDimension}'`
                  });
                  changes[index] = null;
                  continue;
                }
                this.labelLDMap[newVal].count += 1;
              }
            }

            this.updatePreviewColumn(row, col, oldVal, newVal, instance, true);
          }

          let bulkUpdatesArray = Object.values(this.bulkUpdateQueries);
          setTimeout(() => {
            instance.setDataAtRowProp(bulkUpdatesArray);
          })
          if (requireRerender) {
            this.rerender();
          }
        },
        afterGetColHeader: function (col, TH) {
          // das
        },
        afterValidate: (isValid, value, row, col, source) => {
          if (isValid) {
            return;
          }

          const commentsPlugin = this.$refs['hot'].hotInstance.getPlugin('comments');
          const showComment = (message) => {
            setTimeout(() => {
              commentsPlugin.updateCommentMeta(row, col, {
                value: message,
                readOnly: true
              });
              commentsPlugin.showAtCell(row, col);
              setTimeout(() => {
                commentsPlugin.removeCommentAtCell(row, col);
              }, 6000);
            })
          }

          if (this.ColumnValidationInfo[this.colHeaders[col]]) {
            showComment(this.ColumnValidationInfo[this.colHeaders[col]]);
          }

          return false;
        }
      }
    },
    /**
     * let obj = {
     *    link: "HOT_Link",
     *    call_to_action: {
     *        name: "HOT_Headline"
     *    }
     * }
     * let cardNum = 1
     *
     * 1st call:
     *    Object.keys(obj) == [link, call_to_action]
     *    foreach Object.keys(obj)
     *       1. obj["link"] == "HOT_Link" (not an object type)
     *             - set obj["link"] = HOT_Link 1
     *
     *       2. obj["call_to_action"] == { name: "HOT_Headline" } (it is an object)
     *             - call setCarouselCardNum(obj["call_to_action"], cardNum)
     *
     * 2nd call:
     *    obj = {
     *       name: "HOT_Headline"
     *    }
     *
     *    foreach Object.keys(obj)  // ["name"]
     *       1. obj["name"] == "HOT_Headline" (not an object type)
     *          - set obj["name"] = HOT_Headline 1
     */
    setCarouselCardNum (obj, cardNum) {
      Object.keys(obj).forEach(key => {
        if (obj[key] !== null && typeof obj[key] === "object") {
          this.setCarouselCardNum(obj[key], cardNum);
          return;
        }
        if (typeof obj[key] === "string" && obj[key].startsWith("HOT_")) {
          obj[key] = `${obj[key]} ${cardNum}`;
        }
      });
    },
    isDpaCollectionAd () {
      return this.tab == "collection" && this.isDPAObjective(this.selectedCampaignLauncherConfig)
    },
    isSalesCollectionCatalogAd () {
      return (this.selectedCampaignLauncherConfig.campaign.objective == fbEnums.objectives.Sales && !this.selectedCampaignLauncherConfig.campaign.productCatalog && this.tab == 'collectionCatalog')
    },
    isDpaCreativeEditCol (colName) {
      return this.dpaCreativeEditRequiredCols.some(col => `HOT_${col}` == colName);
    },
    recurse (obj, headerValMap) {
      Object.keys(obj).forEach((key) => {
        if (key === "call_to_action" && !headerValMap["HOT_Call To Action"]) {
          // When call_to_action is optional and is not selected by user, remove it from creative json (obj)
          delete obj["call_to_action"];
          return;
        }
        if (key === "template_url_spec" && !headerValMap["HOT_Deep Link"]) {
          // When deep link is optional, remove the web object if deep link is not set
          delete obj["template_url_spec"]["web"];
        }
        if (obj[key] !== null && typeof obj[key] === 'object') {
          this.recurse(obj[key], headerValMap)
          return
        }
        if (typeof obj[key] === 'string' && obj[key].startsWith("HOT_")) {
          if (obj[key].startsWith("HOT_Image/Video") || obj[key].startsWith("HOT_Intro Card: Image")) {
            if (headerValMap[obj[key]].startsWith("http://") || headerValMap[obj[key]].startsWith("https://")) {
              // use picture
              obj[key] = headerValMap[obj[key]]
            } else {
              // use image hash
              obj["image_hash"] = headerValMap[obj[key]]
              delete obj[key];
            }
          } else if (obj[key] && obj[key].startsWith("HOT_Optimize Card Order")) {
            if (headerValMap[obj[key]] === "No") {
              obj[key] = false;
            } else if (headerValMap[obj[key]] === "Yes") {
              obj[key] = true;
            } else {
              delete obj[key];
            }
          } else {
            obj[key] = headerValMap[obj[key]]
          }
        }
      })
    },
    updateBeakerColor (rowNum) {
      const td = this.$refs["hotPublish"].hotInstance.getCell(rowNum, 0);
      if (td) {
        if (this.creativeSetupData[rowNum].enabled) {
          td.firstElementChild.classList.add("green-beaker");
        } else {
          td.firstElementChild.classList.remove("green-beaker");
        }
      }
    },
    saveSetupData (newData) {
      const rowNum = this.creativeSetupRowNum
      this.creativeSetupData[rowNum] = newData;
      this.updateBeakerColor(rowNum)
      this.$emit("onSetAdSheetAlert", {
        show: false,
        tab: this.tab,
        message: ""
      });
    },
    getImageHashOrUrl (key) {
      return this.imageIdUrlMap[key]['hash'] && !(this.isDpaCollectionAd() || this.isSalesCollectionCatalogAd()) ? this.imageIdUrlMap[key]['hash'] : this.imageIdUrlMap[key]['src']
    },
    regularSpec (row) {
      let headerValMap = {}
      let creativeSpec = JSON.parse(JSON.stringify(this.creativeSpec));
      let spec = JSON.parse(JSON.stringify(this.creativeSpec))
      let labelDimensionMacroValues = {}
      for (let i = 0; i < this.colHeaders.length; i++) {
        if (this.colHeaders[i] === "HOT_Instant Experience" && row[i] && !(this.isDPAObjective(this.selectedCampaignLauncherConfig) || this.isSalesCollectionCatalogAd())) {
          let canvas = this.getInstantExperienceById(row[i]);
          let ieType = canvas && canvas.body_elements ? canvas.body_elements[0].element_type : "PHOTO"
          if (ieType === "VIDEO") {
            spec = spec["video"];
            if (canvas.collection_hero_video) {
              headerValMap["HOT_SearchEngineVideoId"] = canvas.collection_hero_video.id;
            }
          } else {
            spec = spec['image']
          }
          headerValMap["HOT_Link"] = `https://fb.com/canvas_doc/${row[i]}`
        }
        if ((this.colHeaders[i] === 'HOT_Image/Video' || this.colHeaders[i] === 'HOT_Video') && row[i]) {
          // eslint-disable-next-line no-unused-vars
          let isVideo = false;
          if (row[i].split('-')[0] === 'DXC') {
            spec = ('image' in spec) ? spec['image'] : spec;
            headerValMap[this.colHeaders[i]] = this.getImageHashOrUrl(row[i]);
          } else if (row[i].includes("http") || row[i].includes("www")) {
            spec = spec['image']
            headerValMap[this.colHeaders[i]] = row[i]
          } else {
            spec = spec['video']
            let videoThumbnailIndex = this.colHeaders.findIndex((header) => header == "HOT_Video_Thumbnail");
            if (videoThumbnailIndex > -1 && row[videoThumbnailIndex]) {
              if (row[videoThumbnailIndex].includes('DXC')) {
                headerValMap[this.colHeaders[i]] = this.imageIdUrlMap[row[videoThumbnailIndex]]['src'];
              } else {
                headerValMap[this.colHeaders[i]] = row[videoThumbnailIndex];
              }
            } else {
              headerValMap[this.colHeaders[i]] = this.imageIdUrlMap[row[i]]['src']
            }
            headerValMap['HOT_SearchEngineVideoId'] = this.imageIdUrlMap[row[i]]['SearchEngineVideoId']
            isVideo = true;
          }
          if ((this.tab == 'collection' && this.isDPAObjective(this.selectedCampaignLauncherConfig)) || this.isSalesCollectionCatalogAd()) {
            spec["instantExperience"] = JSON.parse(JSON.stringify(creativeSpec["instantExperience"]));
            if (isVideo) {
              delete spec["instantExperience"]["photo"];
              delete spec["instantExperience"]["canvasPhoto"];
              spec["instantExperience"]["isVideo"] = true
            } else {
              delete spec["instantExperience"]["canvasVideo"];
            }
          } if (this.isBrandAwarenessObjective(this.selectedCampaignLauncherConfig) && !headerValMap["HOT_Link"]) {
            if (row[i].split('-')[0] == 'DXV') {
              delete spec["creative"]["object_story_spec"]["video_data"]["call_to_action"]
            } else {
              spec = creativeSpec["imageWithoutLink"]
            }
          }
        } else if (this.colHeaders[i] === 'HOT_Call To Action' && row[i]) {
          headerValMap[this.colHeaders[i]] = row[i].split(' ').map(x => x.toUpperCase()).join('_')
        } else if (this.dropdownColumns.has(this.colHeaders[i]) && row[i]) {
          headerValMap[this.colHeaders[i]] = row[i].split(' ').map(x => x.toLowerCase()).join('_')
        } else if (this.colHeaders[i] === 'HOT_Catalog Card: Creative') {
          headerValMap[this.colHeaders[i]] = row[i].split(' ').map(x => x).join('_')
        } else if (this.colHeaders[i] === 'HOT_Deep Link Destination') {
          headerValMap[this.colHeaders[i]] = row[i].split(' ').map(x => x.toLowerCase()).join('_')
        } else if (this.colHeaders[i] === 'HOT_Phone Number') {
          headerValMap[this.colHeaders[i]] = `tel:+${row[i]}`
        } else if (this.colHeaders[i] === 'HOT_Frame Image' && row[i]) {
          headerValMap[this.colHeaders[i]] = this.getImageHashOrUrl(row[i]);
        } else if (this.colHeaders[i] === 'HOT_Catalogue Information Opacity') {
          headerValMap[this.colHeaders[i]] = row[i] == "Solid" ? 100 : 75;
        } else if (this.colHeaders[i].startsWith("HOT_LD_") && row[i]) {
          let ldName = this.displayColHeaders[i].replace(/\s/g, "");
          labelDimensionMacroValues[`{{LabelDimension.${ldName}}}`] = row[i];
        } else {
          headerValMap[this.colHeaders[i]] = row[i]
        }
      }

      headerValMap["HOT_Ad Name"] = this.getAdName(row);

      // For Event Responses (FbObjective: 6), image/video column is optional.
      // So, set spec to image creativeSpec if no image/video provided by user.
      if (this.isEventResponsesObjective(this.selectedCampaignLauncherConfig) && JSON.stringify(this.creativeSpec) == JSON.stringify(spec)) {
        spec = spec["image"]
      }
      if (this.isStoreVisitsObjective(this.selectedCampaignLauncherConfig)) {
        spec["creative"]["dynamic_ad_voice"] = fbEnums.dynamicAdVoice[this.selectedCampaignLauncherConfig.ad.dynamicAdVoice];
      }
      if (
        this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) ||
        this.selectedCampaignLauncherConfig.adset.destination == fbEnums.destinationType.App
      ) {
        const storeUrl = this.selectedCampaignLauncherConfig.adset.app.storeUrl;
        const appId = this.selectedCampaignLauncherConfig.adset.app.appId;
        if (this.tab != "collection" || this.tab != "collectionCatalog") {
          headerValMap["HOT_Link"] = storeUrl
        }
        headerValMap["HOT_AppId"] = appId
        for (let i = 1; i <= this.hardcodedCardsCount; i++) {
          headerValMap[`HOT_Link ${i}`] = storeUrl
          headerValMap[`HOT_Call To Action ${i}`] = headerValMap["HOT_Call To Action"];
          headerValMap[`HOT_AppId ${i}`] = appId
        }
      }
      if (this.isReachObjective(this.selectedCampaignLauncherConfig) && headerValMap['HOT_Call To Action'] == "CALL_NOW") {
        let callToAction = {
          "type": "CALL_NOW",
          "value": {
            "link": "HOT_Phone Number"
          }
        }
        if (spec["creative"]["object_story_spec"]["link_data"]) {
          spec["creative"]["object_story_spec"]["link_data"]["call_to_action"] = callToAction
        } else {
          spec["creative"]["object_story_spec"]["video_data"]["call_to_action"] = callToAction
        }
      }
      if (this.isPromotionMode && ["single", "carousel"].includes(this.tab)) {
        headerValMap['HOT_Page'] = "{{store.facebookpageid}}"
        headerValMap['HOT_InstagramId'] = "{{store.instagramaccountid}}"
      } else {
        headerValMap['HOT_Page'] = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config).facebookPageId
        headerValMap['HOT_InstagramId'] = JSON.parse(this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config).instagramAccountId
      }
      if (this.tab === 'carousel') {
        if (this.isDPAObjective(this.selectedCampaignLauncherConfig) ||
          (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) &&
            this.selectedCampaignLauncherConfig.campaign.productCatalog)) {
          // KEEPING THIS CASE EMPTY FOR NOW
          // let mediaId = headerValMap["HOT_Intro Card: Image"]
          // if (mediaId.split("-")[0] === "DXC") {
          //   headerValMap["HOT_Intro Card: Image"] = this.getImageHashOrUrl(mediaId);
          // }
          // // check if static card is required to be included
          // var staticCardColumns = ["HOT_Intro Card: News Feed Link Description",
          //   "HOT_Intro Card: Image",
          //   "HOT_Intro Card: Website Url",
          //   "HOT_Intro Card: Headline"
          // ];
          // var isStaticCardRequired = this.colHeaders.reduce((previousValue, currentValue, i) => {
          //   var isStaticCardColumn = staticCardColumns.includes(currentValue);
          //   if (isStaticCardColumn) {
          //     return previousValue && (row[i] != "" && row[i] != undefined && row[i] != null);
          //   } else {
          //     return previousValue && true;
          //   }
          // }, true);
          // if (!isStaticCardRequired) {
          //   delete spec["creative"]["object_story_spec"]["template_data"]["child_attachments"];
          // }
        } else {
          let card = null;
          if (this.isStoreVisitsObjective(this.selectedCampaignLauncherConfig)) {
            card = spec["creative"]["object_story_spec"]["template_data"]["child_attachments"][0];
          } else {
            card = spec["creative"]["object_story_spec"]["link_data"]["child_attachments"][0];
          }
          let cards = []
          for (let i = 1; i <= this.hardcodedCardsCount; i++) {
            let mediaName = `HOT_Image/Video ${i}`
            if (`HOT_Video ${i}` in headerValMap) {
              mediaName = `HOT_Video ${i}`
            }
            if (
              (headerValMap[`HOT_Link ${i}`] &&
                headerValMap[`HOT_Image/Video ${i}`]) ||
              headerValMap[`HOT_Video ${i}`] ||
              (headerValMap[`HOT_Image/Video ${i}`] &&
                (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig) || this.isMessagesObjective(this.selectedCampaignLauncherConfig) ||
                [fbEnums.destinationType.App, fbEnums.destinationType.WhatsApp].includes(
                  this.selectedCampaignLauncherConfig.adset.destination
                )))
            ) {
              if (this.isStoreVisitsObjective(this.selectedCampaignLauncherConfig) &&
              (headerValMap[`HOT_Call To Action`] == 'CALL_NOW' || headerValMap[`HOT_Call To Action`] == 'GET_DIRECTIONS')
              ) {
                delete card.call_to_action.value
              }
              cards.push(JSON.parse(JSON.stringify(card)))
              var mediaId = headerValMap[mediaName]
              if (mediaId.split("-")[0] === "DXC") {
                headerValMap[mediaName] = this.getImageHashOrUrl(mediaId);
              } else if (mediaId.includes("http") || mediaId.includes("www")) {
                headerValMap[mediaName] = mediaId
              } else {
                headerValMap[mediaName] = this.getImageHashOrUrl(mediaId);
                headerValMap[`HOT_video_id ${i}`] = this.imageIdUrlMap[mediaId]["SearchEngineVideoId"]
                cards[cards.length - 1]["video_id"] = "HOT_video_id"
              }
              if (this.isConversionsObjective(this.selectedCampaignLauncherConfig) ||
                  this.isTrafficObjective(this.selectedCampaignLauncherConfig) ||
                  this.isLeadGenerationObjective(this.selectedCampaignLauncherConfig) ||
                  this.isStoreVisitsObjective(this.selectedCampaignLauncherConfig) ||
                  this.isMessagesObjective(this.selectedCampaignLauncherConfig)) {
                headerValMap[`HOT_Call To Action ${i}`] = headerValMap["HOT_Call To Action"];
              }
              if (this.isLeadGenerationObjective(this.selectedCampaignLauncherConfig)) {
                if (this.selectedCampaignLauncherConfig.adset.destination == fbEnums.destinationType.PhoneCall) {
                  headerValMap[`HOT_Phone Number ${i}`] = headerValMap["HOT_Phone Number"];
                } else {
                  headerValMap[`HOT_Lead Form Id ${i}`] = headerValMap["HOT_Lead Form Id"];
                }
              }
              this.setCarouselCardNum(cards[cards.length - 1], i)
            }
          }
          if (this.isStoreVisitsObjective(this.selectedCampaignLauncherConfig)) {
            if (headerValMap["HOT_AddMap"] == true || headerValMap["HOT_AddMap"] == "true") {
              cards.push({
                "link": "https://www.facebook.com/store_locator",
                "name": "Check out our stores.",
                "place_data": {
                  "type": "DYNAMIC"
                }
              });
            }
            spec["creative"]["object_story_spec"]["template_data"]["child_attachments"] = cards
          } else {
            spec["creative"]["object_story_spec"]["link_data"]["child_attachments"] = cards
          }
        }
      } else if (this.tab == "existing posts") {
        var creative = spec["creative"];

        creative["call_to_action"] = {};
        creative["call_to_action"]["value"] = {};
        creative["call_to_action"]["type"] = "HOT_Call To Action";
        creative["call_to_action"]["value"]["link"] = "HOT_Website Url";

        // set spec based on whether its ig post or facebook post
        var post = this.instagramPosts.filter(e => {
          return e.id == row[2];
        })
        if (post.length != 0 && post[0].ig_id) {
          creative["object_id"] = this.selectedCampaignLauncherConfig.facebookPageId;
          creative["source_instagram_media_id"] = "HOT_Post ID";
          creative["instagram_user_id"] = this.connectedInstagramAccountId;
          delete creative["instagram_actor_id"];
          delete creative["object_story_id"];
        }
      }
      if (this.isDPAObjective(this.selectedCampaignLauncherConfig) && ["single", "carousel"].includes(this.tab)) {
        let frameReqCol = `HOT_${fbEnums.dpaCreativeEditRequiredCols.Frame}`;
        let catalogReqCol = `HOT_${fbEnums.dpaCreativeEditRequiredCols['Catalogue Information']}`;

        const dpaCreativeReqColsFilled = this.dpaCreativeEditRequiredCols.some(
          (col) => Boolean(headerValMap[`HOT_${col}`])
        );

        const imgLayerSpec = spec.creative.object_story_spec.template_data.image_layer_specs;

        if (
          headerValMap["HOT_Catalog Card: Creative"] == "carousel_slideshows" ||
          !dpaCreativeReqColsFilled
        ) {
          delete spec.creative.object_story_spec.template_data.image_layer_specs;
        } else {
          if (!headerValMap[frameReqCol]) {
            spec.creative.object_story_spec.template_data.image_layer_specs =
              imgLayerSpec.filter((x) => x.layer_type != "frame_overlay");
          } else if (!headerValMap[catalogReqCol]) {
            spec.creative.object_story_spec.template_data.image_layer_specs =
              imgLayerSpec.filter((x) => x.layer_type != "text_overlay");
          }

          this.setDpaCreativeEditDefaultValues(headerValMap, spec.creative.object_story_spec.template_data.image_layer_specs);
        }
      }
      this.recurse(spec, headerValMap)
      return spec
    },
    setDpaCreativeEditDefaultValues (headerValMap, imgLayerSpecs) {
      const defaultValueSetter = (imgLayerSpec, defaultValues) => {
        for (let [propName, colName] of Object.entries(imgLayerSpec)) {
          if (!headerValMap[colName]) {
            headerValMap[colName] = defaultValues[propName];
          }
        }
      }

      imgLayerSpecs.forEach(imgLayerSpec => {
        if (imgLayerSpec.layer_type == "frame_overlay") {
          defaultValueSetter(imgLayerSpec, fbEnums.dpaCreativeEditDefaultValues.frame)
        } else if (imgLayerSpec.layer_type == "text_overlay") {
          defaultValueSetter(imgLayerSpec, fbEnums.dpaCreativeEditDefaultValues.catalogueInformation)
        }
      })
    },
    placementCustomizationSpec (row, index) {
      this.mainSheetHotData = row
      let popupTableData = JSON.parse(JSON.stringify(this.creativeSetupData[index].hotTableData));
      var assetFeedSpec = this.$refs.creativeSetup.getAssetFeedSpecJSON(popupTableData, row, this.colHeaders)
      return { "creative": assetFeedSpec }
    },
    processDynamicCreativeData (hotData) {
      return {
        HOT_Headline: hotData["HOT_Headline"].flat(),
        HOT_Description: hotData["HOT_Description"].flat(),
        "HOT_Primary Text": hotData["HOT_Primary Text"].flat(),
        "HOT_Call To Action": hotData["HOT_Call To Action"].map(cta =>
          cta
            .split(" ")
            .map(x => x.toUpperCase())
            .join("_")
        ),
        "HOT_Image/Video": hotData["HOT_Image/Video"].flat().map(cellData => {
          const media =
            cellData.split("-")[0] === "DXC"
              ? this.getImageHashOrUrl(cellData)
              : this.imageIdUrlMap[cellData]["src"];
          return media;
        }),
        ...(!this.isImage && {
          HOT_SearchEngineVideoId: hotData["HOT_Image/Video"]
            .flat()
            .map(
              cellData => this.imageIdUrlMap[cellData]["SearchEngineVideoId"]
            )
        })
      };
    },
    dynamicCreativeSpec (row, index) {
      let dynamicCreativeSpec = JSON.parse(JSON.stringify(this.creativeSpec))
        .dynamicCreative;
      let metadata = dynamicCreativeSpec.metadata;
      let headerValMap = {};
      let dynamicData = this.processDynamicCreativeData(
        this.creativeSetupData[index].hotTableData
      );
      let isImage = true;
      const headlineRemap = metadata.headlineRemap;

      for (let i = 0; i < this.colHeaders.length; i++) {
        if (!row[i]) {
          continue;
        }
        if (this.colHeaders[i] === "HOT_Image/Video") {
          if (row[i].split("-")[0] === "DXC") {
            dynamicData[this.colHeaders[i]].push(this.getImageHashOrUrl(row[i]));
          } else {
            isImage = false;
            dynamicData[this.colHeaders[i]].push(
              this.imageIdUrlMap[row[i]]["src"]
            );
            dynamicData["HOT_SearchEngineVideoId"].push(
              this.imageIdUrlMap[row[i]]["SearchEngineVideoId"]
            );
          }
        } else if (this.colHeaders[i] === "HOT_Call To Action") {
          dynamicData[this.colHeaders[i]].push(
            row[i]
              .split(" ")
              .map(x => x.toUpperCase())
              .join("_")
          );
        } else {
          let correctHeadline =
            this.colHeaders[i] in headlineRemap
              ? headlineRemap[this.colHeaders[i]]
              : this.colHeaders[i];
          if (correctHeadline in dynamicData) {
            dynamicData[correctHeadline].push(row[i]);
          } else {
            headerValMap[correctHeadline] = row[i];
          }
        }
      }
      let spec = isImage
        ? dynamicCreativeSpec.image
        : dynamicCreativeSpec.video;

      let assetFeedSpec = {};
      let referenceSpec = spec.creative.asset_feed_spec;

      if (!isImage) {
        metadata.colToAssetSpecMap["HOT_Image/Video"] = "videos"
      }
      for (let [hotKey, assetKey] of Object.entries(
        metadata.colToAssetSpecMap
      )) {
        const replicaCount = dynamicData[hotKey].length;
        if (replicaCount == 0) {
          delete referenceSpec[assetKey]
        }
        for (let i = 1; i <= replicaCount; i++) {
          if (!(assetKey in assetFeedSpec)) {
            assetFeedSpec[assetKey] = [];
          }
          let toReplicate = referenceSpec[assetKey][0];
          if (
            typeof toReplicate === "string" &&
            toReplicate.startsWith("HOT_")
          ) {
            assetFeedSpec[assetKey].push(`${toReplicate} ${i}`);
          } else {
            assetFeedSpec[assetKey].push(
              JSON.parse(JSON.stringify(toReplicate))
            );
            this.setCarouselCardNum(assetFeedSpec[assetKey][i - 1], i);
          }
        }
      }
      if (this.isLeadGenerationObjective(this.selectedCampaignLauncherConfig)) {
        assetFeedSpec["call_to_actions"] = [];
        dynamicData["HOT_Call To Action"].forEach(cta => {
          assetFeedSpec["call_to_actions"].push({
            type: cta,
            value: {
              lead_gen_form_id: "HOT_Lead Form Id"
            }
          });
        });
      }
      if (this.isAppInstallsObjective(this.selectedCampaignLauncherConfig)) {
        const storeUrl = this.selectedCampaignLauncherConfig.adset.app.storeUrl;
        headerValMap["HOT_Link"] = storeUrl
      }
      Object.assign(spec.creative.asset_feed_spec, assetFeedSpec);

      for (let [hotKey, values] of Object.entries(dynamicData)) {
        for (let i = 0; i < values.length; i++) {
          headerValMap[`${hotKey} ${i + 1}`] = values[i];
        }
      }

      if (this.isPromotionMode && ["single", "carousel"].includes(this.tab)) {
        headerValMap["HOT_Page"] = "{{store.facebookpageid}}"
      } else {
        headerValMap["HOT_Page"] = this.selectedCampaignLauncherConfig.facebookPageId;
      }

      this.recurse(spec, headerValMap);
      return spec;
    },
    buildCreativeSpec (row, index) {
      var spec = "";
      if (index in this.creativeSetupData && this.creativeSetupData[index].enabled) {
        if (this.creativeSetupData[index].type === 'Dynamic Creative') {
          spec = this.dynamicCreativeSpec(row, index)
        } else {
          spec = this.placementCustomizationSpec(row, index)
        }
      } else {
        spec = this.regularSpec(row)
      }
      this.creativeEnhancements(row, spec);
      return spec;
    },
    getPreviewableRows () {
      this.uniqueId = this.publishedCreativesSize + 1
      let validSpec = []; let validRows = []
      let instance = this.$refs['hotPublish'].hotInstance
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        if (this.checkIfPreviewable(previewRow) && rowNum >= this.publishedCreativesSize) {
          let rowData = instance.getDataAtRow(rowNum) // [true, ad name, Dlow, null]
          let rowDataObj = this.buildRowDataObj(rowData, rowNum)
          let adName = this.getAdName(rowData);
          let targets = this.getRowValueForColumn(rowData, "HOT_Targets");
          let fractions = this.getRowValueForColumn(rowData, "HOT_LineItemFractions");
          let locations = this.getRowValueForColumn(rowData, "HOT_Locations");
          let startDate = this.getRowValueForColumn(rowData, "HOT_StartDate");
          let endDate = this.getRowValueForColumn(rowData, "HOT_EndDate");
          var startTime = this.getRowValueForColumn(rowData, "HOT_StartTime");
          var endTime = this.getRowValueForColumn(rowData, "HOT_EndTime");
          // get link value to set conversion domain
          var defaultLink = this.getRowValueForColumn(rowData, "HOT_Link")
          var carouselLink = this.getRowValueForColumn(rowData, "HOT_See More Url")
          var collectionLink = this.getRowValueForColumn(rowData, "HOT_Button Destination")
          let adLabels = this.getAdLabels(rowData);
          var viewTags = this.getRowValueForColumn(rowData, "HOT_ViewTags");
          if (viewTags) {
            viewTags = viewTags.split(",");
          } else {
            viewTags = [];
          }
          var link = "";
          if (defaultLink) {
            link = defaultLink;
          } else if (carouselLink) {
            link = carouselLink;
          } else if (collectionLink) {
            link = collectionLink;
          }
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          let spec = {
            'hotRowId': `${this.tab}-${this.uniqueId++}`,
            'AdName': `${adName}`,
            'targets': targets,
            'locations': locations,
            'fractions': fractions,
            'Creative': creativeSpec["creative"],
            'StartDate': startDate || null,
            'EndDate': endDate || null,
            'StartTime': startTime,
            'EndTime': endTime,
            'Link': link,
            'labels': adLabels,
            'ViewTags': viewTags
          }
          if (this.tab == 'collection' || this.tab == "collectionCatalog") {
            if (this.isDPAObjective(this.selectedCampaignLauncherConfig) || this.isSalesCollectionCatalogAd()) {
              spec["InstantExperience"] = creativeSpec["instantExperience"]
            } else {
              let ieIndex = this.colHeaders.indexOf("HOT_Instant Experience");
              let ieId = rowData[ieIndex];
              let canvas = this.getInstantExperienceById(ieId);
              spec["InstantExperience"] = {
                id: ieId,
                isPublished: canvas && canvas.is_published
              }
            }
          }
          validSpec.push(spec)
          validRows.push(rowDataObj)
        }
      }
      return [validSpec, validRows]
    },
    getPreviewableRowsForAdPreview () {
      this.uniqueId = 0;
      let instance = this.$refs['hotPublish'].hotInstance;
      let creativeSpecDetails = [];
      // if (this.publishedCreatives && this.publishedCreatives[this.tab]) {
      //   for (var i = 0; i < this.publishedCreatives[this.tab].length; i++) {
      //     let rowData = this.publishedCreatives[this.tab][i];
      //     let isNotPublished = rowData.HOT_IsNotPublished;
      //     if (isNotPublished) continue;
      //     let adName = this.getReplacedMacroValues(rowData["HOT_Ad Name"]);
      //     var hotRowData = instance.getDataAtRow(i);
      //     let creativeSpec = this.buildCreativeSpec(hotRowData, i);
      //     creativeSpecDetails.push({ id: `DXC-${this.tab}-${this.uniqueId++}`, name: adName, spec: creativeSpec })
      //   }
      // }
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        if (this.checkIfPreviewable(previewRow)) {
          let rowData = instance.getDataAtRow(rowNum);
          let adNameIndex = this.colHeaders.indexOf("HOT_Ad Name");
          let adName = (adNameIndex != -1 && rowData[adNameIndex]) ? rowData[adNameIndex] : ""
          adName = this.getReplacedMacroValues(adName);
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          creativeSpecDetails.push({ id: `DXC-${this.tab}-${this.uniqueId++}`, name: adName, spec: creativeSpec })
        }
      }
      return creativeSpecDetails;
    },
    getCreativeStructure (tableData) {
      this.uniqueId = this.publishedCreativesSize + 1
      let validSpec = []; let validRows = []
      for (let [rowNum, previewRow] of Object.entries(this.previewable)) {
        if (this.checkIfPreviewable(previewRow)) {
          let rowData = tableData[rowNum]
          let rowDataObj = this.buildRowDataObj(rowData, rowNum)
          let adNameIndex = this.colHeaders.indexOf("HOT_Ad Name")
          let adName = (adNameIndex != -1 && rowData[adNameIndex]) ? rowData[adNameIndex] : ""
          let targets = this.getRowValueForColumn(rowData, "HOT_Targets");
          let locations = this.getRowValueForColumn(rowData, "HOT_Locations");
          let startDate = this.getRowValueForColumn(rowData, "HOT_StartDate");
          let endDate = this.getRowValueForColumn(rowData, "HOT_EndDate");
          var startTime = this.getRowValueForColumn(rowData, "HOT_StartTime");
          var endTime = this.getRowValueForColumn(rowData, "HOT_EndTime");
          let creativeSpec = this.buildCreativeSpec(rowData, rowNum);
          let spec = {
            'hotRowId': `${this.tab}-${this.uniqueId++}`,
            'AdName': `${adName}`,
            'targets': targets,
            'locations': locations,
            'Creative': creativeSpec["creative"],
            'StartDate': startDate || null,
            'EndDate': endDate || null,
            'StartTime': startTime,
            'EndTime': endTime
          }
          if (this.tab == 'collection' || this.tab == 'collectionCatalog') {
            if (this.isDPAObjective(this.selectedCampaignLauncherConfig) || this.isSalesCollectionCatalogAd()) {
              spec["InstantExperience"] = creativeSpec["instantExperience"]
            } else {
              let ieIndex = this.colHeaders.indexOf("HOT_Instant Experience");
              let ieId = rowData[ieIndex];
              spec["InstantExperience"] = {
                id: ieId,
                isPublished: this.getInstantExperienceById(ieId).is_published
              }
            }
          }
          validSpec.push(spec)
          validRows.push(rowDataObj)
        }
      }
      return [validSpec, validRows]
    },
    getAllRows () {
      this.uniqueId = this.publishedCreativesSize + 1
      let validRows = []
      let instance = this.$refs['hotPublish'].hotInstance
      let rowNum = 0;
      var gridData = instance.getData();
      var cleanedGridData = [];
      const showNomenclatureMacros = this.isNomenclatureEnabled() && !this.isPromotionMode
      var self = this;
      gridData.forEach((rowData, index) => {
        if (showNomenclatureMacros) {
          let hasUserInput = false
          for (let i = 0; i < rowData.length; i++) {
            if (self.colHeaders[i] == "HOT_Ad Name" && rowData[i]) {
              if (self.channelMacros.Ad && rowData[i] != self.channelMacros.Ad) {
                hasUserInput = true
              } else if (!self.channelMacros.Ad) {
                hasUserInput = true
              }
            } else if (self.colHeaders[i] == "HOT_Url Tags" && rowData[i]) {
              if (self.channelMacros.UrlTags && rowData[i] != self.channelMacros.UrlTags) {
                hasUserInput = true
              } else if (!self.channelMacros.UrlTags) {
                hasUserInput = true
              }
            } else if (self.colHeaders[i] != "HOT_Preview Ad" && rowData[i]) {
              hasUserInput = true
            }
          }
          if (hasUserInput) {
            cleanedGridData.push(rowData);
          }
        } else {
          if (!instance.isEmptyRow(index)) {
            cleanedGridData.push(rowData);
          }
        }
      })
      for (let rowData of cleanedGridData) {
        let rowDataObj = this.buildRowDataObj(rowData, rowNum)
        validRows.push(rowDataObj)
        rowNum++;
      }
      rowNum = 0;
      validRows.forEach(elem => {
        elem["HOT_IsNotPublished"] = false;
        rowNum++;
      });
      return validRows;
    },
    buildRowDataObj (rowData, rowNum) {
      let rowObj = Object.assign(
        ...rowData.map((cellValue, index) => ({
          [this.colHeaders[index]]: cellValue
        }))
      );
      if (rowNum in this.creativeSetupData && this.creativeSetupData[rowNum].enabled) {
        rowObj["creativeSetupData"] = {
          type: this.creativeSetupData[rowNum].type,
          hotTableData: this.creativeSetupData[rowNum].hotTableData
        }
      }
      return rowObj;
    },
    validateTargets (value, callBack) {
      if (value == "" || value == null) {
        callBack(true);
        return;
      }
      let self = this
      let targets = value.split(",")
      let targetNotMapped = []
      targets.forEach(function (target) {
        let splitTarget = target.split("-")
        if (target != "" && (splitTarget.length != 3 || splitTarget[0] != 'DXT' || splitTarget[1] != Number.parseInt(deltax.businessProfileId).toString(36) || isNaN(splitTarget[2]))) {
          self.$Message.error({
            background: true,
            content: "Target '" + target + "' is invalid"
          })
          callBack(false)
        } else {
          let flag = false
          if (target != "") {
            flag = self.mappedLineItems.map(x => {
              return x.targets
            }).flat().includes(parseInt(splitTarget[2]))
            if (!flag) {
              targetNotMapped.push(target)
            }
          }
        }
      })
      if (targetNotMapped.length > 0) {
        self.$Message.error({
          background: true,
          content: "Targets " + targetNotMapped.join(", ") + " is not mapped to a line item"
        })
        callBack(false)
      }
      callBack(true)
    },
    validateLocations (value, callBack) {
      if (value == "" || value == null) {
        callBack(true);
        return;
      }
      let self = this
      let locations = value.split(",")
      let locationNotMapped = []
      var mappedLocations = self.$store.state.savedMappedLocations[self.mediaPlanId + '-' + self.selectedCampaignLauncherConfigId]
      locations.forEach(function (location) {
        let splitLocation = location.split("-")
        if (location != "" && (splitLocation.length != 3 || splitLocation[0] != 'DXL' || splitLocation[1] != Number.parseInt(deltax.businessProfileId).toString(36))) {
          self.$Message.error({
            background: true,
            content: "Location '" + location + "' is invalid"
          })
          callBack(false)
        } else {
          if (location != "" && splitLocation[2]) {
            if (mappedLocations) {
              let keys = []
              if (self.selectedCampaignLauncherConfig.campaign.locationMode == "Hyperlocal") {
                keys = mappedLocations.map(x => x.storeCode)
              } else {
                keys = mappedLocations.map(x => x.facebookKey)
              }

              if (!keys.includes(splitLocation[2])) {
                locationNotMapped.push(splitLocation[2])
              }
            }
          }
        }
      })
      if (locationNotMapped.length > 0) {
        self.$Message.error({
          background: true,
          content: "Locations " + locationNotMapped.join(", ") + " is not mapped to a line item"
        })
        callBack(false)
      }
      callBack(true)
    },
    validateHexColor (value, callBack) {
      if (!value) {
        callBack(true);
        return;
      }
      value = String(value);
      if (value.length != 6) {
        callBack(false);
      } else if (!value.split('').every(x => (x >= 0 && x <= 9) || (x >= 'a' && x <= 'f') || (x >= 'A' && x <= 'F'))) {
        callBack(false);
      }
      callBack(true);
    },
    getRowValueForColumn (rowData, headerName) {
      var indexValue = this.colHeaders.indexOf(headerName);
      return indexValue != -1 && rowData[indexValue] ? rowData[indexValue] : null
    },
    getAdLabels (rowData) {
      let labels = [];
      for (let i = 0; i < this.colHeaders.length; i++) {
        if (this.colHeaders[i].startsWith("HOT_LD_") && rowData[i]) {
          labels.push(rowData[i]);
        }
      }
      return labels;
    },
    getAdName (rowData) {
      let adName = rowData[this.colNameIndexMap["HOT_Ad Name"]];
      if (!adName) {
        return ""
      }
      for (let i = 0; i < rowData.length; i++) {
        if (this.colHeaders[i].startsWith("HOT_LD_") && rowData[i]) {
          let ldName = this.displayColHeaders[i].replace(/\s/g, "");
          adName = adName.replaceAll(`{{LabelDimension.${ldName}}}`, rowData[i]);
        }
      }
      return adName;
    },
    isNomenclatureEnabled () {
      let facebookMacros = this.getFacebookNomenclature;
      if (facebookMacros && facebookMacros.campaign != '' && facebookMacros.adSet != '') {
        return true;
      }
      return false;
    },
    addToLabelLDMap (label, labelDimension, increment = true) {
      if (!this.labelLDMap[label]) {
        this.labelLDMap[label] = {
          labelDimension,
          count: 0
        };
      }
      if (increment) {
        this.labelLDMap[label].count += 1;
      }
    },
    macroColRenderer (instance, td, row, col, prop, value, cellProperties) {
      let macroValue = this.colHeaders[col] == "HOT_Ad Name" ? this.channelMacros.Ad : this.channelMacros.UrlTags;
      macroValue = value && macroValue != value ? value : macroValue
      if ([row, col] in this.macroRenderedCells) {
        this.htData[row][col] = this.getReplacedMacroValues(value);
        Handsontable.renderers.TextRenderer.apply(this, arguments)
      } else {
        var result = this.getReplacedMacroValues(macroValue);
        arguments[5] = result;
        this.htData[row][col] = macroValue;
        if (row < this.publishedCreativesSize) {
          this.updatePreviewColumn(row, col, null, result, instance);
        } else {
          this.updatePreviewColumn(row, col, value, result, instance);
        }
        Handsontable.renderers.TextRenderer.apply(this, arguments);
      }
    },
    getReplacedMacroValues (macro) {
      var finalString = macro;
      if (macro.includes("{{") && macro.includes("}}")) {
        var macroList = macro.match(/{{[A-za-z0-9.]*}}/g).map(macro => macro.slice(2, macro.length - 2));
        macroList.forEach(macro => {
          for (var item in this.macroValueMapper) {
            let macroKeys = Object.keys(this.macroValueMapper[item]);
            if (item === "globalMacros") {
              let withLowercaseKeys = {}
              Object.keys(this.macroValueMapper[item]).forEach(key => {
                const value = this.macroValueMapper[item][key];
                withLowercaseKeys[key.toLowerCase()] = value;
              });
              if (withLowercaseKeys[macro.toLowerCase()]) { finalString = finalString.replace(`{{${macro}}}`, withLowercaseKeys[macro.toLowerCase()]); break; }
            } else {
              if (macroKeys.length < 2) {
                if (this.macroValueMapper[item][macroKeys[0]][macro]) { finalString = finalString.replace(`{{${macro}}}`, this.macroValueMapper[item][macroKeys[0]][macro]); break; }
              }
            }
          }
        });
      } return finalString;
    },
    setStatusAndError (hotRow, creativeName) {
      var creativeData = this.adCreativeMapping.filter(a => a.creativeName == creativeName)
      hotRow['Error'] = creativeData.map(a => a.failureReason).find(f => f)
      var failedCount = creativeData.filter(a => a.status == 'Failed').length
      var newCount = creativeData.filter(a => a.status == 'New').length
      var successCount = creativeData.filter(a => a.status == 'Success').length
      var totalCount = creativeData.length
      if (failedCount == totalCount) {
        hotRow['Status'] = 'Error'
      } else if (newCount == totalCount) {
        hotRow['Status'] = 'New'
      } else if (successCount == totalCount) {
        hotRow['Status'] = 'Published'
      } else {
        hotRow['Status'] = 'Partial'
      }
    }
  }
}
</script>

<style>
/* .ht_clone_left table.htCore {
  box-shadow: 2px 0 5px -2px rgba(0, 0, 0, 0.2);
} */
.hot-img {
  display: block;
  padding: 2px;
  margin: 0 auto;
  max-height: 70px;
  max-width: 100px;
}
.ad-sheet-hot .handsontable td,
.ad-sheet-hot .handsontable tr,
.ad-sheet-hot .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}

.preview-td-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}
.preview-td-wrapper > span {
  display: inline-flex;
  flex-grow:1;
  flex-basis: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
	cursor: pointer;
}
.preview-icon {
	font-size: 18px;
	opacity: 1;
}
.preview-icon-disabled > span.eye {
	pointer-events: none;
  opacity: 0.5;
}

.green-beaker .fa-flask {
  color: #51b848;
}
.disabled-cell {
	background-color: #f6f6f6 !important;
}
.disabled-cell-text {
  background-color: #f6f6f6 !important;
  color: #bbb !important;
}
.post-cell {
  cursor: not-allowed;
}
.trim {
  margin: 0 !important;
  padding: 0 !important;
}
.handsontable td.htInvalid {
  background-color: white !important;
  border: 1px solid red
}
.error {
  color: red !important;
}
.partial, .new {
  color: orange !important;
}
.published {
  color: green !important;
}
.status-span {
  font-size: 20px;
}
</style>

<style src="../../../../../../../node_modules/handsontable/dist/handsontable.full.css"></style>
