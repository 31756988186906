<template>
  <div>
    <div v-if="this.sptab == 'AdGroups'" class="hot-div" style="width: 100%; height: 580px; overflow: auto;" :key="rerenderKey">
      <hot-table
        ref="hot1"
        :id="'adgrouphot'"
        :settings="adgroupSettings"
      ></hot-table>
    </div>
    <div v-else-if="this.sptab == 'Keyword Targeting'" class="hot-div" style="width: 100%; height: 580px; overflow: auto; " :key="rerenderKey">
      <Select v-model="adgroupsSelectedKeyword" style="width:200px;padding-bottom:1%;padding-left:1%">
        <Option v-for="item in adgroupList" :value="item" :key="item">{{ item }}</Option>
      </Select>
      <div class="flex">
        <div style="padding-left:1%;padding-bottom:1%">
      <hot-table
      class="ad-sheet-hot"
        ref="hot2"
        :id="'keywordHot'"
        :settings="keywordSettings"
      ></hot-table>
      </div>
      <div style="padding-left:1%">
      <hot-table
      class="ad-sheet-hot"
        ref="hot3"
        :id="'negativeKeywordHot'"
        :settings="negativeKeywordSettings"
      ></hot-table>
      </div>
      </div>
    </div>
    <div v-if="this.sptab == 'Product Targeting'" class="hot-div" style="width: 100%; height: 580px; overflow: auto;" :key="rerenderKey">
      <Select v-model="adgroupsSelectedProduct" style="width:200px;padding-bottom:1%;padding-left:1%">
        <Option v-for="item in productAdgroupList" :value="item" :key="item">{{ item }}</Option>
      </Select>
      <div class="flex">
        <div style="padding-left:1%">
      <hot-table
      class="ad-sheet-hot"
        ref="hot4"
        :id="'productHot'"
        :settings="productSettings"
      ></hot-table>
      </div>
      <div style="padding-left:1%">
      <hot-table
      class="ad-sheet-hot"
        ref="hot5"
        :id="'negativeProductHot'"
        :settings="negativeProductSettings"
      ></hot-table>
      </div>``
      </div>
    </div>
    <div v-if="this.sptab == 'Targeting'" class="hot-div" style="width: 100%; height: 580px; overflow: hidden; " :key="rerenderKey">
      <Select v-model="adgroupsSelected" style="width:200px;padding-bottom:1%">
        <Option v-for="item in adgroupList" :value="item" :key="item">{{ item }}</Option>
      </Select>
      <div class="flex">
        <div style="padding-left:1%">
      <hot-table
      class="ad-sheet-hot"
        ref="hot6"
        :id="'autoTargetHot'"
        :settings="autoTargetingSettings"
      ></hot-table>
      </div>
      <div style="padding-left:1%">
      <hot-table
      class="ad-sheet-hot"
        ref="hot7"
        :id="'negativeProductHot'"
        :settings="negativeProductSettings"
      ></hot-table>
      </div>``
      <div style="padding-left:1%">
      <hot-table
      class="ad-sheet-hot"
        ref="hot8"
        :id="'negativeKeywordHot'"
        :settings="negativeKeywordSettings"
      ></hot-table>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { APIService } from "../../ApiService.js";
import { mapState, mapGetters, mapMutations } from "vuex";
import { HotTable } from "@handsontable/vue";
import { EventBus } from "../../EventBus.js";
import Handsontable from "handsontable";
import { Select, Option } from "iview";
import {
  columnConfig,
  displayHeaderNameLookup,
  displayHeaderNameLookupSpKeywordTarget,
  columnConfigSpKeywordTarget,
  displayHeaderNameLookupSpProductTarget,
  columnConfigSpProductTarget,
  displayHeaderNameLookupSpAutoTarget,
  columnConfigSpAutoTarget
} from "../../columnConfig.js";
var exportStatus = {
  "Enabled": 1,
  "Paused": 3,
  "Success": 8,
  "Failed": 10
}

export default {
  components: {
    "hot-table": HotTable,
    Select,
    Option
  },
  props: {
    sptab: { default: "" },
    currentAmsTab: String
  },
  data: function () {
    return {
      tabledata: [],
      rerenderKey: 0,
      rateValue: 0,
      allcolumns: {},
      configColumns: {},
      requiredCols: {},
      lineItem: [],
      targetResponse: [],
      alreadyProcessed: {},
      clusterIds: [],
      targetsData: [],
      audiencesCluster: [],
      showAudiencePop: false,
      clusterId: 0,
      selectedAudience: {},
      selectedRow: 0,
      defaultBid: 0,
      currencyResponse: {},
      country: "",
      allCategoryNames: [],
      error: [],
      lineitemData: [],
      lineitemAdsData: [],
      previewLogo: '',
      previewHeadline: '',
      previewProductImage: '',
      previewCustomImage: '',
      showPreview: false,
      cellProperties: [],
      adsDetails: [],
      previewProductTitle: '',
      asins: [],
      adgroudDataLength: 3,
      adgroupsSelected: '',
      adgroupsSelectedKeyword: 'All Adgroups',
      adgroupSpecificKeywords: [],
      adgroupSpecificNegativeKeywords: [],
      adgroupSpecificAsins: [],
      adgroupSpecificNegativeAsins: [],
      publishedAmsResponse: {},
      keywordTableData: [],
      negativeKeywordTableData: [],
      productTableData: [],
      negativeProductTableData: [],
      adgroupList: [],
      productAdgroupList: [],
      filteredData: [],
      filteredNegativeKeywordData: [],
      filteredProductData: [],
      filteredNegativeProductData: [],
      adgroupsSelectedProduct: 'All Adgroups',
      entitiesData: {},
      adgroupData: [],
      keywordData: [],
      negativeKeywordData: [],
      productData: [],
      negativeProductData: [],
      asinsData: [],
      editedAdgroupId: '',
      campaignData: [],
      adgroupDataLength: 0,
      targetingType: {
        "Keyword Targeting": 1,
        "Product Targeting": 2
      },
      invalidAdgroupCells: [],
      adsData: [],
      savedData: {},
      keywordCellSettings: [],
      negativeKeywordCellSettings: [],
      productCellSettings: [],
      negativeProductCellSettings: [],
      keywordInvalidAdgroupCellSettings: [],
      negativeKeywordInvalidAdgroupCellSettings: [],
      productInvalidAdgroupCellSettings: [],
      negativeProductInvalidAdgroupCellSettings: [],
      adgroupCellSettings: [],
      waitTime: 0
    };
  },
  computed: {
    ...mapState([
      "campaignLauncherConfigs",
      "mappedLineItems",
      "selectedCampaignLauncherConfig",
      "selectedCampaignLauncherConfigId",
      "mediaPlanId",
      "amsPublishedData",
      "deltax",
      "tableData",
      "businessProfileId",
      "amsEnums",
      "keywordTablesData",
      "productTablesData",
      "IsAmsLineItemsUpdated",
      "bpCurrencySymbol",
      "clusterDetails",
      "isCreated",
      "amsEntitiesResponse",
      "isRefreshEntitiesData",
      "isSpSubmissionFlow",
      "amsSpError"
    ]),
    ...mapGetters(["getMediaPlanById", "adgroupDetails", "amsSheetDetails"])
  },
  created () {
    let self = this;
    this.$store.commit("set_AmsLoadNext", true);
    this.$store.commit("set_AmsLoadSave", true);
    window.updateKeywordBidValues = this.updateKeywordBidValues;
    window.updateProductBidValues = this.updateProductBidValues;
    this.selectedMediaPlanDetails = this.getMediaPlanById(this.mediaPlanId);
    this.adgroupList.push("All Adgroups");
    this.productAdgroupList.push("All Adgroups");
    this.configColumns = JSON.parse(
      this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId].config
    );
    this.defaultBid = this.configColumns.campaign.defaultBid;
    if (!this.isCreated && this.sptab == 'AdGroups') {
      this.entitiesData = {};
      this.getAdSheetData();
    }
    if (this.isCreated) {
      setTimeout(() => this.LoadSavedData(), 10000);
    }
    this.updateColumnConfig();
    EventBus.$on("SaveAmazonSpTargets", alwaysCb => {
      self.saveAlwaysCallback = alwaysCb
      self.processTableData();
    });
    EventBus.$on("saveAmazonSpCreative", alwaysCb => {
      self.saveAlwaysCallback = alwaysCb
      self.saveTableData();
    });
  },
  methods: {
    ...mapMutations(["set_clusterDetails", "set_tableData", "set_AmsLoadNext", "set_AmsSpError", "set_adsData", "set_AmsDataValid", "set_keywordTableData", "set_productTableData", "set_amsLineItemsUpdate", "set_bpCurrencySymbol", "set_amsSheetData", "set_isCreated", "set_AmsLoadSave", "set_amsEntitiesResponse", "set_isRefreshEntitiesData"]),
    getAdSheetData () {
      var response = null;
      this.savedData = null;
      APIService.getAdSheetData(this.mediaPlanId, this.selectedCampaignLauncherConfigId, this.adSheetMode, 10)
        .then(res => {
          response = res.data.data
          if (response != null && response.creativeJson != null) {
            this.savedData = response;
            var adsheetData = JSON.parse(response.creativeJson);
            this.set_amsSheetData(adsheetData);
          }
          this.getEntitiesData();
        });
    },
    LoadSavedData () {
      this.entitiesData = this.amsEntitiesResponse;
      if (!this.entitiesData.amsEntities && this.waitTime <= 3) {
        setTimeout(() => this.LoadSavedData(), 5000);
        this.waitTime++;
      }
      if (this.entitiesData.amsEntities && this.sptab == "AdGroups") {
        this.getColumnConfig('AdGroups')
        let adgroupColumns = this.allcolumns[0];
        let adgroupColHeaders = adgroupColumns["base"];
        this.displayColHeaders["HOT_DefaultBid"] = this.displayColHeaders["HOT_DefaultBid"] + " (" + this.bpCurrencySymbol + ")"
        this.getColumnsData(adgroupColHeaders);
        this.adgroupData = this.amsSheetDetails.Adgroup;
      }
      this.updateTableData(this.amsSheetDetails.Adgroup, 'hot1', this.columns, this.colHeaders);
      this.updateTableData(this.amsSheetDetails.keywords, 'hot2');
      this.updateTableData(this.amsSheetDetails.negativeKeywords, 'hot3');
      this.updateTableData(this.amsSheetDetails.products, 'hot4');
      this.updateTableData(this.amsSheetDetails.negativeProducts, 'hot5');
      if (this.entitiesData.amsEntities && this.sptab == "Product Targeting") {
        this.$store.commit("set_AmsLoadNext", false);
        this.$store.commit("set_AmsLoadSave", false);
      }
      this.keywordTableData.push(this.keywordTablesData.keyword)
      this.productTableData.push(this.productTablesData.asin)
      this.negativeProductTableData.push(this.productTablesData.negativeAsin)
      this.negativeKeywordTableData.push(this.keywordTablesData.negativeKeyword)
    },
    validateKeywords (keywords) {
      let validKeywords = [];
      let adgroups = this.adgroupData.filter(a => a.amsTargetType == 'Keyword Targeting').map(a => a.adGroup);
      keywords.forEach((keyword, keyindex) => {
        let duplicateKeyword = keywords.filter(key => key.keyword == keyword.keyword && key.adGroupId == keyword.adGroupId && key.matchType == keyword.matchType && key.keyword != '')
        if (duplicateKeyword.length > 1) {
          let property = this.setCellProperties(keyindex, 1);
          this.keywordCellSettings.push(property);
        }

        if (keyword.keyword != null && keyword.keyword != undefined && keyword.keyword != '') {
          if ((adgroups.length > 0 && !adgroups.includes(keyword.adGroupId))) {
            let property = this.setCellProperties(keyindex, 1);
            this.keywordInvalidAdgroupCellSettings.push(property);
          }
          validKeywords.push({
            keyword: keyword.keyword,
            matchType: keyword.matchType,
            bid: keyword.bid,
            adGroupId: keyword.adGroupId
          })
        }
      })
      return validKeywords;
    },
    validateNegativeKeywords (negativeKeywords) {
      let validNegtaiveKeywords = [];
      let adgroups = this.adgroupData.filter(a => a.amsTargetType == 'Keyword Targeting').map(a => a.adGroup);
      negativeKeywords.forEach((negativeKey, nkeyIndex) => {
        let duplicatenegativeKeyword = negativeKeywords.filter(negative => negative.adGroupIdNK == negativeKey.adGroupIdNK && negative.negativeKeyword == negativeKey.negativeKeyword && negativeKey.negativeKeyword != '');
        if (duplicatenegativeKeyword.length > 1) {
          let property = this.setCellProperties(nkeyIndex, 1);
          this.negativeKeywordCellSettings.push(property);
        }
        if (negativeKey.negativeKeyword != null && negativeKey.negativeKeyword != undefined && negativeKey.negativeKeyword != '') {
          if ((adgroups.length > 0 && !adgroups.includes(negativeKey.adGroupIdNK))) {
            let property = this.setCellProperties(nkeyIndex, 1);
            this.negativeKeywordInvalidAdgroupCellSettings.push(property);
          }
          validNegtaiveKeywords.push({
            negativeKeyword: negativeKey.negativeKeyword,
            negativeMatchType: negativeKey.negativeMatchType,
            adGroupIdNK: negativeKey.adGroupIdNK
          })
        }
      })
      return validNegtaiveKeywords;
    },
    validateProducts (product) {
      let products = [];
      let adgroups = this.adgroupData.filter(a => a.amsTargetType == 'Product Targeting').map(a => a.adGroup);
      product.forEach((asinDetails, asinIndex) => {
        let duplicateProduct = product.filter(asin => asin.asin == asinDetails.asin && asin.productAdGroupId == asinDetails.productAdGroupId && asin.asin != '');
        if (duplicateProduct.length > 1) {
          let property = this.setCellProperties(asinIndex, 1);
          this.productCellSettings.push(property);
        }
        if (asinDetails.asin != null && asinDetails.asin != undefined && asinDetails.asin != '') {
          if ((adgroups.length > 0 && !adgroups.includes(asinDetails.productAdGroupId))) {
            let property = this.setCellProperties(asinIndex, 1);
            this.productInvalidAdgroupCellSettings.push(property);
          }
          if (asinDetails[1] == undefined) {
            products.push({
              asin: asinDetails.asin,
              productBid: asinDetails.productBid,
              productAdGroupId: asinDetails.productAdGroupId
            })
          } else {
            let categoryInfo = this.parseJson(asinDetails.asin)
            products.push({
              asin: categoryInfo.categoryName,
              productBid: asinDetails.productBid,
              categoryId: asinDetails[1],
              productAdGroupId: asinDetails.productAdGroupId
            })
          }
        }
      })
      return products;
    },
    validateNegativeProducts (negativeProduct) {
      let negativeAsinData = [];
      let adgroups = this.adgroupData.filter(a => a.amsTargetType == 'Product Targeting').map(a => a.adGroup);
      negativeProduct.forEach((negativeAsin, negativeIndex) => {
        let duplicateNegativeProduct = negativeProduct.filter(asin => asin.excludedAsins == negativeAsin.excludedAsins && asin.adGroupIdProductNK == negativeAsin.adGroupIdProductNK && asin.excludedAsins != '');
        if (duplicateNegativeProduct.length > 1) {
          let property = this.setCellProperties(negativeIndex, 1);
          this.negativeProductCellSettings.push(property);
        }
        if (negativeAsin.excludedAsins != null && negativeAsin.excludedAsins != undefined && negativeAsin.excludedAsins != '') {
          if ((adgroups.length > 0 && !adgroups.includes(negativeAsin.adGroupIdProductNK))) {
            let property = this.setCellProperties(negativeIndex, 1);
            this.negativeProductInvalidAdgroupCellSettings.push(property);
          }
          negativeAsinData.push({
            excludedAsins: negativeAsin.excludedAsins,
            adGroupIdProductNK: negativeAsin.adGroupIdProductNK
          })
        }
      })
      return negativeAsinData;
    },
    updateColumnConfig () {
      if (this.sptab == "AdGroups") {
        this.getColumnConfig(this.sptab);
        this.adgroupSettings = this.buildHotSettings(0);
      } else if (this.sptab == "Keyword Targeting") {
        this.getColumnConfig(this.sptab);
        this.keywordSettings = this.buildHotSettings(0);
        this.negativeKeywordSettings = this.buildHotSettings(1);
      } else if (this.sptab == "Product Targeting") {
        this.getColumnConfig(this.sptab);
        this.productSettings = this.buildHotSettings(0);
        this.negativeProductSettings = this.buildHotSettings(1);
      } else if (this.sptab == "Targeting") {
        this.getColumnConfig(this.sptab);
        this.autoTargetingSettings = this.buildHotSettings(0);
        this.negativeProductSettings = this.buildHotSettings(1);
        this.negativeKeywordSettings = this.buildHotSettings(2);
      }
    },
    getColumnConfig (tab, bidIcon = '') {
      if (tab == "AdGroups") {
        this.allcolumns = columnConfig(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
            .config
        );
        this.displayColHeaders = displayHeaderNameLookup(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
            .config
        );
      } else if (tab == "Keyword Targeting") {
        this.allcolumns = columnConfigSpKeywordTarget(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
            .config
        );
        this.displayColHeaders = displayHeaderNameLookupSpKeywordTarget(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
            .config
        );
        this.displayColHeaders["HOT_SuggestedBid"] = bidIcon != '' ? bidIcon : "Suggested Bid (" + this.bpCurrencySymbol + ") <button onclick = 'updateKeywordBidValues()'><i class='fa fa-refresh' aria-hidden='true'></i></button>"
      } else if (tab == "Product Targeting") {
        this.allcolumns = columnConfigSpProductTarget(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
            .config
        );
        this.displayColHeaders = displayHeaderNameLookupSpProductTarget(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
            .config
        );
        this.displayColHeaders["HOT_SuggestedBid"] = bidIcon != '' ? bidIcon : "Suggested Bid (" + this.bpCurrencySymbol + ") <button onclick = 'updateProductBidValues()'><i class='fa fa-refresh' aria-hidden='true'></i></button>"
      } else if (tab == "Targeting") {
        this.allcolumns = columnConfigSpAutoTarget(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
            .config
        );
        this.displayColHeaders = displayHeaderNameLookupSpAutoTarget(
          this.campaignLauncherConfigs[this.selectedCampaignLauncherConfigId]
            .config
        );
      }
    },
    updateInvalidCellProperties (cellSettings, tableId, message) {
      let keywordUpdateSettingsConfig = {
        cell: cellSettings
      }
      this.$refs[tableId].hotInstance.updateSettings(keywordUpdateSettingsConfig);
      if (message.length > 0) {
        this.$Message.error({
          background: true,
          content: message
        });
      }
    },
    setCellProperties (index, colIndex) {
      let cellProperties = {
        row: index,
        col: colIndex,
        className: 'error-cell-text'
      }
      return cellProperties
    },
    setValidCellProperties (index, colIndex) {
      let cellProperties = {
        row: index,
        col: colIndex,
        className: ''
      }
      return cellProperties
    },
    validateTableData () {
      let self = this;
      let adgroup = [];
      let keywords = [];
      let negativeKeyword = [];
      let product = [];
      let negativeProduct = [];
      this.keywordCellSettings = [];
      this.negativeKeywordCellSettings = [];
      this.keywordInvalidAdgroupCellSettings = [];
      this.negativeKeywordInvalidAdgroupCellSettings = [];
      this.productInvalidAdgroupCellSettings = [];
      this.negativeProductInvalidAdgroupCellSettings = [];
      this.productCellSettings = [];
      this.negativeProductCellSettings = [];
      let adgroupCellSettings = [];
      let count = 0;
      adgroup = this.adsData;
      keywords = this.validateKeywords(this.keywordTablesData.keyword);
      negativeKeyword = this.validateNegativeKeywords(this.keywordTablesData.negativeKeyword);
      product = this.validateProducts(this.productTablesData.asin);
      negativeProduct = this.validateNegativeProducts(this.productTablesData.negativeAsin);
      if (this.keywordCellSettings.length > 0 || this.negativeKeywordCellSettings.length > 0 || this.productCellSettings.length > 0 || this.negativeProductCellSettings.length > 0 || this.keywordInvalidAdgroupCellSettings.length > 0 || this.negativeKeywordInvalidAdgroupCellSettings.length > 0 || this.productInvalidAdgroupCellSettings.length > 0 || this.negativeProductInvalidAdgroupCellSettings.length > 0) {
        count++;
      }
      self.adgroupData = [];
      var sheetData = {};
      sheetData.Adgroup = [];
      sheetData.keywords = keywords;
      sheetData.negativeKeywords = negativeKeyword;
      sheetData.products = product;
      sheetData.negativeProducts = negativeProduct;
      adgroup.forEach((adgrp, index) => {
        let keywordData = [];
        let negativeKeywordData = [];
        let asin = [];
        let category = [];
        let negativeAsinData = [];

        if (adgrp.adGroup != '' && adgrp.adGroup != undefined && adgrp.adGroup != null) {
          let duplicateAdgroups = adgroup.filter(adGroup => adGroup.adGroup == adgrp.adGroup && adGroup.campaign == adgrp.campaign);
          if (duplicateAdgroups.length > 1) {
            count++;
            let property = this.setCellProperties(index, 2);
            adgroupCellSettings.push(property);
          }
          self.adgroupData.push({
            campaign: adgrp.campaign,
            cluster: adgrp.cluster,
            adGroup: adgrp.adGroup,
            amsTargetType: adgrp.amsTargetType,
            defaultbid: adgrp.defaultbid
          })
          keywords.forEach((keyword, keyindex) => {
            if (keyword.adGroupId == adgrp.adGroup) {
              keywordData.push({
                Keyword: keyword.keyword,
                MatchType: keyword.matchType,
                Bid: keyword.bid
              })
            }
          })
          negativeKeyword.forEach((negativeKey, nkeyIndex) => {
            if (negativeKey.adGroupIdNK == adgrp.adGroup) {
              negativeKeywordData.push({
                NegativeKeywords: negativeKey.negativeKeyword,
                MatchType: negativeKey.negativeMatchType
              })
            }
          })
          product.forEach((asinDetails, asinIndex) => {
            if (asinDetails.productAdGroupId == adgrp.adGroup) {
              if (asinDetails.categoryId == undefined) {
                asin.push({
                  AsinsToTarget: asinDetails.asin,
                  Bid: asinDetails.productBid
                })
              } else {
                category.push({
                  CategoriesToTarget: asinDetails.asin,
                  Bid: asinDetails.productBid,
                  categoryId: asinDetails.categoryId
                })
              }
            }
          })
          negativeProduct.forEach((negativeAsin, negativeIndex) => {
            if (negativeAsin.adGroupIdProductNK == adgrp.adGroup) {
              negativeAsinData.push(negativeAsin.excludedAsins)
            }
          })
          adgrp.productsToTargets = asin;
          adgrp.negativeAsins = negativeAsinData;
          adgrp.categoryToTarget = category;
          adgrp.keyword = keywordData;
          adgrp.negativeKeyword = negativeKeywordData;
          sheetData.Adgroup.push(adgrp);
        }
      });
      if (adgroupCellSettings.length > 0 && this.$refs['hot1'] != undefined) {
        this.updateInvalidCellProperties(adgroupCellSettings, 'hot1', 'Adgroup Name already exists')
      }
      if (this.$refs["hot2"] !== undefined && this.keywordCellSettings.length > 0) {
        this.updateInvalidCellProperties(this.keywordCellSettings, 'hot2', 'Keyword Target for Adgroup already exists')
      }
      if (this.$refs["hot2"] !== undefined && this.keywordInvalidAdgroupCellSettings.length > 0) {
        this.updateInvalidCellProperties(this.keywordInvalidAdgroupCellSettings, 'hot2', 'Invalid Adgroup selected for Keyword Target')
      }
      if (this.$refs["hot3"] !== undefined && this.negativeKeywordCellSettings.length > 0) {
        this.updateInvalidCellProperties(this.negativeKeywordCellSettings, 'hot3', 'Negative Keyword Target for Adgroup already exists')
      }
      if (this.$refs["hot3"] !== undefined && this.negativeKeywordInvalidAdgroupCellSettings.length > 0) {
        this.updateInvalidCellProperties(this.negativeKeywordInvalidAdgroupCellSettings, 'hot3', 'Invalid Adgroup selected for Negative Keyword Target')
      }
      if (this.$refs["hot4"] !== undefined && this.productCellSettings.length > 0) {
        this.updateInvalidCellProperties(this.productCellSettings, 'hot4', 'ASIN Target for Adgroup already exists')
      }
      if (this.$refs["hot4"] !== undefined && this.productInvalidAdgroupCellSettings.length > 0) {
        this.updateInvalidCellProperties(this.productInvalidAdgroupCellSettings, 'hot4', 'Invalid Adgroup selected for ASIN Target')
      }
      if (this.$refs["hot5"] !== undefined && this.negativeProductCellSettings.length > 0) {
        this.updateInvalidCellProperties(this.negativeProductCellSettings, 'hot5', 'Negative ASIN Target for Adgroup already exists')
      }
      if (this.$refs["hot5"] !== undefined && this.negativeProductInvalidAdgroupCellSettings.length > 0) {
        this.updateInvalidCellProperties(this.negativeProductInvalidAdgroupCellSettings, 'hot5', 'Invalid Adgroup selected for Negative ASIN Target')
      }
      if (count > 0 || this.amsSpError.length > 0) {
        return false;
      } else {
        this.set_adsData(sheetData.Adgroup);
        this.set_amsSheetData(sheetData);
        return true;
      }
    },
    saveTableData () {
      let isValid = this.validateTableData();
      if (isValid && this.entitiesData.amsEntities != undefined) {
        this.$store.commit("set_AmsDataValid", true);
        this.$store.commit("set_AmsLoadNext", false);
      } else if (!isValid) {
        this.$store.commit("set_AmsDataValid", false);
      }
    },
    processTableData () {
      let adgroupCount = 0;
      this.$store.commit("set_AmsDataValid", true);
      if (this.entitiesData.amsEntities != undefined) {
        this.entitiesData.amsEntities.forEach(entity => {
          let adgroupDetails = entity.childEntities;
          entity.childEntities = [];
          let clusterDetail = [];
          this.adsData.forEach(adgroup => {
            let clusterInfo = this.clusterDetails.filter(cluster => cluster.id == adgroup.cluster);
            if (clusterInfo.length > 0) {
              clusterDetail = clusterInfo[0];
            }
            var exportedAdgroup = adgroupDetails.filter(obj => obj.name == adgroup.adGroup);
            let isAdgroupexported = 0;
            if (exportedAdgroup != null && exportedAdgroup != undefined && exportedAdgroup.length > 0) {
              if (entity.name == adgroup.campaign && exportedAdgroup[0].status == exportStatus.Success) {
                entity.childEntities.push(exportedAdgroup[0]);
                adgroupCount++;
                isAdgroupexported = 1;
              }
            }
            if (entity.name == adgroup.campaign && isAdgroupexported == 0) {
              adgroupCount++;
              entity.childEntities.push({
                entityType: 2,
                accountId: "",
                name: adgroup.adGroup,
                status: adgroup.status,
                id: 0,
                failureReason: "",
                defaultBid: adgroup.defaultbid,
                amsTargetType: adgroup.amsTargetType == 'Keyword Targeting' ? this.targetingType["Keyword Targeting"] : this.targetingType["Product Targeting"],
                childEntities: clusterDetail.child,
                additionalData: {
                  cluster: clusterDetail.cluster,
                  negativeKeywords: adgroup.negativeKeyword || [],
                  productsToTargets: adgroup.productsToTargets || [],
                  categories: adgroup.categoryToTarget || [],
                  keywordsBidInfo: adgroup.keyword || [],
                  negativeAsins: adgroup.negativeAsins || []
                }
              })
            }
          })
        })
      }
      if (this.adsData.length == adgroupCount) {
        this.set_tableData(JSON.stringify(this.entitiesData));
        this.set_amsEntitiesResponse(this.entitiesData);
      }
    },
    getColumnsData (colHeaders) {
      var self = this;
      let campaignData = [];
      let clusters = [];
      let campaigns = this.adsData.map(adgroup => adgroup.campaign);
      campaigns.forEach(campaign => {
        if (campaign && !campaignData.includes(campaign) && campaign != '') {
          campaignData.push(campaign)
        }
      })
      let clusterData = this.adsData.map(adgroup => adgroup.cluster);
      clusterData.forEach(cluster => {
        if (!clusters.includes(cluster) && cluster != '') {
          clusters.push(cluster)
        }
      })
      let displayColHeaders = colHeaders.map(
        header => this.displayColHeaders[header]
      );
      var displayNames = [];
      var columns = [];
      for (var ind = 0; ind < colHeaders.length; ind++) {
        var header = colHeaders[ind];
        var displayName = displayColHeaders[ind];
        var displayText = displayName;
        if (this.requiredCols.base.has(header)) {
          displayText += "<span style='color:#808080'>*</span>";
        }
        displayNames.push(displayText);
        let settings = {};
        if (colHeaders[ind] == "HOT_Campaigns") {
          settings.type = "dropdown";
          settings.data = "campaign";
          this.tabledata = this.adgroupData;
          settings.source = campaignData
        } else if (colHeaders[ind] == "HOT_Clusters") {
          settings.type = "dropdown";
          settings.data = "cluster";
          settings.source = clusters;
          settings.renderer = this.clusterRenderer;
        } else if (colHeaders[ind] == "HOT_Preview") {
          settings.readOnly = true;
          settings.copyable = false;
          settings.editor = false;
          settings.renderer = this.previewBtnRenderer;
        } else if (colHeaders[ind] == "HOT_Adgroup") {
          this.minSpareRows = 0;
          this.colWidths = [100, 200, 200, 200, 200, 100, 100]
          settings.type = "text";
          settings.data = "adGroup";
          this.cellProperties = [{
            row: 0,
            col: 0,
            readOnly: true
          }]
        } else if (colHeaders[ind] == "HOT_AdgroupIds" || colHeaders[ind] == "HOT_ProductAdgroupIds" || colHeaders[ind] == "HOT_NKAdgroupIds" || colHeaders[ind] == "HOT_NKProductAdgroupIds") {
          settings.type = 'dropdown'
          settings.allowInvalid = false
          settings.source = colHeaders[ind] == "HOT_AdgroupIds" || colHeaders[ind] == "HOT_NKAdgroupIds" ? this.adsData.filter(adgrp => adgrp.amsTargetType == 'Keyword Targeting' && adgrp.status != exportStatus.Success).map(x => x.adGroup) : this.adsData.filter(adgrp => adgrp.amsTargetType == 'Product Targeting' && adgrp.status != exportStatus.Success).map(x => x.adGroup);
          settings.data = colHeaders[ind] == "HOT_AdgroupIds" ? "adGroupId"
            : colHeaders[ind] == "HOT_ProductAdgroupIds" ? "productAdGroupId"
              : colHeaders[ind] == "HOT_NKAdgroupIds" ? "adGroupIdNK" : "adGroupIdProductNK";
        } else if (colHeaders[ind] == "HOT_Keyword") {
          this.minSpareRows = 1;
          this.tabledata = this.keywordData;
          settings.type = "text";
          settings.data = "keyword";
          this.width = 900;
          this.colWidths = [200, 150, 100, 50, 80, 80, 150];
        } else if (colHeaders[ind] == "HOT_DefaultBid") {
          settings.type = "numeric";
          settings.numericFormat = {
            pattern: "0,0.00"
          };
          settings.manualColumnFreeze = true;
          settings.data = "defaultbid";
        } else if (colHeaders[ind] == "HOT_Targets") {
          settings.type = "text";
          settings.data = "targets";
          settings.minSpareRows = 1;
          settings.readOnly = true;
          settings.className = 'disabled-cell-text';
        } else if (colHeaders[ind] == "HOT_Targeting") {
          settings.type = 'dropdown'
          settings.source = ["Product Targeting", "Keyword Targeting"]
          settings.allowInvalid = false
          settings.data = "amsTargetType";
        } else if (colHeaders[ind] == "HOT_NegativeKeyword") {
          this.colWidths = [200, 200, 100]
          settings.data = "negativeKeyword";
          this.width = 600;
        } else if (colHeaders[ind] == "HOT_MatchType") {
          settings.type = 'dropdown'
          settings.source = ["Broad", "Exact", "Phrase"]
          settings.allowInvalid = false
          settings.data = "matchType"
        } else if (colHeaders[ind] == "HOT_NegativeMatchType") {
          settings.type = 'dropdown'
          settings.source = ["NegativeExact", "NegativePhrase"]
          settings.allowInvalid = false
          settings.data = "negativeMatchType"
          this.tabledata = this.negativeKeywordData;
        } else if (colHeaders[ind] == "HOT_Bid" || colHeaders[ind] == 'HOT_ProductBid') {
          settings.type = 'numeric'
          settings.numericFormat = {
            pattern: '0,0.00'
          }
          settings.manualColumnFreeze = true;
          settings.data = colHeaders[ind] == "HOT_Bid" ? 'bid' : 'productBid';
        } else if (colHeaders[ind] == "HOT_SuggestedBid") {
          settings.readOnly = true;
          settings.className = 'disabled-cell-text';
          settings.type = 'numeric'
          settings.numericFormat = {
            pattern: '0,0.00'
          }
          settings.data = 'suggestedBid'
        } else if (colHeaders[ind] == "HOT_HighBid") {
          settings.readOnly = true;
          settings.className = 'disabled-cell-text';
          settings.data = 'highBid'
        } else if (colHeaders[ind] == "HOT_LowBid") {
          settings.readOnly = true;
          settings.className = 'disabled-cell-text';
          settings.data = 'lowBid'
        } else if (colHeaders[ind] == "HOT_CloseMatchBid") {
          settings.type = 'numeric'
          settings.numericFormat = {
            pattern: '0,0.00'
          }
        } else if (colHeaders[ind] == "HOT_LooseMatchBid") {
          settings.type = 'numeric'
          settings.numericFormat = {
            pattern: '0,0.00'
          }
        } else if (colHeaders[ind] == "HOT_SubsitituteBid") {
          settings.type = 'numeric'
          settings.numericFormat = {
            pattern: '0,0.00'
          }
        } else if (colHeaders[ind] == "HOT_ComplementBid") {
          this.width = 580;
          this.colWidths = [100, 120, 120, 120, 120];
          settings.type = 'numeric'
          settings.numericFormat = {
            pattern: '0,0.00'
          }
        } else if (colHeaders[ind] == "HOT_Asin") {
          const alphaNumericValidateRegex = /^((?=.*[A-Z])(?=.*[0-9])[A-Z0-9]+)+$/;
          settings.data = "asin";
          this.tabledata = this.productData;
          this.colWidths = [200, 200, 50, 80, 80, 150];
          this.width = 850;
          settings.type = "text";
          settings.renderer = this.categoryRenderer;
          this.minSpareRows = 1;
          settings.validator = function (value, callBack) {
            if (value != "") {
              let categoryObject = self.parseJson(value);
              value = Handsontable.helper.stringify(value);
              var values = value.split(",");
              for (var data = 0; data < values.length; data++) {
                if (alphaNumericValidateRegex.test(values[data]) && values[data].length == 10) {
                  callBack(true);
                } else if (categoryObject.amsCategoryId != undefined) {
                  callBack(true);
                } else {
                  self.$Message.error({
                    background: true,
                    content:
                      "Invalid Input. ASIN should be a 10 digit uppercase Alpha-numeric.\n Categories can be pasted from the Assets Pane"
                  });
                  callBack(false);
                }
              }
            } else {
              callBack(true);
            }
          };
        } else if (colHeaders[ind] == "HOT_ExcludedAsins") {
          const alphaNumericValidateRegex = /^((?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+)+$/;
          this.colWidths = [200, 200];
          this.width = 500;
          this.minSpareRows = 1;
          settings.data = "excludedAsins";
          settings.type = "text";
          settings.validator = function (value, callBack) {
            if (value == "") {
              callBack(true);
            } else if (
              alphaNumericValidateRegex.test(value) &&
              value.length == 10
            ) {
              callBack(true);
            } else {
              self.$Message.error({
                background: true,
                content:
                  "Excluded ASINs" +
                  " must only contain a combination alpha-numeric values (Eg, 0, A, a) with 10 characters"
              });
              callBack(false);
            }
          };
        }
        columns.push(settings);
      }
      this.columns = columns;
      this.colHeaders = displayNames;
    },
    clusterRenderer (instance, td, row, col, prop, value, cellProperties) {
      var failureCount = 0;
      if (this.entitiesData.amsEntities !== undefined && this.adgroupData !== undefined) {
        this.entitiesData.amsEntities.forEach(campaign => {
          campaign.childEntities.forEach(adgroup => {
            if (adgroup.name === this.adgroupData[row].adGroup) {
              adgroup.childEntities.forEach(ad => {
                if (ad.failureReason != null) {
                  failureCount++;
                }
              })
            }
          })
        });
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        if (failureCount != 0) {
          td.innerHTML = `${td.innerHTML} <i class="fa fa-exclamation-triangle red" style="color:red" data-toggle="tooltip" title="${failureCount} product(s) from this cluster don't have SKU id. Ad launches for these products will not be supported"></i>`;
        }
      }
    },
    CreatePreview (row) {
      let previewIcon = document.getElementById("previewCount" + row);
      let eyeIcon = document.getElementsByClassName("eye preivew-icon-span ivu-poptip-rel");
      this.showPreview = !this.showPreview;
      let ads = [];
      if (this.$refs["hot1"] !== undefined) {
        let asin = this.clusterDetails[row].cluster.amsCluster.asinsToAdvertise[0].toString();
        ads = this.adsDetails.filter(x => x.asin == asin)[0];
        if (ads == undefined || ads == null) {
          this.FetchProductsData(this.clusterDetails[row].cluster.amsCluster.asinsToAdvertise[0].toString());
          ads = this.adsDetails.filter(x => x.asin == asin)[0];
        }
        if (ads && ads.asin !== null && ads.asin !== undefined && ads.asin !== "") {
          this.previewProductTitle = ads.title;
          this.previewProductImage = ads.imageLink;
        }
        let customImageCreative = "https://production-cb-api.mediabuy.pro/t/1741?variant=Default&scaleFactor=0.7&format=png&ptype=n&xs=PBga59fKW5&Headline=text-";
        if (this.previewProductTitle === undefined || this.previewProductTitle === null) {
          customImageCreative += "&Description=text-";
        } else {
          customImageCreative += "&Description=text-" + encodeURI(this.previewProductTitle);
        } if (this.previewProductImage !== undefined && this.previewProductImage !== null && this.previewProductImage !== "") {
          customImageCreative += "&Product=src-" + encodeURI(this.previewProductImage);
        }
        previewIcon.innerHTML = "<div class='ivu-poptip-content' style='position:fixed'><div class='ivu-poptip-arrow' style='top:5% !important'></div> <!----> <div class='ivu-poptip-inner'><div class='ivu-poptip-body'><div class='ivu-poptip-body-content'><div class='ivu-poptip-body-content-inner'><div style='display:flex'><div span='11' style='padding-right:1%'><img src='" + customImageCreative + "' style='width: 100%;height: 100%;'></div></div></div></div></div></div></div>";
      }
      if (this.showPreview) {
        previewIcon.style.visibility = "visible";
        for (let i = 0; i < eyeIcon.length; i++) {
          if (i != row) {
            eyeIcon[i].style.pointerEvents = "none";
            eyeIcon[i].style.opacity = "0.5";
          }
        }
      } else {
        previewIcon.style.visibility = "hidden";
        for (let i = 0; i < eyeIcon.length; i++) {
          eyeIcon[i].style.pointerEvents = "auto";
          eyeIcon[i].style.opacity = "1";
        }
      }
    },
    previewBtnRenderer (instance, td, row, col, prop, value, cellProperties) {
      let paddingValue = 25;
      if (row != 0) {
        paddingValue = paddingValue * (row + 1);
      }
      if (!td.firstElementChild) {
        let previewTdWrapper = document.createElement("div");
        previewTdWrapper.classList.add("preview-td-wrapper", "ivu-poptip");
        let previewIcon = document.createElement("span");
        previewIcon.classList.add("eye", "preivew-icon-span", "ivu-poptip-rel");
        previewIcon.innerHTML = '<i class="fas fa-eye preview-icon"></i>';
        let previewContent = document.createElement("div");
        previewContent.classList.add("ivu-poptip-popper");
        previewContent.style.position = "absolute";
        previewContent.style.willChange = "top,left";
        previewContent.style.top = paddingValue + "%";
        previewContent.style.left = "60px";
        previewContent.setAttribute("x-placement", "right")
        previewContent.id = "previewCount" + row;
        previewIcon.onclick = () => {
          this.CreatePreview(row);
        };
        previewTdWrapper.appendChild(previewIcon);
        previewTdWrapper.appendChild(previewContent);
        td.appendChild(previewTdWrapper);
      }
      return td;
    },
    categoryRenderer (instance, td, row, col, prop, value, cellProperties) {
      let categoryObject = this.parseJson(value);
      if (categoryObject != null && categoryObject.categoryName != undefined) {
        arguments[5] = categoryObject.categoryName;
        this.productTablesData.asin[row][col] = categoryObject.amsCategoryId;
        this.set_productTableData(this.productTablesData);
        Handsontable.renderers.TextRenderer.apply(this, arguments);
      } else {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        if (this.productTablesData.asin[row] != undefined && this.productTablesData.asin[row][col] != undefined) {
          this.productTablesData.asin[row][col] = value;
          this.set_productTableData(this.productTablesData);
        }
      }
    },
    updateAdgroupData (row) {
      let self = this;
      self.adgroupData[row].amsTargetType = self.adgroupData[row].amsTargetType == undefined ? 'Product Targeting' : self.adgroupData[row].amsTargetType;
      self.adgroupData[row].campaign = self.adgroupData[row].campaign == undefined ? self.adgroupData[0].campaign : self.adgroupData[row].campaign;
      self.adgroupData[row].cluster = self.adgroupData[row].cluster == undefined ? self.adgroupData[0].cluster : self.adgroupData[row].cluster;
      self.adgroupData[row].defaultbid = self.adgroupData[row].defaultbid == undefined ? 1.00 : self.adgroupData[row].defaultbid;
    },
    updateKeywordData (row) {
      let self = this;
      let keywordTargetingAdgroup = self.adsData.filter(adgroup => adgroup.amsTargetType == 'Keyword Targeting').length > 0 ? self.adsData.filter(adgroup => adgroup.amsTargetType == 'Keyword Targeting')[0] : '';
      self.keywordTableData[row].adGroupId = self.keywordTableData[row].adGroupId == null ? keywordTargetingAdgroup.adGroup : self.keywordTableData[row].adGroupId;
      self.keywordTableData[row].matchType = self.keywordTableData[row].matchType == null ? 'Broad' : self.keywordTableData[row].matchType;
      self.keywordTableData[row].bid = self.keywordTableData[row].bid == null ? keywordTargetingAdgroup.defaultbid : self.keywordTableData[row].bid;
    },
    updateNegativeKeywordData (row) {
      let self = this;
      let negativeKeywordTargetingAdgroup = self.adsData.filter(adgroup => adgroup.amsTargetType == 'Keyword Targeting').length > 0 ? self.adsData.filter(adgroup => adgroup.amsTargetType == 'Keyword Targeting')[0].adGroup : '';
      self.negativeKeywordTableData[row].adGroupIdNK = self.negativeKeywordTableData[row].adGroupIdNK == null ? negativeKeywordTargetingAdgroup : self.negativeKeywordTableData[row].adGroupIdNK;
      self.negativeKeywordTableData[row].negativeMatchType = self.negativeKeywordTableData[row].negativeMatchType == null ? 'NegativePhrase' : self.negativeKeywordTableData[row].negativeMatchType;
    },
    updateProductData (row) {
      let self = this;
      let productTargetingAdgroup = self.adsData.filter(adgroup => adgroup.amsTargetType == 'Product Targeting' && adgroup.status != exportStatus.Success).length > 0 ? self.adsData.filter(adgroup => adgroup.amsTargetType == 'Product Targeting' && adgroup.status != exportStatus.Success)[0] : '';
      self.productTableData[row].productAdGroupId = (self.productTableData[row].productAdGroupId == null && !!productTargetingAdgroup) ? productTargetingAdgroup.adGroup : self.productTableData[row].productAdGroupId;
      self.productTableData[row].productBid = self.productTableData[row].productBid == null ? productTargetingAdgroup.defaultbid : self.productTableData[row].productBid;
    },
    buildHotSettings (tableType) {
      var self = this;
      let column = this.allcolumns[tableType];
      let colHeaders = column["base"];
      this.requiredCols = {
        base: new Set(column["requiredBase"]),
        card: new Set(column["requiredCard"]),
        baseLength: column["base"].length,
        cardLength: column["card"].length
      };

      this.getColumnsData(colHeaders);
      return {
        startCols: colHeaders.length,
        startRows: 1,
        manualColumnResize: true,
        autoRowSize: true,
        autoColumnSize: true,
        minSpareRows: this.minSpareRows,
        colHeaders: this.colHeaders,
        rowHeights: this.rowHeights,
        height: 500,
        width: this.width,
        data: this.tabledata,
        colWidths: this.colWidths,
        cell: this.cellProperties,
        columns: this.columns,
        maxRows: this.maxRows,
        cells: (row, col, prop) => {
          let cellProperties = {}
          if (this.sptab === 'AdGroups' && row < self.adgroupDataLength && (this.adgroupData[row].status == exportStatus.Success || this.adgroupData[row].status == exportStatus.Failed)) {
            cellProperties.className = 'disabled-cell-text'
            cellProperties.readOnly = 'true'
          }
          if (self.sptab === 'Keyword Targeting' && colHeaders.length == 7) {
            if (this.adgroupSpecificKeywords !== undefined && this.adgroupSpecificKeywords !== null && this.adgroupSpecificKeywords.length > 0) {
              let keywordAdGroup = this.adgroupSpecificKeywords[row];
              if (keywordAdGroup !== undefined && keywordAdGroup !== null) {
                keywordAdGroup = this.adgroupDetails.filter(data => data.adGroup == keywordAdGroup.adGroupId);
              }
              if (keywordAdGroup !== undefined && keywordAdGroup !== null && keywordAdGroup.length > 0) {
                if (keywordAdGroup[0].status === exportStatus.Success) {
                  cellProperties.className = 'disabled-cell-text'
                  cellProperties.readOnly = 'true'
                }
              }
            }
          }

          if (this.sptab === 'Keyword Targeting' && colHeaders.length == 3) {
            if (this.adgroupSpecificNegativeKeywords !== undefined && this.adgroupSpecificNegativeKeywords !== null && this.adgroupSpecificNegativeKeywords.length > 0) {
              let negativeKeywordAdgroup = this.adgroupSpecificNegativeKeywords[row];
              if (negativeKeywordAdgroup !== undefined && negativeKeywordAdgroup !== null) {
                negativeKeywordAdgroup = this.adgroupDetails.filter(data => data.adGroup == negativeKeywordAdgroup.adGroupIdNK);
              }
              if (negativeKeywordAdgroup !== undefined && negativeKeywordAdgroup !== null && negativeKeywordAdgroup.length > 0) {
                if (negativeKeywordAdgroup[0].status === exportStatus.Success) {
                  cellProperties.className = 'disabled-cell-text'
                  cellProperties.readOnly = 'true'
                }
              }
            }
          }

          if (this.sptab === 'Product Targeting' && colHeaders.length == 6) {
            if (this.adgroupSpecificAsins !== undefined && this.adgroupSpecificAsins !== null && this.adgroupSpecificAsins.length > 0) {
              let asinAdGroup = this.adgroupSpecificAsins[row];
              if (asinAdGroup !== undefined && asinAdGroup !== null) {
                asinAdGroup = this.adgroupDetails.filter(data => data.adGroup == asinAdGroup.productAdGroupId);
              }
              if (asinAdGroup !== undefined && asinAdGroup !== null && asinAdGroup.length > 0) {
                if (asinAdGroup[0].status === exportStatus.Success) {
                  cellProperties.className = 'disabled-cell-text'
                  cellProperties.readOnly = 'true'
                }
              }
            }
          }

          if (this.sptab === 'Product Targeting' && colHeaders.length == 2) {
            if (this.adgroupSpecificNegativeAsins !== undefined && this.adgroupSpecificNegativeAsins !== null && this.adgroupSpecificNegativeAsins.length > 0) {
              let negativeAsinAdGroup = this.adgroupSpecificNegativeAsins[row];
              if (negativeAsinAdGroup !== undefined && negativeAsinAdGroup !== null) {
                negativeAsinAdGroup = this.adgroupDetails.filter(data => data.adGroup == negativeAsinAdGroup.adGroupIdProductNK);
              }
              if (negativeAsinAdGroup !== undefined && negativeAsinAdGroup !== null && negativeAsinAdGroup.length > 0) {
                if (negativeAsinAdGroup[0].status === exportStatus.Success) {
                  cellProperties.className = 'disabled-cell-text'
                  cellProperties.readOnly = 'true'
                }
              }
            }
          }
          return cellProperties
        },
        afterValidate: (isValid, value, row, prop, source) => {
          if (!isValid) {
            self.error.push({
              Value: value,
              IsValid: isValid,
              Row: row,
              Column: prop
            });
            this.$store.commit("set_AmsSpError", self.error);
            this.$store.commit("set_AmsLoadNext", true);
          } else {
            var index = self.error.findIndex(e => e.Row == row && e.Column == prop);
            if (index != -1) {
              self.error.splice(index, 1);
            }
            this.$store.commit("set_AmsSpError", self.error);
          }
        },
        beforeChange: (changes, source) => {
          if (changes != undefined && changes != null) {
            changes.forEach(([row, prop, oldValue, newValue]) => {
              if (prop == 'adGroup') {
                if (row >= (self.adgroupData.length) - 1) {
                  if (self.$refs['hot1'].hotInstance.isEmptyRow(row) && self.adgroupData[row] != undefined) {
                    this.updateAdgroupData(row);
                  } else {
                    self.adgroupData.push({
                      amsTargetType: 'Product Targeting',
                      campaign: self.adgroupData[0].campaign,
                      cluster: self.adgroupData[0].cluster,
                      defaultbid: 1.00,
                      adGroup: '',
                      status: 3
                    });
                  }
                  self.adgroupData[row][prop] = newValue;
                  self.updateTableData(self.adgroupData, 'hot1');
                } else if ((self.adgroupData[row].campaign == null || self.adgroupData[row].cluster == null || self.adgroupData.amsTargetType == null || self.adgroupData.defaultbid == null)) {
                  this.updateAdgroupData(row);
                }
              } else if (prop == 'keyword') {
                if (row >= (self.keywordTableData.length - 1)) {
                  if (self.$refs['hot2'].hotInstance.isEmptyRow(row) && self.keywordTableData[row] != undefined) {
                    this.updateKeywordData(row);
                  } else {
                    let keywordTargetingAdgroup = self.adsData.filter(adgroup => adgroup.amsTargetType == 'Keyword Targeting').length > 0 ? self.adsData.filter(adgroup => adgroup.amsTargetType == 'Keyword Targeting')[0] : '';
                    self.keywordTableData.push({
                      matchType: 'Broad',
                      bid: keywordTargetingAdgroup.defaultbid,
                      adGroupId: keywordTargetingAdgroup.adGroup
                    });
                  }
                  self.keywordTableData[row][prop] = newValue
                } else if ((self.keywordTableData[row].adGroupId == null || self.keywordTableData[row].matchType == null || self.keywordTableData.bid == null)) {
                  this.updateKeywordData(row);
                }
              } else if (prop == 'negativeKeyword') {
                if (row >= (self.negativeKeywordTableData.length - 1)) {
                  if (self.$refs['hot3'].hotInstance.isEmptyRow(row) && self.negativeKeywordTableData[row] != undefined) {
                    this.updateNegativeKeywordData(row)
                  } else {
                    let negativeKeywordTargetingAdgroup = self.adsData.filter(adgroup => adgroup.amsTargetType == 'Keyword Targeting').length > 0 ? self.adsData.filter(adgroup => adgroup.amsTargetType == 'Keyword Targeting')[0].adGroup : '';
                    self.negativeKeywordTableData.push({
                      adGroupIdNK: negativeKeywordTargetingAdgroup,
                      negativeMatchType: 'NegativePhrase'
                    });
                  }
                  self.negativeKeywordTableData[row][prop] = newValue
                } else if ((self.negativeKeywordTableData[row].adGroupIdNK == null || self.negativeKeywordTableData[row].negativeMatchType == null)) {
                  this.updateNegativeKeywordData(row);
                }
              } else if (prop == 'asin') {
                if (row >= (self.productTableData.length - 1)) {
                  if (self.$refs['hot4'].hotInstance.isEmptyRow(row) && self.productTableData[row] != undefined) {
                    this.updateProductData(row);
                  } else {
                    let productTargetingAdgroup = self.adsData.filter(adgroup => adgroup.amsTargetType == 'Product Targeting').length > 0 ? self.adsData.filter(adgroup => adgroup.amsTargetType == 'Product Targeting')[0] : '';
                    self.productTableData.push({
                      productBid: productTargetingAdgroup.defaultbid,
                      productAdGroupId: productTargetingAdgroup.adGroup
                    });
                    let products = {
                      asin: self.productTableData,
                      negativeAsin: this.productTablesData.negativeAsin
                    }
                    this.set_productTableData(products);
                  }
                  self.productTableData[row][prop] = newValue
                } else if ((self.productTableData[row].productAdGroupId == null || self.productTableData[row].productBid == null)) {
                  this.updateProductData(row);
                }
              } else if (prop == 'excludedAsins') {
                let negativeProductTargetingAdgroup = self.adsData.filter(adgroup => adgroup.amsTargetType == 'Product Targeting' && adgroup.status != exportStatus.Success).length > 0 ? self.adsData.filter(adgroup => adgroup.amsTargetType == 'Product Targeting' && adgroup.status != exportStatus.Success)[0].adGroup : '';
                if (row >= (self.negativeProductTableData.length - 1)) {
                  if (self.$refs['hot5'].hotInstance.isEmptyRow(row) && self.negativeProductTableData[row] != undefined) {
                    self.negativeProductTableData[row].adGroupIdProductNK = negativeProductTargetingAdgroup;
                  } else {
                    self.negativeProductTableData.push({
                      adGroupIdProductNK: negativeProductTargetingAdgroup
                    });
                  }
                  self.negativeProductTableData[row][prop] = newValue
                } else if ((self.negativeProductTableData[row].adGroupIdProductNK == null)) {
                  self.negativeProductTableData[row].adGroupIdProductNK = negativeProductTargetingAdgroup;
                }
              }
            })
          }
        },
        afterChange: (changes, source) => {
          this.editedAdgroupId = '';
          let self = this;
          let adgroupCellSettings = [];
          if (changes != undefined && changes != null) {
            this.$store.commit("set_AmsLoadNext", true);
            changes.forEach(([row, prop, oldValue, newValue]) => {
              if (prop == 'campaign' || prop == 'cluster' || prop == 'adGroup' || prop == 'amsTargetType' || prop == 'defaultbid') {
                if (self.adgroupData.length > row) {
                  if (prop == 'adGroup') {
                    let duplicateAdgroups = self.adgroupData.filter(adGroup => adGroup.adGroup == newValue && adGroup.campaign == self.adgroupData[row].campaign);
                    if (duplicateAdgroups.length > 1) {
                      self.invalidAdgroupCells.push(row);
                      adgroupCellSettings.push(self.setCellProperties(row, 2));
                      this.updateInvalidCellProperties(adgroupCellSettings, 'hot1', 'Adgroup name already exists')
                    } else if (self.invalidAdgroupCells.includes(row)) {
                      var index = self.invalidAdgroupCells.indexOf(row);
                      self.invalidAdgroupCells.splice(index, 1);
                      adgroupCellSettings.push(self.setValidCellProperties(row, 2));
                      this.updateInvalidCellProperties(adgroupCellSettings, 'hot1', '')
                    }
                  }
                  if (prop == 'adGroup' && oldValue != null) {
                    self.keywordTablesData.keyword.filter(keyword => keyword.adGroupId == oldValue).forEach(key => {
                      key.adGroupId = newValue
                    })
                    self.keywordTablesData.negativeKeyword.filter(negativeKeyword => negativeKeyword.adGroupIdNK == oldValue).forEach(key => {
                      key.adGroupIdNK = newValue
                    })
                    self.productTablesData.asin.filter(product => product.productAdGroupId == oldValue).forEach(asin => {
                      asin.productAdGroupId = newValue
                    })
                    self.productTablesData.negativeAsin.filter(excludedAsin => excludedAsin.adGroupIdProductNK == oldValue).forEach(asin => {
                      asin.adGroupIdProductNK = newValue
                    })
                  }
                  if (prop == 'defaultbid' && oldValue != null) {
                    self.keywordTablesData.keyword.filter(keyword => keyword.adGroupId == self.adgroupData[row].adGroup).forEach(key => {
                      key.bid = newValue
                    })
                    self.productTablesData.asin.filter(product => product.productAdGroupId == self.adgroupData[row].adGroup).forEach(asin => {
                      asin.bid = newValue
                    })
                  }
                  self.adgroupData[row][prop] = newValue
                }
                let keywords = {
                  keyword: this.keywordTablesData.keyword,
                  negativeKeyword: this.keywordTablesData.negativeKeyword
                }
                let products = {
                  asin: this.productTablesData.asin,
                  negativeAsin: this.productTablesData.negativeAsin
                }
                this.set_productTableData(products);
                this.set_adsData(self.adgroupData);
                this.set_keywordTableData(keywords);
              } else if (prop == 'adGroupId' || prop == 'keyword' || prop == 'matchType' || prop == 'bid') {
                if (prop == 'adGroupId' && self.keywordTableData[row] != undefined && (newValue != '' && newValue != undefined && newValue != null)) {
                  let keywordTargetingAdgroup = self.adsData.filter(adgroup => adgroup.adGroup == newValue)[0];
                  self.keywordTableData[row].bid = keywordTargetingAdgroup.defaultbid;
                  this.updateTableData(self.keywordTableData, 'hot2')
                }
                self.keywordTableData = this.updateEditedData(self.keywordTableData, row, prop, newValue, 'adGroupId', self.adgroupsSelectedKeyword, this.editedAdgroupId);
                let keywords = {
                  keyword: self.keywordTableData,
                  negativeKeyword: this.keywordTablesData.negativeKeyword
                }
                this.set_keywordTableData(keywords);
              } else if (prop == 'adGroupIdNK' || prop == 'negativeKeyword' || prop == 'negativeMatchType') {
                self.negativeKeywordTableData = this.updateEditedData(self.negativeKeywordTableData, row, prop, newValue, 'adGroupIdNK', self.adgroupsSelectedKeyword, this.editedAdgroupId)
                let keywords = {
                  keyword: this.keywordTablesData.keyword,
                  negativeKeyword: self.negativeKeywordTableData
                }
                this.set_keywordTableData(keywords);
              } else if (prop == 'productAdGroupId' || prop == 'asin' || prop == 'productBid') {
                if (prop == 'productAdGroupId' && self.productTableData[row] != undefined && (newValue != '' && newValue != undefined && newValue != null)) {
                  let productTargetingAdgroup = self.adsData.filter(adgroup => adgroup.adGroup == newValue)[0];
                  self.productTableData[row].bid = productTargetingAdgroup.defaultbid;
                  this.updateTableData(self.productTableData, 'hot4')
                }
                self.productTableData = this.updateEditedData(self.productTableData, row, prop, newValue, 'productAdGroupId', self.adgroupsSelectedProduct, this.editedAdgroupId)
                let products = {
                  asin: self.productTableData,
                  negativeAsin: this.productTablesData.negativeAsin
                }
                this.set_productTableData(products);
              } else if (prop == 'adGroupIdProductNK' || prop == 'excludedAsins') {
                self.negativeProductTableData = this.updateEditedData(self.negativeProductTableData, row, prop, newValue, 'adGroupIdProductNK', self.adgroupsSelectedProduct, this.editedAdgroupId)
                let products = {
                  asin: this.productTablesData.asin,
                  negativeAsin: self.negativeProductTableData
                }
                this.set_productTableData(products);
              }
            })
          }
        }
      }
    },
    updateKeywordBidValues () {
      let keywordRequest = [];
      let asins = [];
      let self = this;
      let ele = document.getElementById('keywordHot')
      let instance = ele.__vue__.hotInstance;
      this.getKeywordColumnsConfig("Suggested Bid (" + this.bpCurrencySymbol + ") <button onclick = 'updateKeywordBidValues()'><i class='fa fa-spinner fa-spin' aria-hidden='true'></i></button>", this.bpCurrencySymbol);
      let updateSettings = {
        colHeaders: this.colHeaders
      }
      instance.updateSettings(updateSettings);
      this.keywordTablesData.keyword.forEach((keyword, index) => {
        if (keyword.keyword != null && keyword.keyword != undefined && keyword.keyword != '') {
          let adgroup = this.adsData.filter(adgroup => adgroup.adGroup == keyword.adGroupId)
          let cluster = adgroup.length > 0 ? this.clusterDetails.filter(cluster => cluster.id == adgroup[0].cluster) : [];
          let ads = cluster.length > 0 ? cluster[0].cluster.amsCluster.asinsToAdvertise : [];
          ads.forEach(ad => {
            if (!asins.includes(ad)) {
              asins.push(ad)
            }
          })
          keywordRequest.push({
            Id: index,
            Keyword: keyword.keyword,
            MatchType: keyword.matchType
          })
        }
      })
      let body = {
        asinsToAdvertise: asins,
        keywords: keywordRequest
      }
      APIService
        .GetKeywordBid(body, this.configColumns.bpseId)
        .then((response) => {
          let bidSuggestions = response.data.data;
          bidSuggestions.forEach((key, index) => {
            let adgroupData = self.adsData.filter(adgroup => adgroup.adGroup == self.keywordTablesData.keyword[key.id].adGroupId);
            this.keywordTablesData.keyword[key.id].lowBid = key.rangeStart == null ? 'NA' : key.rangeStart
            this.keywordTablesData.keyword[key.id].highBid = key.rangeEnd == null ? 'NA' : key.rangeEnd
            this.keywordTablesData.keyword[key.id].suggestedBid = key.suggested == null ? adgroupData[0].defaultbid : key.suggested
          })
          let ele = document.getElementById('keywordHot')
          let instance = ele.__vue__.hotInstance;
          this.getKeywordColumnsConfig('', this.bpCurrencySymbol);
          let updateSettings = {
            data: this.keywordTablesData.keyword,
            colHeaders: this.colHeaders
          }
          instance.updateSettings(updateSettings);
        })
        .catch((x) => {
          this.getKeywordColumnsConfig("Suggested Bid (" + this.bpCurrencySymbol + ") <button onclick = 'updateKeywordBidValues()'><i class='fa fa-refresh' aria-hidden='true'></i></button>")
          let updateSettings = {
            colHeaders: this.colHeaders
          }
          instance.updateSettings(updateSettings);
          this.errorOccurred = true;
          this.errorMessage = "Unable to fetch categories data.";
        });
    },
    updateProductBidValues () {
      let productRequest = [];
      let self = this;
      let ele = document.getElementById('productHot')
      let instance = ele.__vue__.hotInstance;
      this.getProductColumnsConfig("Suggested Bid (" + this.bpCurrencySymbol + ") <button onclick = 'updateProductBidValues()'><i class='fa fa-spinner fa-spin' aria-hidden='true'></i></button>", this.bpCurrencySymbol);
      let updateSettings = {
        colHeaders: this.colHeaders
      }
      instance.updateSettings(updateSettings);
      this.productTablesData.asin.forEach((product, index) => {
        if (product.asin != null && product.asin != undefined && product.asin != '') {
          productRequest.push({
            Id: index,
            ProductTargetId: product[1] == undefined ? product.asin : product[1],
            ProductTargetTypeId: product[1] == undefined ? 1 : 2
          })
        }
      })
      APIService
        .GetProductBid(productRequest, this.configColumns.bpseId)
        .then((response) => {
          let bidSuggestions = response.data.data;
          bidSuggestions.forEach((product, index) => {
            let adgroupData = self.adsData.filter(adgroup => adgroup.adGroup == self.productTablesData.asin[product.id].productAdGroupId);
            self.productTablesData.asin[product.id].lowBid = product.rangeStart == null ? 'NA' : product.rangeStart
            self.productTablesData.asin[product.id].highBid = product.rangeEnd == null ? 'NA' : product.rangeEnd
            self.productTablesData.asin[product.id].suggestedBid = product.suggested == null ? adgroupData[0].defaultbid : product.suggested
          })
          let ele = document.getElementById('productHot')
          let instance = ele.__vue__.hotInstance;
          this.getProductColumnsConfig('', this.bpCurrencySymbol);
          let updateSettings = {
            data: this.productTablesData.asin,
            colHeaders: this.colHeaders
          }
          instance.updateSettings(updateSettings);
        })
        .catch((x) => {
          this.getProductColumnsConfig("Suggested Bid (" + this.bpCurrencySymbol + ") <button onclick = 'updateProductBidValues()'><i class='fa fa-refresh' aria-hidden='true'></i></button>")
          let updateSettings = {
            colHeaders: this.colHeaders
          }
          instance.updateSettings(updateSettings);
          this.errorOccurred = true;
          this.errorMessage = "Unable to fetch categories data.";
        });
    },
    FetchProductsData (products) {
      var self = this;
      if (products !== null && products !== undefined && products !== "") {
        APIService.getProductsData(products)
          .then(response => {
            response.data.forEach(ele => {
              self.adsDetails.push(ele);
            })
          })
          .catch(x => {
            this.$Message.error({
              background: true,
              content:
                'Error while fetching Asins To Advertise'
            });
          });
      }
    },
    parseJson (str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    },
    updateTableData (tableData, tableId, columns = '', colheaders = '') {
      var self = this;
      let updatedSettings3 = {
        data: tableData
      };
      if (columns != '' && columns != undefined) {
        updatedSettings3.columns = columns
      }
      if (colheaders != '' && colheaders != undefined) {
        updatedSettings3.colHeaders = colheaders
      }
      if (self.$refs[tableId] === undefined) {
      } else {
        self.$refs[tableId].hotInstance.updateSettings(updatedSettings3);
      }
    },
    getTableData (tableId) {
      var self = this;
      var dataArray = [];
      if (self.$refs[tableId] === undefined) {
      } else {
        dataArray = self.$refs[tableId].hotInstance.getData();
      }
      return dataArray;
    },
    updateEditedData (tableData, row, prop, newValue, adgroupProp, selectedAdgroupProp, adgroup) {
      let rowCount = 0;
      if (tableData.length > 0 && tableData.length > row && tableData[row][prop] != undefined) {
        tableData.forEach(ele => {
          adgroup = this.editedAdgroupId != '' ? adgroup : ele[adgroupProp];
          if (adgroup == selectedAdgroupProp) {
            if (rowCount == row) {
              if (prop == adgroupProp) {
                this.editedAdgroupId = ele[prop];
              }

              if (prop != 'asin') {
                ele[prop] = newValue
              }
            }
            rowCount++;
          } else if (selectedAdgroupProp == 'All Adgroups') {
            tableData[row][prop] = newValue;
          }
        })
      }
      return tableData;
    },
    async getEntitiesData () {
      let self = this;
      this.set_amsLineItemsUpdate(false);
      if (this.$refs["hot1"] !== undefined) {
        this.$store.commit("set_isCreated", !this.isCreated);
        var requestData = {
          configId: this.selectedCampaignLauncherConfigId,
          mediaPlanId: this.mediaPlanId
        };
        var url = this.deltax.apiEndPoint +
          "businessProfiles/" +
          this.businessProfileId +
          "/PublishStageData"
        let response = await APIService.PostCampaignLauncherRequestData(
          url,
          requestData
        );
        let clusterDetails = [];
        let adGroupDetails = [];
        self.keywordTableData = [];
        self.productTableData = [];
        self.negativeProductTableData = [];
        self.negativeKeywordTableData = [];
        self.adgroupData = [];
        if (!self.isSpSubmissionFlow && !self.entitiesData.amsEntities) {
          self.entitiesData = response.data;
          this.set_amsEntitiesResponse(self.entitiesData);
        }
        self.entitiesData.amsEntities.forEach(entity => {
          this.set_bpCurrencySymbol(entity.additionalData.bpcurrency);
          entity.childEntities.forEach(child => {
            clusterDetails.push({
              id: child.additionalData.cluster.name,
              cluster: child.additionalData.cluster,
              child: child.childEntities,
              keywords: child.additionalData.keywords
            });
            child.additionalData.keywordsBidInfo.forEach(keyword => {
              self.keywordTableData.push({
                adGroupId: child.name,
                keyword: keyword.Keyword,
                matchType: keyword.MatchType,
                bid: keyword.Bid
              })
            })
            child.additionalData.productsToTargets.forEach(product => {
              self.productTableData.push({
                productAdGroupId: child.name,
                asin: product.AsinsToTarget,
                productBid: product.Bid
              })
            })
            child.additionalData.categories.forEach(product => {
              self.productTableData.push({
                productAdGroupId: child.name,
                asin: product.CategoriesToTarget,
                productBid: product.Bid
              })
            })
            child.additionalData.negativeAsins.forEach(negativeAsins => {
              self.negativeProductTableData.push({
                adGroupIdProductNK: child.name,
                excludedAsins: negativeAsins
              })
            })
            child.additionalData.negativeKeywords.forEach(negativeKeyword => {
              self.negativeKeywordTableData.push({
                adGroupIdNK: child.name,
                negativeKeyword: negativeKeyword.NegativeKeywords,
                negativeMatchType: negativeKeyword.MatchType
              })
            })
            let adGroupInfo = {
              adGroup: child.name,
              campaign: entity.name,
              cluster: child.additionalData.cluster.name,
              amsTargetType: child.amsTargetType == this.targetingType["Keyword Targeting"] ? 'Keyword Targeting' : 'Product Targeting',
              targets: child.additionalData.productsToTargets.length != 0 || child.additionalData.categories.length != 0 ? child.additionalData.productsToTargets.length + child.additionalData.categories.length + "Products" : child.additionalData.keywordsBidInfo.length != 0 ? child.additionalData.keywordsBidInfo.length + "Keywords" : '',
              defaultbid: 1.00,
              status: child.status
            };
            self.adgroupData.push(adGroupInfo);
            adGroupInfo.productsToTargets = child.additionalData.productsToTargets;
            adGroupInfo.negativeAsins = child.additionalData.negativeAsins;
            adGroupInfo.categoryToTarget = child.additionalData.categories;
            adGroupInfo.keyword = child.additionalData.keywordsBidInfo;
            adGroupInfo.negativeKeyword = child.additionalData.negativeKeywords;
            adGroupDetails.push(adGroupInfo);
          })
        })
        for (var r = 0; r < clusterDetails.length; r += 1) {
          this.FetchProductsData(clusterDetails[r].cluster.amsCluster.asinsToAdvertise[0].toString());
        }
        this.set_clusterDetails(clusterDetails);
        if (self.savedData != null && self.savedData.campaignLauncherConfigurationId == this.selectedCampaignLauncherConfigId) {
          let adsheetData = JSON.parse(self.savedData.creativeJson);
          var savedAdgroups = adsheetData.Adgroup.map(a => a.adGroup);
          var adGroups = self.adgroupData.map(a => a.adGroup);
          var clusters = clusterDetails.map(c => c.id);
          self.adgroupData.forEach(adgroup => {
            if (!savedAdgroups.includes(adgroup.adGroup)) {
              adsheetData.Adgroup.push(adgroup);
            }
          })
          for (var i = 0; i < adsheetData.Adgroup.length; i++) {
            if ((!clusters.includes(adsheetData.Adgroup[i].cluster) && (!adGroups.includes(adsheetData.Adgroup[i].adGroup)))) {
              adsheetData.Adgroup.splice(i, 1);
              i--;
            }
          }
          adsheetData.Adgroup.forEach(adgroup => {
            if (this.adgroupData.filter(obj => obj.adGroup == adgroup.adGroup).length == 0) {
              this.adgroupData.push(adgroup);
            }
          })
          if (adsheetData.keywords !== undefined && adsheetData.keywords !== null) {
            adsheetData.keywords.forEach(keyword => {
              if (self.keywordTableData.filter(obj => obj.adGroupId == keyword.adGroupId && obj.bid == keyword.bid && obj.keyword == keyword.keyword && obj.matchType == keyword.matchType).length == 0) {
                self.keywordTableData.push(keyword)
              }
            })
          }
          adsheetData.products.forEach(product => {
            if (self.productTableData.filter(obj => obj.asin == product.asin && obj.productAdGroupId == product.productAdGroupId && obj.productBid == product.productBid).length == 0) {
              self.productTableData.push(product)
            }
          })
          adsheetData.negativeProducts.forEach(negativeProduct => {
            if (self.negativeProductTableData.filter(obj => obj.adGroupIdProductNK == negativeProduct.adGroupIdProductNK && obj.excludedAsins == negativeProduct.excludedAsins).length == 0) {
              self.negativeProductTableData.push(negativeProduct)
            }
          })
          adsheetData.negativeKeywords.forEach(negativekeyword => {
            if (self.negativeKeywordTableData.filter(obj => obj.adGroupIdNK == negativekeyword.adGroupIdNK && obj.negativeKeyword == negativekeyword.negativeKeyword && obj.negativeMatchType == negativekeyword.negativeMatchType).length == 0) {
              self.negativeKeywordTableData.push(negativekeyword)
            }
          })
          this.keywordTablesData.keyword = self.keywordTableData;
          this.keywordTablesData.negativeKeyword = self.negativeKeywordTableData;
          this.productTablesData.asin = self.productTableData;
          this.productTablesData.negativeAsin = self.negativeProductTableData;
          let keywords = {
            keyword: this.keywordTablesData.keyword,
            negativeKeyword: this.keywordTablesData.negativeKeyword
          }
          let products = {
            asin: this.productTablesData.asin,
            negativeAsin: this.productTablesData.negativeAsin
          }
          this.set_productTableData(products);
          this.set_keywordTableData(keywords);
          this.updateTableData(this.keywordTableData, 'hot2');
          this.updateTableData(this.negativeKeywordTableData, 'hot3');
          this.updateTableData(this.productTableData, 'hot4');
          this.updateTableData(this.negativeProductTableData, 'hot5');
          this.set_amsSheetData(adsheetData);
        } else {
          let adsheetData = {};
          adsheetData.Adgroup = adGroupDetails;
          this.set_amsSheetData(adsheetData);
        }
        this.$store.commit("set_AmsLoadNext", false);
        this.$store.commit("set_AmsLoadSave", false);
        this.adgroupDataLength = this.adgroupData.length;
        this.set_clusterDetails(clusterDetails);
        this.getColumnConfig('AdGroups')
        let adgroupColumns = this.allcolumns[0];
        let adgroupColHeaders = adgroupColumns["base"];
        this.displayColHeaders["HOT_DefaultBid"] = this.displayColHeaders["HOT_DefaultBid"] + " (" + this.bpCurrencySymbol + ")"
        this.getColumnsData(adgroupColHeaders);
        this.updateTableData(this.adgroupData, 'hot1', this.columns, this.colHeaders);
        this.set_adsData(this.adgroupData);
        this.updateAdgroupIds();
      }
    },
    getKeywordColumnsConfig (bidIcon = '', bpCurrency = '') {
      this.getColumnConfig('Keyword Targeting', bidIcon)
      let column = this.allcolumns[0];
      let colHeaders = column["base"];
      if (bpCurrency != '') {
        this.displayColHeaders["HOT_Bid"] = this.displayColHeaders["HOT_Bid"] + " (" + bpCurrency + ")";
        this.displayColHeaders["HOT_LowBid"] = this.displayColHeaders["HOT_LowBid"] + " (" + bpCurrency + ")";
        this.displayColHeaders["HOT_HighBid"] = this.displayColHeaders["HOT_HighBid"] + " (" + bpCurrency + ")";
        let suggestedBid = (this.displayColHeaders["HOT_SuggestedBid"]).replace("bpCurrency", " (" + bpCurrency + ")");
        this.displayColHeaders["HOT_SuggestedBid"] = suggestedBid;
      }
      this.getColumnsData(colHeaders);
    },
    getProductColumnsConfig (bidIcon = '', bpCurrency = '') {
      this.getColumnConfig('Product Targeting', bidIcon)
      let column2 = this.allcolumns[0];
      let colHeaders2 = column2["base"];
      if (bpCurrency != '') {
        this.displayColHeaders["HOT_ProductBid"] = this.displayColHeaders["HOT_ProductBid"] + " (" + bpCurrency + ")";
        this.displayColHeaders["HOT_LowBid"] = this.displayColHeaders["HOT_LowBid"] + " (" + bpCurrency + ")";
        this.displayColHeaders["HOT_HighBid"] = this.displayColHeaders["HOT_HighBid"] + " (" + bpCurrency + ")";
        let suggestedBid = (this.displayColHeaders["HOT_SuggestedBid"]).replace("bpCurrency", " (" + bpCurrency + ")");
        this.displayColHeaders["HOT_SuggestedBid"] = suggestedBid;
      }
      this.getColumnsData(colHeaders2);
    },
    updateAdgroupIds () {
      this.adgroupList.splice(1, ((this.adgroupList.length) - 1))
      this.adsData.forEach(element => {
        if (element.amsTargetType == 'Keyword Targeting' && element.adGroup != null && element.adGroup != undefined) {
          this.adgroupList.push(element.adGroup);
        }
      });

      this.productAdgroupList.splice(1, ((this.productAdgroupList.length) - 1))
      this.adsData.forEach(element => {
        if (element.amsTargetType == 'Product Targeting' && element.adGroup != null && element.adGroup != undefined) {
          this.productAdgroupList.push(element.adGroup);
        }
      });

      if (this.$refs["hot2"] !== undefined) {
        this.keywordTableData = this.keywordTablesData.keyword;
        this.negativeKeywordTableData = this.keywordTablesData.negativeKeyword
        this.adgroupSpecificKeywords = this.keywordTableData;
        this.adgroupSpecificNegativeKeywords = this.negativeKeywordTableData;
        this.getKeywordColumnsConfig('', this.bpCurrencySymbol);
        this.updateTableData(this.keywordTableData, 'hot2', this.columns, this.bpCurrencySymbol == '' ? '' : this.colHeaders);
        let column1 = this.allcolumns[1];
        let colHeaders1 = column1["base"];
        this.getColumnsData(colHeaders1);
        this.updateTableData(this.negativeKeywordTableData, 'hot3', this.columns);

        let keywords = {
          keyword: this.keywordTableData,
          negativeKeyword: this.negativeKeywordTableData
        }
        this.set_keywordTableData(keywords);
      }
      if (this.$refs["hot4"] !== undefined) {
        this.productTableData = this.productTablesData.asin;
        this.negativeProductTableData = this.productTablesData.negativeAsin;
        this.adgroupSpecificAsins = this.productTableData;
        this.adgroupSpecificNegativeAsins = this.negativeProductTableData;
        this.getProductColumnsConfig('', this.bpCurrencySymbol)
        this.updateTableData(this.productTableData, 'hot4', this.columns, this.bpCurrencySymbol == '' ? '' : this.colHeaders);

        let column3 = this.allcolumns[1];
        let colHeaders3 = column3["base"];
        this.getColumnsData(colHeaders3);
        this.updateTableData(this.negativeProductTableData, 'hot5', this.columns);

        let products = {
          asin: this.productTableData,
          negativeAsin: this.negativeProductTableData
        }
        this.set_productTableData(products);
      }
    }
  },
  watch: {
    adgroupDetails: function () {
      this.adsData = this.adgroupDetails
    },
    isRefreshEntitiesData: function () {
      if (this.isRefreshEntitiesData) {
        this.getEntitiesData();
        this.set_isRefreshEntitiesData(false);
      }
    },
    currentAmsTab: function () {
      if (this.currentAmsTab == 'AdGroups') {
        this.adsData.forEach(adgroup => {
          let countDetails = '';
          if (adgroup.amsTargetType == 'Keyword Targeting') {
            let length = this.keywordTablesData.keyword.filter(keyword => keyword.adGroupId == adgroup.adGroup).length;
            countDetails = length > 0 ? length + ' Keywords' : ''
          } else if (adgroup.amsTargetType == 'Product Targeting') {
            let length = this.productTablesData.asin.filter(keyword => keyword.productAdGroupId == adgroup.adGroup).length;
            countDetails = length > 0 ? length + ' Products' : ''
          }
          adgroup.targets = countDetails;
        })
        this.set_adsData(this.adsData);
        this.updateTableData(this.adsData, 'hot1');
      } else {
        this.updateAdgroupIds();
      }
    },
    IsAmsLineItemsUpdated () {
      if (this.IsAmsLineItemsUpdated) {
        this.$forceUpdate();
        let len = this.adgroupDataLength;
        this.entitiesData = {};
        this.getAdSheetData();
        this.$store.commit("set_AmsLoadNext", true);
        this.adgroupDataLength = len;
      }
    },
    adgroupsSelectedKeyword () {
      if (this.filteredData.length == 0) {
        this.keywordTableData = [];
        let dt = this.getTableData('hot2');
        dt.forEach(x => {
          if (x[0] != null) {
            this.keywordTableData.push({
              adGroupId: x[0],
              keyword: x[1],
              matchType: x[2],
              bid: x[3]
            });
          }
        })
      }
      if (this.filteredNegativeKeywordData.length == 0) {
        this.negativeKeywordTableData = [];
        let nkdt = this.getTableData('hot3');
        nkdt.forEach(x => {
          if (x[0] != null) {
            this.negativeKeywordTableData.push({
              adGroupIdNK: x[0],
              negativeKeyword: x[1],
              negativeMatchType: x[2]
            });
          }
        })
      }
      if (this.adgroupsSelectedKeyword == 'All Adgroups') {
        let allData = [];
        let allNegativeData = [];
        this.filteredData = [];
        this.filteredNegativeKeywordData = [];
        this.keywordTableData.forEach(x => {
          allData.push({
            adGroupId: x.adGroupId,
            keyword: x.keyword,
            matchType: x.matchType,
            bid: x.bid
          });
        });
        this.negativeKeywordTableData.forEach(x => {
          allNegativeData.push({
            adGroupIdNK: x.adGroupIdNK,
            negativeKeyword: x.negativeKeyword,
            negativeMatchType: x.negativeMatchType
          });
        });
        this.adgroupSpecificKeywords = this.keywordTableData;
        this.adgroupSpecificNegativeKeywords = this.negativeKeywordTableData;
        this.updateTableData(allData, 'hot2');
        this.updateTableData(allNegativeData, 'hot3');
      } else {
        this.filteredData = [];
        this.filteredNegativeKeywordData = [];
        this.keywordTableData.forEach(x => {
          if (x.adGroupId == this.adgroupsSelectedKeyword) {
            this.filteredData.push({
              adGroupId: x.adGroupId,
              keyword: x.keyword,
              matchType: x.matchType,
              bid: x.bid
            });
          }
        });
        this.negativeKeywordTableData.forEach(x => {
          if (x.adGroupIdNK == this.adgroupsSelectedKeyword) {
            this.filteredNegativeKeywordData.push({
              adGroupIdNK: x.adGroupIdNK,
              negativeKeyword: x.negativeKeyword,
              negativeMatchType: x.negativeMatchType
            });
          }
        });
        this.adgroupSpecificKeywords = this.filteredData;
        this.adgroupSpecificNegativeKeywords = this.filteredNegativeKeywordData;
        this.updateTableData(this.filteredData, 'hot2');
        this.updateTableData(this.filteredNegativeKeywordData, 'hot3');
      }
    },
    adgroupsSelectedProduct () {
      if (this.filteredProductData.length == 0) {
        this.productTableData = [];
        let product = this.getTableData('hot4');
        product.forEach(x => {
          if (x[0] != null) {
            this.productTableData.push({
              productAdGroupId: x[0],
              asin: x[1],
              productBid: x[2]
            });
          }
        })
      }
      if (this.filteredNegativeProductData.length == 0) {
        this.negativeProductTableData = [];
        let negativeProduct = this.getTableData('hot5');
        negativeProduct.forEach(x => {
          if (x[0] != null) {
            this.negativeProductTableData.push({
              adGroupIdProductNK: x[0],
              excludedAsins: x[1]
            });
          }
        })
      }
      if (this.adgroupsSelectedProduct == 'All Adgroups') {
        let allData = [];
        let allNegativeData = [];
        this.filteredProductData = [];
        this.filteredNegativeProductData = [];
        this.productTableData.forEach(x => {
          allData.push({
            productAdGroupId: x.productAdGroupId,
            asin: x.asin,
            productBid: x.productBid
          });
        });
        this.negativeProductTableData.forEach(x => {
          allNegativeData.push({
            adGroupIdProductNK: x.adGroupIdProductNK,
            excludedAsins: x.excludedAsins
          });
        });
        this.adgroupSpecificAsins = this.productTableData;
        this.adgroupSpecificNegativeAsins = this.negativeProductTableData;
        this.updateTableData(allData, 'hot4');
        this.updateTableData(allNegativeData, 'hot5');
      } else {
        this.filteredProductData = [];
        this.filteredNegativeProductData = [];
        this.productTableData.forEach(x => {
          if (x.productAdGroupId == this.adgroupsSelectedProduct) {
            this.filteredProductData.push({
              productAdGroupId: x.productAdGroupId,
              asin: x.asin,
              productBid: x.productBid
            });
          }
        });
        this.negativeProductTableData.forEach(x => {
          if (x.adGroupIdProductNK == this.adgroupsSelectedProduct) {
            this.filteredNegativeProductData.push({
              adGroupIdProductNK: x.adGroupIdProductNK,
              excludedAsins: x.excludedAsins
            });
          }
        });
        this.adgroupSpecificAsins = this.filteredProductData;
        this.adgroupSpecificNegativeAsins = this.filteredNegativeProductData;
        this.updateTableData(this.filteredProductData, 'hot4');
        this.updateTableData(this.filteredNegativeProductData, 'hot5');
      }
    }
  }
};
</script>
<style>
.error-cell-text {
  border: 1px solid red !important;
}
</style>
<style scoped>
.ad-sheet-hot .handsontable td,
.ad-sheet-hot .handsontable tr,
.ad-sheet-hot .handsontable th {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap !important;
}
.td-text {
  display: inline-flex !important;
  float: left !important;
}
.edit-td-wrapper {
  display: flex;
  width: 100%;
  height: 50%;
}
.edit-icon {
  font-size: 18px;
  opacity: 1;
}
.edit-td-wrapper > span {
  display: inline-flex;
  float: right;
}
.disabled-cell-text {
  background-color: #dac0c0 !important;
  color: #bbb !important;
  cursor: not-allowed !important;
}
.hot-img {
  display: block;
  padding: 2px;
  margin: 0 auto;
  max-height: 70px;
  max-width: 100px;
}
.flex {
  display: flex;
  width: auto;
  height: auto;
}
::v-deep .ivu-poptip-popper[x-placement^=right] .ivu-poptip-arrow {
    left: -7px !important;
}
</style>
<style
  src="../../../../../../../node_modules/handsontable/dist/handsontable.full.css"
></style>
