<template>
  <div>
    <button
      @click="showModal = true"
      class="btn btn-success btn-sm widget-element pull-right"
    >
      <i class="fa fa-plus"></i>
    </button>

    <v-modal
      class="twitter-target-modal"
      title="Create Target"
      v-model="showModal"
      effect="fade"
    >
      <div slot="modal-body" class="targets-body">
        <Row>
          <Row-Col span="16">
            <Form
              :label-width="105"
              :model="targetForm"
              :rules="validationRules"
              ref="creationForm"
            >
              <br />

              <Row>
                <Row-Col span="9">
                  <Form-Item label="Account:" prop="bpseId">
                    <Select
                      v-model="targetForm.bpseId"
                      placeholder="Select an account"
                    >
                      <Option
                        v-for="account in twitterAccounts"
                        :value="account.id"
                        :key="account.id"
                        >{{ account.accountNickName }} ({{
                          account.accountId
                        }})</Option
                      >
                    </Select>
                  </Form-Item>
                </Row-Col>
                <Row-Col span="15">
                  <Button class="float-right" @click="clearAll"
                    >Clear all</Button
                  >
                </Row-Col>
              </Row>
              <br />

              <template v-if="targetForm.bpseId">
                <Form-Item
                  label="Target Name:"
                  prop="targetName"
                  class="target-name"
                >
                  <Row>
                    <Row-Col span="15">
                      <i-input v-model="targetForm.targetName" />
                    </Row-Col>
                  </Row>
                  <Row>
                    <Row-Col span="15">
                      <ul class="macro-list">
                        <li class="macros">Available Macros:</li>
                        <li
                          v-for="macroName in targetMacros"
                          :key="macroName"
                          class="pointer"
                        >
                          <a class="macros" @click="addMacro(macroName)">
                            {{ macroName }}
                          </a>
                        </li>
                      </ul>
                    </Row-Col>
                  </Row>
                </Form-Item>
                <div class="divider">
                  <span>Demographics</span>
                </div>
                <Form-Item label="Age">
                  <Collapse
                    v-model="collapsePanel"
                    class="twitter-targets-panel"
                  >
                    <Panel hide-arrow name="open">
                      Age Group: {{ getAgeStringForPanel }}
                      <div slot="content">
                        <Form-Item label="Age" prop="age.type">
                          <Row>
                            <Row-Col span="10">
                              <RadioGroup v-model="targetForm.age.type">
                                <Radio
                                  :label="parseInt(id)"
                                  v-for="(type, id) in twitterEnums.age.type"
                                  :key="parseInt(id)"
                                  class="target-label-span"
                                >
                                  <span> {{ type }} </span>
                                </Radio>
                              </RadioGroup>
                            </Row-Col>
                          </Row>
                          <Row v-if="targetForm.age.type === 1">
                            <Row-Col span="12">
                              <span class="target-label-span">Min Age: </span>
                              <Select
                                v-model="targetForm.age.min"
                                filterable
                                placeholder="Min Age"
                                class="target-age-select"
                              >
                                <Option
                                  v-for="age in twitterEnums.age.min"
                                  :key="age"
                                  :value="age"
                                  :disabled="age > targetForm.age.max"
                                  >{{ age }}</Option
                                >
                              </Select>
                            </Row-Col>
                            <Row-Col span="12">
                              <span class="target-label-span">Max Age: </span>
                              <Select
                                v-model="targetForm.age.max"
                                filterable
                                placeholder="Max Age"
                                class="target-age-select"
                              >
                                <Option
                                  v-for="age in twitterEnums.age.max"
                                  :key="age"
                                  :value="age"
                                  :disabled="age < targetForm.age.min"
                                  >{{ age }}</Option
                                >
                              </Select>
                            </Row-Col>
                          </Row>
                        </Form-Item>
                      </div>
                    </Panel>
                  </Collapse>
                </Form-Item>
                <Form-Item label="Gender">
                  <Collapse
                    v-model="collapsePanel"
                    class="twitter-targets-panel"
                  >
                    <Panel hide-arrow name="open">
                      Gender: {{ twitterEnums.gender[targetForm.gender] }}
                      <div slot="content">
                        <Form-Item label="Gender" prop="gender">
                          <Row>
                            <Row-Col span="10">
                              <RadioGroup
                                type="button"
                                button-style="solid"
                                v-model="targetForm.gender"
                              >
                                <Radio
                                  :label="parseInt(id)"
                                  v-for="(type, id) in twitterEnums.gender"
                                  :key="parseInt(id)"
                                >
                                  <span> {{ type }} </span>
                                </Radio>
                              </RadioGroup>
                            </Row-Col>
                          </Row>
                        </Form-Item>
                      </div>
                    </Panel>
                  </Collapse>
                </Form-Item>
                <Form-Item label="Location & Language">
                  <Collapse
                    v-model="collapsePanel"
                    class="twitter-targets-panel"
                  >
                    <Panel hide-arrow name="open">
                      <div v-if="getStringForPanel('locations') || getStringForPanel('languages')">
                        Location: {{ getStringForPanel('locations') }} <br />
                      </div>
                      <div v-else>
                        Location & Languages
                      </div>
                      <div slot="content">
                        <Form-Item label="Location" prop="locations">
                          <Row
                            ><Row-Col span="15">
                              <Select
                                multiple
                                v-model="targetForm.locations"
                                filterable
                                placeholder="Search locations"
                                :remote-method="
                                  query => getTargetingData(query, 'locations')
                                "
                                :loading="loading.locations"
                              >
                                <Option
                                  v-for="loc in locations"
                                  :key="loc.targeting_value"
                                  :value="loc.targeting_value"
                                  :label="loc.name"
                                >
                                  <span>{{ loc.name }}</span>
                                  <span style="float:right;color:#ccc">{{
                                    loc.location_type
                                  }}</span>
                                </Option>
                              </Select>
                            </Row-Col></Row
                          >
                        </Form-Item>
                        <Form-Item label="Language" prop="language">
                          <Row
                            ><Row-Col span="15">
                              <Select
                                multiple
                                v-model="targetForm.languages"
                                filterable
                                placeholder="Search language"
                                :remote-method="
                                  query => getTargetingData(query, 'languages')
                                "
                                :loading="loading.languages"
                              >
                                <Option
                                  v-for="lang in languages"
                                  :key="lang.targeting_value"
                                  :value="lang.targeting_value"
                                  >{{ lang.name }}</Option
                                >
                              </Select>
                            </Row-Col></Row
                          >
                        </Form-Item>
                      </div>
                    </Panel>
                  </Collapse>
                </Form-Item>
                <Form-Item label="Device">
                  <Collapse
                    v-model="collapsePanel"
                    class="twitter-targets-panel"
                  >
                    <Panel hide-arrow name="open">
                      Devices
                      <div slot="content">
                        <Form-Item label="Operating System" prop="devices">
                          <Row>
                            <Row-Col span="15">
                              <CheckboxGroup v-model="targetForm.platforms">
                                <div
                                  v-for="os in platforms"
                                  :key="os.targeting_value"
                                >
                                  <Checkbox
                                    :label="os.targeting_value"
                                    class="operating-system-checkbox"
                                  >
                                    <span class="os-name"
                                      >{{ os.name }}</span
                                    >
                                  </Checkbox>
                                  <div
                                    class="platform-version-select"
                                    v-if="
                                      checkIfOsIsSelected(os.targeting_value)
                                    "
                                  >
                                    <Select
                                      v-model="
                                        targetForm.platform_versions[
                                          os.targeting_value
                                        ]
                                      "
                                      filterable
                                      placeholder="Search platform versions"
                                    >
                                      <Option
                                        v-for="version in platform_versions.filter(
                                          x => x.os_type === os.name
                                        )"
                                        :key="version.targeting_value"
                                        :value="version.targeting_value"
                                        >{{getStringForPlatformVersions(version)}}</Option
                                      >
                                    </Select>
                                  </div>
                                </div>
                              </CheckboxGroup>
                            </Row-Col>
                          </Row>
                        </Form-Item>
                        <Form-Item label="Model" prop="model">
                          <Row
                            ><Row-Col span="15">
                              <Select
                                multiple
                                v-model="targetForm.devices"
                                filterable
                                placeholder="Search Model"
                                :remote-method="
                                  query => getTargetingData(query, 'devices')
                                "
                                :loading="loading.devices"
                              >
                                <Option
                                  v-for="device in devices"
                                  :key="device.targeting_value"
                                  :value="device.targeting_value"
                                  >{{ device.name }}</Option
                                >
                              </Select>
                            </Row-Col></Row
                          >
                        </Form-Item>
                        <Form-Item label="Carrier" prop="network_operators">
                          <Row
                            ><Row-Col span="15">
                              <Select
                                multiple
                                v-model="targetForm.network_operators"
                                filterable
                                placeholder="Search Carrier"
                                :remote-method="
                                  query =>
                                    getTargetingData(query, 'network_operators')
                                "
                                :loading="loading.network_operators"
                              >
                                <Option
                                  v-for="operators in network_operators"
                                  :key="operators.targeting_value"
                                  :value="operators.targeting_value"
                                  >{{ operators.name }}</Option
                                >
                              </Select>
                            </Row-Col></Row
                          >
                        </Form-Item>
                      </div>
                    </Panel>
                  </Collapse>
                </Form-Item>
                <Form-Item label="Targeting Features">
                  <Collapse
                    v-model="collapsePanel"
                    class="twitter-targets-panel"
                  >
                    <Panel hide-arrow name="open">
                      Features
                      <div slot="content">
                        <Form-Item
                          label="Include Keywords"
                          prop="includedKeywords"
                        >
                          <Row
                            ><Row-Col span="15">
                              <i-input
                                @on-enter="
                                  addTag(
                                    'includedKeywordsTags',
                                    'includedKeywords'
                                  )
                                "
                                v-model="includedKeywords"
                                placeholder="Press enter to add new keywords"
                              />
                              <Tag
                                v-for="item in includedKeywordsTags"
                                :key="item"
                                closable
                                @on-close="
                                  handleClose('includedKeywordsTags', item)
                                "
                                >{{ item }}</Tag
                              >
                            </Row-Col></Row
                          >
                        </Form-Item>
                        <Form-Item
                          label="Exclude Keywords"
                          prop="excludedKeywords"
                        >
                          <Row
                            ><Row-Col span="15">
                              <i-input
                                @on-enter="
                                  addTag(
                                    'excludedKeywordsTags',
                                    'excludedKeywords'
                                  )
                                "
                                v-model="excludedKeywords"
                                placeholder="Press enter to add new keywords"
                              />
                              <Tag
                                v-for="item in excludedKeywordsTags"
                                :key="item"
                                closable
                                @on-close="
                                  handleClose('excludedKeywordsTags', item)
                                "
                                >{{ item }}</Tag
                              >
                            </Row-Col></Row
                          >
                        </Form-Item>
                        <Form-Item label="Interest" prop="interests">
                          <Row
                            ><Row-Col span="15">
                              <Select
                                multiple
                                v-model="targetForm.interests"
                                filterable
                                placeholder="Search Interests"
                                :remote-method="
                                  query => getTargetingData(query, 'interests')
                                "
                                :loading="loading.interests"
                              >
                                <Option
                                  v-for="interest in interests"
                                  :key="interest.targeting_value"
                                  :value="interest.targeting_value"
                                  >{{ interest.name }}</Option
                                >
                              </Select>
                            </Row-Col></Row
                          >
                        </Form-Item>
                        <Form-Item label="Movies & TV Shoes" v-if="getLocaleForTVShows.length > 0" prop="tv_shows">
                          <Row
                            ><Row-Col span="15">
                              <Select
                                multiple
                                v-model="targetForm.tv_shows"
                                filterable
                                placeholder="Search TV Shows"
                                :remote-method="
                                  query => getTVShows(query)
                                "
                                :loading="loading.tv_shows"
                              >
                                <Option
                                  v-for="show in tv_shows"
                                  :key="show.targeting_value"
                                  :value="show.targeting_value"
                                  >{{ show.name }}</Option
                                >
                              </Select>
                            </Row-Col></Row
                          >
                        </Form-Item>
                        <Form-Item label="Events" prop="events">
                          <Row
                            ><Row-Col span="15">
                              <Select
                                multiple
                                v-model="targetForm.events"
                                filterable
                                placeholder="Search Events"
                                :remote-method="
                                  query => getTargetingData(query, 'events')
                                "
                                :loading="loading.events"
                              >
                                <Option
                                  v-for="event in events"
                                  :key="event.targeting_value"
                                  :value="interest.targeting_value"
                                  >{{ event.name }}</Option
                                >
                              </Select>
                            </Row-Col></Row
                          >
                        </Form-Item>
                        <Form-Item
                          label="Conversation Topics"
                          prop="conversations"
                        >
                          <Row
                            ><Row-Col span="15">
                              <Select
                                multiple
                                v-model="targetForm.conversations"
                                filterable
                                placeholder="Search Conversations"
                                :remote-method="
                                  query =>
                                    getTargetingData(query, 'conversations')
                                "
                                :loading="loading.conversations"
                              >
                                <Option
                                  v-for="show in conversations"
                                  :key="show.targeting_value"
                                  :value="show.targeting_value"
                                  >{{ show.name }}</Option
                                >
                              </Select>
                            </Row-Col></Row
                          >
                        </Form-Item>
                      </div>
                    </Panel>
                  </Collapse>
                </Form-Item>
                <!-- Not Edited -->
              </template>
              <template v-else>
                <p style="text-align: center;">
                  Please select a Twitter account to create targets
                </p>
              </template>
            </Form>
          </Row-Col>

          <Row-Col span="8">
            <div class="preivew-wrapper">
              <div class="preview-header">
                <p>New Audience Preview</p>
              </div>
              <template v-if="isFormValid">
                <div
                  class="target-row"
                >
                  <Row class="target-prev-header">
                    <Row-Col span="19">
                      <p>{{ getTargetName() }}</p>
                    </Row-Col>
                    <Row-Col span="2" offset="1">
                      <i
                        @click="saveTarget()"
                        class="fa fa-save preview-icon"
                      >
                      </i>
                    </Row-Col>
                    <Row-Col span="2">
                      <i
                        class="fa fa-trash preview-icon"
                        @click="removeTargetFromPreview()"
                      />
                    </Row-Col>
                  </Row>
                    <Row class="target-prev-body">
                      <p>
                        {{getUserReadableTargetSummary}}
                      </p>
                      <p v-if="includedKeywordsTags.length">
                        Included Tags <br/>
                        <Tag v-for="item in includedKeywordsTags" :key="item">
                          {{item}}
                        </Tag>
                      </p>
                      <p v-if="excludedKeywordsTags.length">
                        Excluded Tags <br/>
                        <Tag v-for="item in excludedKeywordsTags" :key="item">
                          {{item}}
                        </Tag>
                      </p>
                    </Row>
                </div>
              </template>
              <template v-else>
                <div class="preview-placeholder">
                  Please enter the required fields to preview new audience
                </div>
              </template>
            </div>
          </Row-Col>
        </Row>
      </div>
      <div slot="modal-footer" class="modal-footer hide"></div>
    </v-modal>
  </div>
</template>

<script>
import { APIService } from "../../ApiService.js";
import { mapState, mapMutations } from "vuex";
import { twitterEnums } from "../../Constants/TwitterEnums.js";
import {
  Row,
  Col,
  Form,
  FormItem,
  Select,
  Option,
  Input,
  Button,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Panel,
  Collapse,
  Tag,
  locale
} from "iview";
import { modal } from "vue-strap";
import lang from "iview/dist/locale/en-US";
import { EventBus } from "../../EventBus.js";

locale(lang);

export default {
  components: {
    Row,
    "Row-Col": Col,
    Form,
    FormItem,
    Select,
    Option,
    "i-input": Input,
    Button,
    "v-modal": modal,
    RadioGroup,
    Radio,
    CheckboxGroup,
    Checkbox,
    Panel,
    Collapse,
    Tag
  },
  data () {
    return {
      twitterEnums,
      showModal: false,
      targetingEndpointStaticTypes: ["platforms", "platform_versions"],
      targetMacros: ["Language", "Location", "Gender", "AgeGroup"],
      langPlaceholder: "Select language",
      locations: [],
      languages: [],
      platforms: [],
      platform_versions: [],
      network_operators: [],
      devices: [],
      conversations: [],
      loading: {
        locations: false,
        languages: false,
        platforms: false,
        devices: false,
        platform_versions: false,
        network_operators: false,
        interests: false,
        tv_markets: false,
        events: false,
        conversations: false,
        tv_shows: false
      },
      collapsePanel: "open",
      includedKeywords: "",
      excludedKeywords: "",
      tv_shows: [],
      interests: [],
      tv_shoes: [],
      events: [],
      event_types:
        "CONFERENCE, HOLIDAY, MOVIE_RELEASE, MUSIC_AND_ENTERTAINMENT, OLYMPICS, OTHER, POLITICS, RECURRING, SPORTS",
      targetForm: {
        bpseId: 0,
        targetName: "Target-{AgeGroup}",
        age: {
          type: 0,
          min: "13",
          max: "And Up"
        },
        gender: 0,
        locations: [],
        languages: [],
        platforms: [],
        platform_versions: {
        },
        network_operators: [],
        devices: [],
        includedKeywords: [],
        excludedKeywords: [],
        interests: [],
        tv_markets: [],
        events: [],
        conversations: [],
        tv_shows: [],
        locale: []
      },
      targetsList: [],
      targetStringJson: {},
      includedKeywordsTags: [],
      excludedKeywordsTags: [],
      locale: [],
      validationRules: {
        bpseId: [
          {
            required: true,
            type: "number",
            message: "Please select an account",
            trigger: "change"
          }
        ],
        targetName: [
          { required: true, message: "Please enter a name", trigger: "blur" }
        ]
      },
      disableExclusion: true,
      commonUsageTargetingProperties: [
        "locations",
        "languages",
        "platforms",
        "network_operators",
        "devices",
        "interests",
        "events",
        "conversations",
        "tv_shows"]
    };
  },
  computed: {
    ...mapState([
      "twitterAccounts",
      "savedTargetsMapperByBpseId",
      "savedTargets"
    ]),
    isFormValid () {
      return (
        this.targetForm.bpseId != null &&
        this.targetsList.length > 0 &&
        this.targetForm.targetName
      );
    },
    getAgeStringForPanel () {
      if (this.targetForm.age.type === 0) {
        return this.twitterEnums.age.type[this.targetForm.age.type];
      }
      return this.targetForm.age.min + " - " + this.targetForm.age.max;
    },
    getStringForPanel () {
      return type => {
        var self = this
        if (!self.targetForm[type] || self.targetForm[type].length < 1) {
          return null;
        }
        var valuesUsed = self[type].filter(function (obj) {
          return self.targetForm[type].some(function (obj2) {
            return obj.targeting_value === obj2;
          });
        });
        return valuesUsed.map(x => x.name).join(" | ");
      }
    },
    checkIfOsIsSelected () {
      return currentOsTargetingId => {
        if (currentOsTargetingId === "4") {
          return false;
        }
        return (
          this.targetForm.platforms.includes(currentOsTargetingId) || false
        );
      };
    },
    getLocaleForTVShows () {
      var self = this
      var locationUsed = self.locations.filter(function (obj) {
        return self.targetForm.locations.some(function (obj2) {
          return obj.targeting_value === obj2;
        });
      });
      var languagesUsed = self.languages.filter(function (obj) {
        return self.targetForm.languages.some(function (obj2) {
          return obj.targeting_value === obj2;
        });
      });
      var locales = []
      if (locationUsed.length > 0 && languagesUsed.length > 0) {
        locationUsed.forEach(location => {
          languagesUsed.forEach(language => {
            var localeString = language.targeting_value + "-" + location.country_code
            locales.push(localeString)
          })
        })
      }
      return locales;
    },
    getUserReadableTargetSummary () {
      var message = " Includes " + this.twitterEnums.gender[this.targetForm.gender]
      if (this.targetForm.gender == 0) {
        message += " gender"
      }
      message += " with "
      this.commonUsageTargetingProperties.forEach(element => {
        var data = this.getStringForPanel(element)
        var validElementName = element.replace(/_/g, ' ');
        if (data) {
          message += validElementName + ": " + data + ", "
        }
      })
      var age = this.getAgeStringForPanel
      message += "" + age + " age range"
      return message
    }
  },
  mounted: function () {
    if (!this.targetForm.bpseId) {
      if (this.twitterAccounts.length == 1) {
        this.targetForm.bpseId = this.twitterAccounts[0].id
      }
    }
  },
  watch: {
    showModal () {
      if (!this.showModal) {
        this.clearAll();
      }
    },
    "targetForm.bpseId": function () {
      this.languages = [];
      if (!this.targetForm.bpseId) {
        if (this.twitterAccounts.length == 1) {
          this.targetForm.bpseId = this.twitterAccounts[0].id
        }
        return;
      }
      this.targetingEndpointStaticTypes.forEach(type => {
        this.getTargetingData(null, type, true);
      });
    },
    targetForm: {
      handler (newValue) {
        this.updateTargetJson(newValue)
      },
      deep: true
    }
  },
  methods: {
    ...mapMutations(["set_savedTargetsMapperByBpseId", "set_savedTargets"]),
    getTargetingData (searchQuery, type, isEmptyValid = false) {
      if (!searchQuery && !isEmptyValid) {
        return;
      }
      var self = this;
      this.$set(this.loading, type, true);
      var parametersJson = null;
      if (type === "events") {
        parametersJson = JSON.stringify({ "event_types": this.event_types });
      }
      APIService.getTwitterTargetEntities(
        this.targetForm.bpseId,
        type,
        searchQuery,
        parametersJson
      )
        .then(response => {
          var combinedValues = [...self[type], ...response.data.data];
          self[type] = [...new Set(combinedValues.map(JSON.stringify))].map(JSON.parse)
        })
        .catch(error => {
          console.error(error);
        })
        .finally(() => {
          this.$set(this.loading, type, false);
        });
    },
    getTVShows (searchQuery) {
      if (!searchQuery) {
        return;
      }
      var self = this;
      var type = "tv_shows"
      this.$set(this.loading, type, true);
      self.getLocaleForTVShows.forEach(locale => {
        var parametersJson = JSON.stringify({ "locale": locale });
        APIService.getTwitterTargetEntities(
          this.targetForm.bpseId,
          type,
          searchQuery,
          parametersJson
        )
          .then(response => {
            var combinedValues = [...self[type], ...response.data.data];
            self[type] = [...new Set(combinedValues.map(JSON.stringify))].map(JSON.parse)
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            this.$set(this.loading, type, false);
          });
      })
    },
    getTargetName () {
      let macroDict = {
        Language: this.getStringForPanel('languages'),
        Location: this.getStringForPanel('locations'),
        Gender: this.twitterEnums.gender[this.targetForm.gender],
        AgeGroup: this.getAgeStringForPanel
      };
      let replacedTargetName = this.targetForm.targetName;
      for (let [macro, value] of Object.entries(macroDict)) {
        if (value) {
          replacedTargetName = replacedTargetName.replace(`{${macro}}`, value);
        } else {
          replacedTargetName = replacedTargetName.replace(`{${macro}}`, "");
        }
      }
      return replacedTargetName;
    },
    addMacro (name) {
      if (!this.targetForm.targetName.includes(`{${name}}`)) {
        if (this.targetForm.targetName) {
          this.targetForm.targetName += "-";
        }
        this.targetForm.targetName += `{${name}}`;
      }
    },
    clearAll () {
      this.$refs.creationForm.resetFields();
      this.clearNestedFields();
    },
    removeTargetFromPreview () {
      this.targetsList = []
    },
    async saveTarget (language) {
      let target = {
        locationTargetType: 0,
        name: this.getTargetName(),
        agencyid: deltax.businessProfileId,
        advertiserid: deltax.businessProfileId,
        businessProfileId: deltax.businessProfileId,
        businessProfilesSearchEngineId: this.targetForm.bpseId,
        isCreatedOnDx: true,
        targetString: JSON.stringify(this.targetStringJson),
        estimatedReach: 0
      };

      try {
        let response = await APIService.saveTarget(target);
        target.id = response.data.data;
        this.$Message.success({
          background: true,
          content: `${target.name} successfully created`
        });

        this.set_savedTargets([...this.savedTargets, target]);

        let targetsBpseMap = this.savedTargetsMapperByBpseId;
        if (!(this.targetForm.bpseId in targetsBpseMap)) {
          targetsBpseMap[this.targetForm.bpseId] = [];
        }
        targetsBpseMap[this.targetForm.bpseId].push({
          BpseId: this.targetForm.bpseId,
          EstimatedReach: 0,
          Id: response.data.data,
          Name: target.name
        });
        this.set_savedTargetsMapperByBpseId(targetsBpseMap);

        // emit to show created targets in mapper screen
        target.Id = target.id;
        target.bpseId = target.businessProfilesSearchEngineId;
        target.Name = target.name;
        EventBus.$emit('twitter-updateSavedTarget', target, false);
      } catch (ex) {
        console.error(ex);
        this.$Message.error({
          background: true,
          content: "There was a problem while processing your request"
        });
      }
    },
    addTag (tags, inputModel) {
      if (this[tags] && this[inputModel]) {
        this[tags].push(this[inputModel]);
        this[inputModel] = "";
      }
    },
    handleClose (tagsList, name) {
      this[tagsList] = this[tagsList].filter(e => e !== name);
    },
    getStringForPlatformVersions (version) {
      if (version.os_type == "Mobile web on other devices") {
        return "Other Mobile KaiOs and above"
      }
      return version.os_type + " " + version.name + " and above"
    },
    getObjectsFromList (objectList, idsList, compareOperator) {
      var objectUsed = objectList.filter(function (obj) {
        return idsList.some(function (obj2) {
          return obj[compareOperator] === obj2;
        });
      });
      return objectUsed.map(obj => ({ ...obj, id: obj.targeting_value }))
    },
    getAllValuesInObject (nestedObject) {
      var targetingValues = []
      for (const property in nestedObject) {
        var prop = Number(property)
        if (nestedObject[prop]) {
          targetingValues.push(nestedObject[prop])
        }
      }
      return targetingValues;
    },
    getAgeTargetString (age) {
      if (age.all) {
        return null
      }
      if (age.max === "And Up") {
        return "AGE_OVER_" + age.min
      }
      if (age.min && age.max) {
        return "AGE_" + age.min + "_TO_" + age.max
      }
      return null;
    },
    updateTargetJson (targetForm) {
      this.targetStringJson = this.twitterEnums.targetSkeleton
      if (targetForm.age.type == 0) {
        this.targetStringJson.age = {
          "all": true,
          "min": null,
          "max": null,
          "twitterAgeRange": null
        }
      } else {
        this.targetStringJson.age = {
          "all": false,
          "min": targetForm.age.min,
          "max": targetForm.age.max,
          "twitterAgeRange": this.getAgeTargetString(targetForm.age)
        }
      }
      this.targetStringJson.gender = {
        "id": targetForm.gender,
        "label": this.twitterEnums.gender[targetForm.gender]
      }
      this.targetStringJson.keywords = {
        "include": this.includedKeywordsTags,
        "exclude": this.excludedKeywordsTags
      }
      this.targetStringJson.platform_versions = this.getAllValuesInObject(targetForm.platform_versions);
      this.commonUsageTargetingProperties.forEach(properties => {
        this.targetStringJson[properties] = this.getObjectsFromList(this[properties], targetForm[properties], "targeting_value")
      })
      this.targetsList = [this.targetStringJson]
    },
    clearNestedFields () {
      this.targetForm = JSON.parse(JSON.stringify(this.twitterEnums.targetForm));
      this.excludedKeywordsTags = [];
      this.includedKeywordsTags = [];
    }
  }
};
</script>

<style scoped>
.preivew-wrapper {
  border: 1px solid #ddd;
  margin: 15px;
  width: calc(100% - 30px);
}
.preview-placeholder {
  margin: 10px;
  font-weight: normal;
  color: #979696;
}
.preview-header {
  background-color: #f4f4f4;
  height: 35px;
  line-height: 35px;
  padding: 0 10px 0 10px;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--main-bg-color);
}
.preview-header p {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  padding: 0;
}
::v-deep .push-left {
  margin-left: 20px;
}
.vertical-space {
  margin-top: 10px;
  margin-bottom: 10px;
}
.audience-wrapper {
  padding: 15px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  padding: 15px;
}
.audience {
  border: 1px solid #ddd;
  padding: 10px;
  background: white;
}
.and-text {
  font-weight: bold;
}
.float-right {
  float: right;
  margin-left: 10px;
}
.macro-list {
  margin-bottom: 0px;
}
.macro-list > li {
  display: inline-block;
}
.pointer {
  cursor: pointer;
}
.macros {
  padding-right: 10px;
}
::v-deep .space {
  margin-bottom: 7px;
}
.target-row {
  margin: 10px;
  border: 1px solid #ddd;
}
.target-prev-header {
  padding: 0 5px;
  background: #f4f4f4;
  border-bottom: 2px solid var(--main-bg-color);
}
.target-prev-header p {
  margin: 2px 0 4px 0;
}
.target-prev-body {
  padding: 5px;
  font-weight: normal;
}
.preview-icon {
  margin-top: 3px;
  cursor: pointer;
  color: #888888;
}
.preview-icon:hover {
  color: #707376;
}
.preview-icon:active {
  transform: translateY(1px);
}
.divider {
  width: 100%;
  height: 7px;
  border-bottom: 1px solid #ccc;
  position: relative;
  margin-bottom: 36px;
}
.divider > span {
  left: 36px;
  position: absolute;
  font-size: 13px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  color: #979898;
  background-color: white;
  padding: 0 10px;
}
.operating-system-checkbox {
  display: block;
}
.platform-version-select {
  padding-left: 20px;
}
.os-name {
  padding-left: 5px;
}
.target-age-select {
  max-width: 150px;
}
.target-label-span {
  padding-right: 10px;
}
</style>
<style>
.modal-header button.close > span {
  font-size: 22px !important;
  color: initial;
}
.target-name > .ivu-form-item-error-tip {
  padding-top: 1px;
}
.twitter-target-modal .modal-header {
  padding-bottom: 5px !important;
}
.twitter-target-modal .modal-content {
  margin-top: -20px !important;
}
.twitter-target-modal .modal-dialog {
  width: 98%;
  height: 90%;
  z-index: 9997;
}
.targets-body {
  overflow: auto;
  padding: 0px !important;
  height: calc(100vh - 90px);
  max-height: calc(100vh - 90px);
}
.twitter-targets-panel .ivu-collapse-header {
  pointer-events: none;
  height: auto !important;
}
.twitter-targets-panel .ivu-form-item-error {
  padding-bottom: 30px;
}
.twitter-targets-panel .ivu-form-item {
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
<style src="../../../../Styles/dx-iview.css"></style>
