import { Objective } from "./Objective.js"
import { destinationType, platforms, positions } from "../../Constants/FacebookEnums.js"

let ColumnConfig = function (config) {
  const isMessenger = config.adset.destination == destinationType.Messenger

  let callToAction = []

  switch (config.adset.destination) {
    case destinationType.Messenger:
      callToAction = ["Message Page", "Apply Now", "Learn More", "Shop Now", "Sign Up", "Book Travel", "Contact Us", "Get Quote", "Subscribe", "Pay To Access"]
      break
    case destinationType.App:
      callToAction = ["Subscribe", "Book Travel", "Download", "Learn More", "Listen now", "Open Link", "Play Game", "Shop Now", "Sign Up", "Use App", "Watch More", "See Menu", "Start Order", "Contact Us"]
      break
    case destinationType.WhatsApp:
      callToAction = ["No Button", "Apply Now", "Learn More", "Shop Now", "Sign Up", "Book Travel", "Subscribe", "Pay To Access", "Whatsapp Message", "Get Quote", "Contact Us"]
      break
    case destinationType.PhoneCall:
      callToAction = ["Call Now"]
      break
    default:
      callToAction = ["No Button", "Apply Now", "Download", "Learn More", "Shop Now", "Sign Up", "Watch More", "Book Travel", "Subscribe", "Contact Us"]
  }

  let single = new function () {
    if (config.adset.destination == destinationType.App) {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Headline", "HOT_Deep Link", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"];
      this.requiredBase = ["HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action", "HOT_Image/Video"]
    } else if (config.adset.destination == destinationType.WhatsApp) {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Headline", "HOT_Description", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"];
      this.requiredBase = ["HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action", "HOT_Image/Video"]
    } else if (config.adset.destination == destinationType.PhoneCall) {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Call To Action", "HOT_Phone Number", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Link", "HOT_Ad Title", "HOT_Display Link", "HOT_Text", "HOT_Description", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"];
      this.requiredBase = ["HOT_Ad Name", "HOT_Phone Number", "HOT_Call To Action", "HOT_Image/Video"]
    } else {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Link", "HOT_Call To Action", "HOT_Image/Video", "HOT_Video_Thumbnail", "HOT_Ad Title", "HOT_Display Link", "HOT_Text", "HOT_Description", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"];
      this.requiredBase = ["HOT_Ad Name", "HOT_Link", "HOT_Call To Action", "HOT_Image/Video"]
    }
    this.card = []
    this.requiredCard = []
    this.callToAction = callToAction
    this.placementOptions = ["Facebook Feed", "Facebook Right Hand Column", "Facebook Instant Article", "Facebook Marketplace", "Facebook Video Feeds", "Facebook Story", "Instagram Stream", "Instagram Story", "Instagram Explore", "Audience Network Classic ", "Audience Network Instream Video", "Audience Network Rewarded Video"]
    this.placementCustomizationHeaders = ["HOT_Placements", "HOT_Image/Video", "HOT_Text", "HOT_Ad Title", "HOT_Link"]
    this.languageCustomizationHeaders = ["HOT_Placements", "HOT_Image/Video", "HOT_Text", "HOT_Ad Title", "HOT_Description", "HOT_Link"]
    this.DynamicCreativeHeaders = ["HOT_Placements", "HOT_Image/Video", "HOT_Primary Text", "HOT_Description", "HOT_Headline"]
  }();
  let carousel = new function () {
    if (config.adset.destination == destinationType.App) {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Deep Link", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"]
      this.requiredBase = ["HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action"]
      this.card = ["HOT_Headline", "HOT_Image/Video", "HOT_Deep Link"]
      this.requiredCard = ["HOT_Image/Video"]
    } else if (config.adset.destination == destinationType.WhatsApp) {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"]
      this.requiredBase = ["HOT_Ad Name", "HOT_Primary Text", "HOT_Call To Action"]
      this.card = ["HOT_Headline", "HOT_Image/Video", "HOT_Description"]
      this.requiredCard = ["HOT_Image/Video"]
    } else {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_See More Url", "HOT_Call To Action", "HOT_Optimize Card Order", "HOT_Ad Title", "HOT_Text", "HOT_Description", "HOT_See More Display Url", "HOT_Url Tags", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions", "HOT_Standard_Enhancements", "HOT_Image_Enhancements", "HOT_3d_Animation", "HOT_Music"]
      this.requiredBase = ["HOT_Ad Name", "HOT_See More Url", "HOT_Call To Action"]
      this.card = ["HOT_Link", "HOT_Headline", "HOT_Image/Video", "HOT_Description"]
      this.requiredCard = ["HOT_Link", "HOT_Image/Video"]
    }
    this.callToAction = callToAction
  }();
  let collection = new function () {
    if (config.adset.destination == destinationType.App) {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Instant Experience", "HOT_Primary Text", "HOT_Headline", "HOT_Deep Link Destination", "HOT_Deep Link", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"]
      this.requiredBase = ["HOT_Ad Name", "HOT_Instant Experience", "HOT_Primary Text", "HOT_Headline", "HOT_Deep Link Destination"]
      // HOT_Deep Link Destination: deeplink_with_web_fallback, web_only, deeplink_with_appstore_fallback
    } else {
      this.base = ["HOT_Preview Ad", "HOT_Ad Name", "HOT_Message", "HOT_Instant Experience", "HOT_Call To Action", "HOT_Headline", "HOT_StartDate", "HOT_EndDate", "HOT_StartTime", "HOT_EndTime", "HOT_Targets", "HOT_Locations", "HOT_LineItemFractions"]
      this.requiredBase = ["HOT_Ad Name", "HOT_Message", "HOT_Instant Experience", "HOT_Call To Action"]
    }
    this.card = []
    this.requiredCard = []
    this.callToAction = callToAction
  }();

  if (isMessenger || config.adset.destination == destinationType.WhatsApp) {
    return { single, carousel }
  }
  return { single, carousel, collection }
}

let CreativeSpec = function (config) {
  const isMessenger = config.adset.destination == destinationType.Messenger

  let single = new function () {
    if (config.adset.destination == destinationType.App) {
      this.image = {
        "creative": {
          "object_story_spec": {
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId",
            "link_data": {
              "picture": "HOT_Image/Video",
              "link": "HOT_Link",
              "name": "HOT_Headline",
              "message": "HOT_Primary Text",

              "call_to_action": {
                "type": "HOT_Call To Action",
                "value": {
                  "link": "HOT_Link",
                  "app_link": "HOT_Deep Link",
                  "application": "HOT_AppId"
                }
              }
            }
          },
          "degrees_of_freedom_spec": {
            "creative_features_spec": {
              "standard_enhancements": {
                "enroll_status": "OPT_OUT"
              }
            }
          }
        }
      }
      this.video = {
        "creative": {
          "object_story_spec": {
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId",
            "video_data": {
              "call_to_action": {
                "type": "HOT_Call To Action",
                "value": {
                  "link": "HOT_Link",
                  "app_link": "HOT_Deep Link",
                  "application": "HOT_AppId"
                }
              },
              "video_id": "HOT_SearchEngineVideoId",
              "message": "HOT_Primary Text",
              "image_url": "HOT_Image/Video",
              "title": "HOT_Headline"
            }
          },
          "url_tags": "HOT_Url Tags",
          "degrees_of_freedom_spec": {
            "creative_features_spec": {
              "standard_enhancements": {
                "enroll_status": "OPT_OUT"
              }
            }
          }
        }
      }
    } else if (config.adset.destination == destinationType.WhatsApp) {
      this.image = {
        "creative": {
          "object_story_spec": {
            "link_data": {
              "call_to_action": {
                "type": "WHATSAPP_MESSAGE"
              },
              "link": "https://api.whatsapp.com/send",
              "message": "HOT_Primary Text",
              "name": "HOT_Headline",
              "description": "HOT_Description",
              "picture": "HOT_Image/Video"
            },
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId"
          },
          "degrees_of_freedom_spec": {
            "creative_features_spec": {
              "standard_enhancements": {
                "enroll_status": "OPT_OUT"
              }
            }
          }
        }
      }
      this.video = {
        "creative": {
          "object_story_spec": {
            "video_data": {
              "call_to_action": {
                "value": {
                  "link": "https://api.whatsapp.com/send"
                },
                "type": "WHATSAPP_MESSAGE"
              },
              "video_id": "HOT_SearchEngineVideoId",
              "title": "HOT_Headline",
              "message": "HOT_Primary Text",
              "link_description": "HOT_Description",
              "image_url": "HOT_Image/Video"
            },
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId"
          },
          "degrees_of_freedom_spec": {
            "creative_features_spec": {
              "standard_enhancements": {
                "enroll_status": "OPT_OUT"
              }
            }
          }
        }
      }
    } else {
      this.image = {
        "creative": {
          "object_story_spec": {
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId",
            "link_data": {
              "picture": "HOT_Image/Video",
              "link": "HOT_Link",
              "name": "HOT_Ad Title",
              "description": "HOT_Description",
              "message": "HOT_Text",
              "caption": "HOT_Display Link",
              ...(isMessenger && {
                "page_welcome_message": config.ad.messageTemplate.messageBody
              }),
              "call_to_action": {
                "type": "HOT_Call To Action",
                "value": isMessenger
                  ? { "app_destination": "MESSENGER" }
                  : { "link": "HOT_Link" }
              }
            }
          },
          "url_tags": "HOT_Url Tags",
          "degrees_of_freedom_spec": {
            "creative_features_spec": {
              "standard_enhancements": {
                "enroll_status": "OPT_OUT"
              }
            }
          }
        }
      }
      this.video = {
        "creative": {
          "object_story_spec": {
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId",
            "video_data": {
              "video_id": "HOT_SearchEngineVideoId",
              "image_url": "HOT_Image/Video",
              "title": "HOT_Ad Title",
              "message": "HOT_Text",
              "link_description": "HOT_Description",
              ...(isMessenger && {
                "page_welcome_message": config.ad.messageTemplate.messageBody
              }),
              "call_to_action": {
                "type": "HOT_Call To Action",
                "value": isMessenger
                  ? { "app_destination": "MESSENGER" }
                  : { "link": "HOT_Link" }
              }
            }
          },
          "url_tags": "HOT_Url Tags",
          "degrees_of_freedom_spec": {
            "creative_features_spec": {
              "standard_enhancements": {
                "enroll_status": "OPT_OUT"
              }
            }
          }
        }
      }
    }
    this.dynamicCreative = {
      metadata: new function () {
        this.colToAssetSpecMap = {
          "HOT_Image/Video": "images",
          "HOT_Primary Text": "bodies",
          "HOT_Call To Action": "call_to_action_types",
          "HOT_Description": "descriptions",
          "HOT_Headline": "titles"
        };

        this.headlineRemap = {
          "HOT_Ad Title": "HOT_Headline",
          "HOT_Text": "HOT_Primary Text"
        };
      }(),
      image: {
        creative: {
          object_story_spec: {
            page_id: "HOT_Page"
          },
          asset_feed_spec: {
            images: [ // required. Max: 10
              {
                url: "HOT_Image/Video"
              }
            ],
            bodies: [ // Max: 5
              {
                text: "HOT_Primary Text"
              }
            ],
            call_to_action_types: ["HOT_Call To Action"], // required. Max: 5
            descriptions: [ // Max: 5
              {
                text: "HOT_Description"
              }
            ],
            link_urls: [
              {
                website_url: "HOT_Link", // required
                display_url: "HOT_Display Link"
              }
            ],
            titles: [ // Max: 5
              {
                text: "HOT_Headline"
              }
            ],
            ad_formats: ["SINGLE_IMAGE"] // required
          }
        }
      },
      video: {
        creative: {
          object_story_spec: {
            page_id: "HOT_Page"
          },
          asset_feed_spec: {
            videos: [ // required. Max: 10
              {
                video_id: "HOT_SearchEngineVideoId",
                thumbnail_url: "HOT_Image/Video"
              }
            ],
            bodies: [ // Max: 5
              {
                text: "HOT_Primary Text"
              }
            ],
            call_to_action_types: ["HOT_Call To Action"], // required. Max: 5
            descriptions: [ // Max: 5
              {
                text: "HOT_Description"
              }
            ],
            link_urls: [
              {
                website_url: "HOT_Link", // required
                display_url: "HOT_Display Link"
              }
            ],
            titles: [ // Max: 5
              {
                text: "HOT_Headline"
              }
            ],
            ad_formats: ["SINGLE_VIDEO"] // required
          }
        }
      }
    }
    this.placementCustomization = {}
  }();
  let carousel = new function () {
    if (config.adset.destination == destinationType.App) {
      this.creative = {
        "object_story_spec": {
          "link_data": {
            "call_to_action": {
              "type": "HOT_Call To Action",
              "value": {
                "link": "HOT_Link",
                "app_link": "HOT_Deep Link",
                "application": "HOT_AppId"
              }
            },
            "link": "HOT_Link",
            "message": "HOT_Primary Text",
            "child_attachments": [
              {
                "link": "HOT_Link",
                "picture": "HOT_Image/Video",
                "name": "HOT_Headline",
                "call_to_action": {
                  "type": "HOT_Call To Action",
                  "value": {
                    "link": "HOT_Link",
                    "app_link": "HOT_Deep Link",
                    "application": "HOT_AppId"
                  }
                }
              }
            ]
          },
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId"
        },
        "degrees_of_freedom_spec": {
          "creative_features_spec": {
            "standard_enhancements": {
              "enroll_status": "OPT_OUT"
            }
          }
        }
      }
    } else if (config.adset.destination == destinationType.WhatsApp) {
      this.creative = {
        "object_story_spec": {
          "link_data": {
            "call_to_action": {
              // "value": {
              //   "whatsapp_number": "916363007152"
              // },
              "type": "WHATSAPP_MESSAGE"
            },
            "link": "https://api.whatsapp.com/send",
            "message": "HOT_Primary Text",
            "name": "Chat on WhatsApp",
            "child_attachments": [
              {
                "call_to_action": {
                  "type": "WHATSAPP_MESSAGE"
                  // "value": {
                  //   "link": "https://api.whatsapp.com/send",
                  //   "whatsapp_number": "916363007152"
                  // }
                },
                "link": "https://api.whatsapp.com/send",
                "description": "HOT_Description",
                "name": "HOT_Headline",
                "picture": "HOT_Image/Video"
              }
            ]
          },
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId"
        },
        "degrees_of_freedom_spec": {
          "creative_features_spec": {
            "standard_enhancements": {
              "enroll_status": "OPT_OUT"
            }
          }
        }
      }
    } else {
      this.creative = {
        "object_story_spec": {
          "link_data": {
            "call_to_action": {
              "type": "HOT_Call To Action",
              "value": isMessenger
                ? { "app_destination": "MESSENGER" }
                : { "link": "HOT_See More Url" }
            },
            "description": "HOT_Description",
            "link": "HOT_See More Url",
            "message": "HOT_Text",
            "name": "HOT_Ad Name",
            "caption": "HOT_Display Link",
            ...(isMessenger && {
              "page_welcome_message": config.ad.messageTemplate.messageBody
            }),
            "child_attachments": [
              {
                "link": "HOT_Link",
                "picture": "HOT_Image/Video",
                "name": "HOT_Headline",
                "description": "HOT_Description",
                ...(isMessenger && {
                  "call_to_action": {
                    "type": "HOT_Call To Action",
                    "value": {
                      "app_destination": "MESSENGER"
                    }
                  }
                })
              }
            ],
            "multi_share_optimized": "HOT_Optimize Card Order"
          },
          "page_id": "HOT_Page",
          "instagram_actor_id": "HOT_InstagramId"
        },
        "url_tags": "HOT_Url Tags",
        "degrees_of_freedom_spec": {
          "creative_features_spec": {
            "standard_enhancements": {
              "enroll_status": "OPT_OUT"
            }
          }
        }
      }
    }
  }();
  let collection = new function () {
    if (config.adset.destination == destinationType.App) {
      this.image = {
        "creative": {
          "object_story_spec": {
            "link_data": {
              "call_to_action": {
                "value": {
                  "link": "HOT_Link",
                  "app_link": "HOT_Deep Link",
                  "application": "HOT_AppId",
                  "retailer_item_ids": [
                    "0",
                    "0",
                    "0",
                    "0"
                  ]
                },
                "type": "USE_MOBILE_APP"
              },
              "link": "HOT_Link",
              "message": "HOT_Primary Text",
              "name": "HOT_Headline"
            },
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId"
          },
          "applink_treatment": "HOT_Deep Link Destination"
        }
      }
      this.video = this.image
    } else {
      this.image = {
        "creative": {
          "object_story_spec": {
            "link_data": {
              "link": "HOT_Link",
              "message": "HOT_Message",
              "name": "HOT_Headline",
              "retailer_item_ids": [
                "0",
                "0",
                "0",
                "0"
              ]
            },
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId"
          }
        }
      };
      this.video = {
        "creative": {
          "object_story_spec": {
            "video_data": {
              "call_to_action": {
                "value": {
                  "link": "HOT_Link"
                },
                "type": "LEARN_MORE"
              },
              "retailer_item_ids": [
                "0",
                "0",
                "0",
                "0"
              ],
              "video_id": "HOT_SearchEngineVideoId",
              "message": "HOT_Message",
              "title": "HOT_Headline"
            },
            "page_id": "HOT_Page",
            "instagram_actor_id": "HOT_InstagramId"
          }
        }
      }
    }
  }()

  if (isMessenger || config.adset.destination == destinationType.WhatsApp) {
    return { single, carousel }
  }
  return { single, carousel, collection }
}
let AdFormats = {
  "Image or Carousel": {
    "adSheets": ["single"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.MarketPlace,
        positions.Facebook.VideoFeeds,
        positions.Facebook.Story,
        positions.Facebook.InstreamVideo,
        positions.Facebook.InstantArticle
      ],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Story
      ]
    }
  },
  "Video": {
    "adSheets": ["single"],
    "placements": {
      [platforms.Facebook]: [
        positions.Facebook.Feed,
        positions.Facebook.MarketPlace,
        positions.Facebook.VideoFeeds,
        positions.Facebook.Story,
        positions.Facebook.InstreamVideo,
        positions.Facebook.InstantArticle
      ],
      [platforms.Instagram]: [
        positions.Instagram.Stream,
        positions.Instagram.Story
      ]
    }
  }
}
let OptimizationGoal = [7, 8, 14, 20]

let traffic = new Objective(ColumnConfig, CreativeSpec, AdFormats, OptimizationGoal)
export { traffic }
