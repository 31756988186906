import { render, staticRenderFns } from "./LinkContract.vue?vue&type=template&id=53d85290&scoped=true&"
import script from "./LinkContract.vue?vue&type=script&lang=js&"
export * from "./LinkContract.vue?vue&type=script&lang=js&"
import style0 from "./LinkContract.vue?vue&type=style&index=0&id=53d85290&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53d85290",
  null
  
)

export default component.exports